import { Box, Button, Grid, Dialog, Paper, DialogActions, DialogContentText, DialogContent, DialogTitle, Snackbar } from "@material-ui/core";
import _ from "lodash";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { getRequests } from "../../actions";
import { useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";

import BrandIcons from "../Common/BrandIcons";
import RoleIcons from "../Common/RoleIcons";
import RequestsBreadcrumb from "./RequestsBreadcrumb";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const CancelledRequest = ({ item }) => {
    const dispatch = useDispatch();
    const { oktaAuth, authState } = useOktaAuth();
    const [modalOpen, setModalOpen] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const toggleModal = () => setModalOpen(!modalOpen);
    const resetStatus = () => {
        setModalOpen(true);
    };
    const confirmStatusReset = async () => {
        console.log("resetting status to pending");
        setModalOpen(!modalOpen);
        const token = oktaAuth.getAccessToken();
        axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/resetrequeststatus`,
                {
                    item,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {});
    };

    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            dispatch(getRequests(token));
            return;
        }
        setToastOpen(false);
        dispatch(getRequests(token));
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={1}>
                            <RequestsBreadcrumb item={item} />
                        </Box>
                        <Box p={1}>
                            <h4>This request was cancelled</h4>

                            <div>
                                <strong>Request ID: </strong>
                                {item.id}
                            </div>
                            <div>
                                <strong>Name: </strong>
                                {item.firstName} {item.lastName}
                            </div>
                            <div>
                                <strong>Email: </strong>
                                {item.email}{" "}
                            </div>
                            <div>
                                <strong>Role: </strong>
                                <RoleIcons slug={item.userRole} />
                            </div>
                            <div>
                                <strong>Location: </strong>
                                {item.location}{" "}
                            </div>
                            <div>
                                <strong>Brands: </strong>
                                {_.map(_.compact(item.assocBrands.split(";")), (brand, i) => {
                                    return <BrandIcons key={i} slug={brand} location={item.location} />;
                                })}
                            </div>
                            <div>
                                <strong>Comments: </strong>
                                {item.comments}
                            </div>

                            <Box my={3}>
                                <Button variant='contained' color='primary' onClick={() => resetStatus()}>
                                    Reset Request Status
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={modalOpen} onClose={toggleModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>Reset Request Status?</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>Please confirm that you want to reset the status for this Request.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleModal} color='default'>
                        Cancel
                    </Button>
                    <Button onClick={confirmStatusReset} color='primary' autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={toastOpen} autoHideDuration={3000} onClose={handleToastClose}>
                <>{toastContent}</>
            </Snackbar>
        </>
    );
};

export default CancelledRequest;
