import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// import "overlayscrollbars/css/OverlayScrollbars.css";
import "@fontsource/roboto";
import "../styles/base-mdc-theme/theme.css";
import "../styles/base-mdc-theme/themecolors.css";
import "../styles/custom.css";
import "../styles/brandstyling.css";

import { useEffect, useState, useMemo, useRef } from "react";
import _ from "lodash";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getOktaMe, getRequests, getApps, getStores, getBrands, getRoles, getAdmins, getAdminRoles, getUsers, getSignins, getHelp } from "../actions";
import { useOktaAuth } from "@okta/okta-react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { formatISO9075, differenceInMinutes, differenceInDays } from "date-fns";

import TopNav from "./Nav/TopNav";
import SideNav from "./Nav/SideNav";
import { red } from "@material-ui/core/colors";
library.add(fad);
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    title: {
        flexGrow: 1,
        fontSize: "1.5rem",
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
}));
const Layout = (props) => {
    document.title = process.env.REACT_APP_SITETITLE;
    const colormode = localStorage.getItem("color-mode");
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentAdmin = useSelector((state) => state.user.untouched);
    const brands = useSelector((state) => state.brands.untouchedlist);
    const roles = useSelector((state) => state.roles.untouchedlist);
    const matrixusers = useSelector((state) => state.matrixusers.untouchedlist);
    const focusbrandsusers = useSelector((state) => state.focusbrandsusers.untouchedlist);
    const stores = useSelector((state) => state.stores.untouchedlist);
    const requests = useSelector((state) => state.requests.untouchedlist);
    const apps = useSelector((state) => state.apps.untouchedlist);
    const { oktaAuth, authState } = useOktaAuth();
    const [open, setOpen] = useState(false);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [darkMode, setDarkMode] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const switchTheme = () => {
        if (darkMode) {
            document.documentElement.setAttribute("color-mode", "light");
            localStorage.setItem("color-mode", "light");
            setDarkMode(false);
        } else {
            document.documentElement.setAttribute("color-mode", "dark");
            localStorage.setItem("color-mode", "dark");
            setDarkMode(true);
        }
    };
    useEffect(() => {
        if (!colormode) {
            if (prefersDarkMode) {
                setDarkMode(true);
                document.documentElement.setAttribute("color-mode", "dark");
                localStorage.setItem("color-mode", "dark");
            } else {
                setDarkMode(false);
                document.documentElement.setAttribute("color-mode", "light");
                localStorage.setItem("color-mode", "light");
            }
        } else if (colormode === "light") {
            setDarkMode(false);
            document.documentElement.setAttribute("color-mode", "light");
        } else if (colormode === "dark") {
            setDarkMode(true);
            document.documentElement.setAttribute("color-mode", "dark");
        }
    }, [prefersDarkMode, colormode]);

    const theme = useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: darkMode ? "dark" : "light",
                    primary: {
                        main: "#A52031",
                        aa: "#0061af",
                    },
                    info: {
                        light: "#64b5f6",
                        main: "#2196f3",
                        dark: "#1976d2",
                        contrastText: "#fff",
                    },
                    aa: {
                        main: "#0061af",
                    },
                },
                overrides: {
                    MuiTab: {
                        wrapper: { alignItems: "flex-start", flexDirection: "row", justifyContent: "normal" },
                    },
                    MuiFormLabel: {
                        asterisk: {
                            color: red[600],
                        },
                    },
                },
            }),
        [darkMode]
    );
    const getUserSyncTime = (token) => {
        axios
            .get(process.env.REACT_APP_APIHOST + "/api/oktausersynctime", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    console.log("syncing user db localstorage timesync with matrix system");
                    localStorage.removeItem("oktausersynctime");
                    localStorage.setItem("oktausersynctime", JSON.stringify(res.data.response[0]));
                } else {
                    localStorage.removeItem("oktausersynctime");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const startSyncUsersToDB = (token, lastran, updated) => {
        axios
            .post(
                process.env.REACT_APP_APIHOST + "/api/oktausersynctime",
                { modifiedby: currentAdmin.profile.login, prevrun: lastran, lastupdated: updated },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    getUserSyncTime(token);
                    dispatch(getUsers(token));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const checkLastUserSyncTime = (token) => {
        let _localoktasynctime = localStorage.getItem("oktausersynctime");
        _localoktasynctime = JSON.parse(_localoktasynctime);
        if (!_localoktasynctime) {
            // localStorage key doesn't exist
            console.log("Okta User DB Sync key does NOT exist");
            getUserSyncTime(token);
        } else {
            console.log("Okta User DB Sync key does exist");
            if (!_localoktasynctime.lastran && !_localoktasynctime.updated) {
                // localStorage key exists, but sync time isn't there
                startSyncUsersToDB(token, false, true);
            } else if (_localoktasynctime.lastran && _localoktasynctime.updated) {
                // localStorage sync time exists
                let _offsetsynctime = differenceInMinutes(new Date(), new Date(_localoktasynctime.lastran));
                // localStorage last update date exists
                let _resetsynctime = differenceInDays(new Date(), new Date(_localoktasynctime.updated));
                if (_offsetsynctime >= 5 && _resetsynctime < 1) {
                    console.log(`Resyncing User DB from Okta.  Time since last sync: ${_offsetsynctime} minute(s)`);
                    startSyncUsersToDB(token, new Date(_localoktasynctime.lastran), false);
                } else if (_offsetsynctime >= 5 && _resetsynctime >= 1) {
                    console.log(`Complete Update of User DB from Okta.  Time since last update: ${_resetsynctime} days(s)`);
                    startSyncUsersToDB(token, false, new Date(_localoktasynctime.updated));
                } else {
                    console.log(`User DB was recently updated from Okta. Time since last sync: ${_offsetsynctime} minute(s). Time since last update: ${_resetsynctime} days(s)`);
                    getUserSyncTime(token);
                }
            }
        }
    };

    const getSigninLogSyncTime = (token) => {
        axios
            .get(process.env.REACT_APP_APIHOST + "/api/oktasigninlogsynctime", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    console.log("syncing signin log localstorage timesync with matrix system");
                    localStorage.removeItem("oktasigninlogsynctime");
                    localStorage.setItem("oktasigninlogsynctime", JSON.stringify(res.data.response[0]));
                } else {
                    localStorage.removeItem("oktasigninlogsynctime");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const startSyncSigninLogsToDB = (token, lastran) => {
        axios
            .post(
                process.env.REACT_APP_APIHOST + "/api/oktasigninlogsynctime",
                { modifiedby: currentAdmin.profile.login, prevrun: lastran },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    getSigninLogSyncTime(token);
                    dispatch(getSignins(token));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const checkLastSigninLogsSyncTime = (token) => {
        let _localsigninlogsynctime = localStorage.getItem("oktasigninlogsynctime");
        _localsigninlogsynctime = JSON.parse(_localsigninlogsynctime);
        if (!_localsigninlogsynctime) {
            // localStorage key doesn't exist
            console.log("Okta SigninLog DB Sync key does NOT exist");
            getSigninLogSyncTime(token);
        } else {
            console.log("Okta SigninLog DB Sync key does exist");
            if (_localsigninlogsynctime.lastran) {
                // localStorage sync time exists
                let _offsetsynctime = differenceInMinutes(new Date(), new Date(_localsigninlogsynctime.lastran));
                if (_offsetsynctime >= 10) {
                    console.log(`Resyncing SigninLog DB from Okta.  Time since last sync: ${_offsetsynctime} minute(s)`);
                    startSyncSigninLogsToDB(token, new Date(_localsigninlogsynctime.lastran));
                } else {
                    console.log(`SigninLog DB was recently updated from Okta. Time since last sync: ${_offsetsynctime} minute(s).`);
                    getSigninLogSyncTime(token);
                }
            }
        }
    };
    useEffect(() => {
        if (!authState.isPending && authState.isAuthenticated) {
            if (authState.accessToken) {
                oktaAuth
                    .getUser()
                    .then((oktaUser) => {
                        const userguid = oktaUser.sub;
                        const token = oktaAuth.getAccessToken();
                        dispatch(getOktaMe(token, userguid));
                    })
                    .catch(function (error) {
                        console.log(error);
                        if (error.errorCode === "login_required") {
                            window.location.reload(true);
                        }
                    });
            }
        } else if (!authState.isPending && !authState.isAuthenticated) {
            oktaAuth.signInWithRedirect(window.location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]); // Update if authState changes
    useEffect(() => {
        const token = oktaAuth.getAccessToken();
        if (!_.isEmpty(currentAdmin)) {
            dispatch(getUsers(token));
            dispatch(getSignins(token));
            dispatch(getStores(token));
            dispatch(getRequests(token));
            dispatch(getBrands(token));
            dispatch(getApps(token));
            dispatch(getHelp(token));
            dispatch(getRoles(token, currentAdmin));
            dispatch(getAdmins(token));
            dispatch(getAdminRoles(token));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAdmin]);
    useEffect(() => {
        if (currentAdmin) {
            const token = oktaAuth.getAccessToken();
            if (token) {
                // checkLastUserSyncTime(token);
                // checkLastSigninLogsSyncTime(token);
            }
        }
    }, [props, currentAdmin]);
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <TopNav classes={classes} open={open} handleDrawerOpen={handleDrawerOpen} currentAdmin={currentAdmin} switchTheme={switchTheme} darkMode={darkMode} />
                <SideNav classes={classes} open={open} handleDrawerClose={handleDrawerClose} currentAdmin={currentAdmin} setOpen={setOpen} switchTheme={switchTheme} darkMode={darkMode} />
                <main id='content-main' role='main' className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth='xl' className={classes.container}>
                        {props.children}
                    </Container>
                </main>
            </div>
        </ThemeProvider>
    );
};

export default Layout;
