import { useState, useEffect } from "react";
import { Box, Button, Divider, Grid, IconButton, InputAdornment, Paper, Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionSquare, faEdit, faCheck, faHorizontalRule, faCheckSquare, faTimesSquare } from "@fortawesome/pro-duotone-svg-icons";
import { blue, grey, green, red } from "@material-ui/core/colors";
import SimpleTextField from "./SimpleTextField";
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreenButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Button);

const UserNameField = ({ props, autofilldomain, showFormHelp, fieldHelpText, checkedUsername, validateUsername, setValidusername, usernameValidMsg }) => {
    const [checkUsernameBtn, setCheckUsernameBtn] = useState(
        <InputAdornment position='end'>
            <Box>
                <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                {autofilldomain}
                <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
            </Box>
            <IconButton disabled>
                <FontAwesomeIcon icon={faEdit} size='sm' />
            </IconButton>
        </InputAdornment>
    );
    const [validBtn, setValidBtn] = useState("");
    useEffect(() => {
        if (checkedUsername && checkedUsername === `${props.values.username}${autofilldomain}`) {
            setValidBtn(
                <InputAdornment position='start'>
                    <Box color={green[500]}>
                        <FontAwesomeIcon icon={faCheckSquare} />
                    </Box>
                </InputAdornment>
            );
            setCheckUsernameBtn(
                <InputAdornment position='end'>
                    <Box>
                        <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                        {autofilldomain}
                        <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                    </Box>
                    <GreenButton>Valid User Name</GreenButton>
                </InputAdornment>
            );
        } else if (checkedUsername && checkedUsername !== `${props.values.username}${autofilldomain}`) {
            setValidBtn(
                <InputAdornment position='start'>
                    <Box color={red[500]}>
                        <FontAwesomeIcon icon={faTimesSquare} />
                    </Box>
                </InputAdornment>
            );
            setCheckUsernameBtn(
                <InputAdornment position='end'>
                    <Box>
                        <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                        {autofilldomain}
                        <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                    </Box>
                    <BlueButton variant='outlined' onClick={() => validateUsername(`${props.values.username}${autofilldomain}`)}>
                        Check User Name
                    </BlueButton>
                </InputAdornment>
            );
            setValidusername("invalid");
        } else {
            if (props.values.username) {
                props.values.username = props.values.username.replace(/([^a-zA-Z._-])/, "");
                props.values.username = props.values.username.toLowerCase();
                if (props.values.username.length > 2) {
                    setCheckUsernameBtn(
                        <InputAdornment position='end'>
                            <Box>
                                <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                                {autofilldomain}
                                <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                            </Box>
                            <BlueButton variant='outlined' onClick={() => validateUsername(`${props.values.username}${autofilldomain}`)}>
                                Check User Name
                            </BlueButton>
                        </InputAdornment>
                    );
                }
            } else {
                setCheckUsernameBtn(
                    <InputAdornment position='end'>
                        <Box>
                            <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                            {autofilldomain}
                            <FontAwesomeIcon icon={faHorizontalRule} transform={{ rotate: 90 }} />
                        </Box>
                        <IconButton disabled>
                            <FontAwesomeIcon icon={faEdit} size='sm' />
                        </IconButton>
                    </InputAdornment>
                );
                setValidBtn("");
            }
        }
    }, [props.values.username, checkedUsername]);

    return (
        <>
            <Grid container spacing={1} alignItems='flex-end'>
                <Grid item xs={12}>
                    <SimpleTextField
                        props={props}
                        fieldname={"username"}
                        fieldtype={"text"}
                        fieldlabel={"User Name"}
                        fieldrequired={true}
                        showFormHelp={showFormHelp}
                        fieldHelpText={fieldHelpText}
                        fieldValidationText={usernameValidMsg}
                        endAdornment={checkUsernameBtn}
                        startAdornment={validBtn}
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default UserNameField;
