import {
    faChevronLeft,
    faEclipseAlt,
    faSun,
    faBrowser,
    faUserCheck,
    faUsersClass,
    faMapMarkerQuestion,
    faStoreAlt,
    faUsersCrown,
    faUserTag,
    faUserSecret,
    faCopyright,
    faUserHeadset,
    faTools,
    faTag,
    faHouseUser,
    faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// children get the permissions of their parent items
const navitems = [
    {
        id: 0,
        label: "Access Requests",
        title: "Access Requests",
        slug: "accessrequests",
        to: "/accessrequests",
        icon: <FontAwesomeIcon icon={faUserCheck} fixedWidth />,
        access: ["super", "admin", "helpdesk"],
        children: false,
    },
    // {
    //     id: 1,
    //     label: "Apps",
    //     title: "Matrix Apps",
    //     to: "/apps",
    //     icon: <FontAwesomeIcon icon={faBrowser} fixedWidth />,
    //     access: ["super"],
    //     children: false,
    // },
    // {
    //     id: 2,
    //     label: "Franchisees",
    //     title: "Franchisees List",
    //     to: "/franchisees",
    //     icon: <FontAwesomeIcon icon={faUsersClass} fixedWidth />,
    //     access: ["super"],
    //     children: false,
    // },
    {
        id: 3,
        label: "Help",
        title: "Help & Documentation",
        to: "/help",
        icon: <FontAwesomeIcon icon={faMapMarkerQuestion} fixedWidth />,
        access: ["super"],
        children: false,
    },
    {
        id: 4,
        label: "Shared Accounts",
        title: "Shared Accounts",
        to: "/sharedaccounts",
        icon: <FontAwesomeIcon icon={faHouseUser} fixedWidth />,
        access: ["all"],
        children: false,
    },
    {
        id: 5,
        label: "Stores",
        title: "Stores List",
        to: "/stores",
        icon: <FontAwesomeIcon icon={faStoreAlt} fixedWidth />,
        access: ["super", "admin", "helpdesk", "readonly"],
        children: false,
    },
    {
        id: 6,
        label: "Users",
        title: "Users",
        to: "/users",
        icon: <FontAwesomeIcon icon={faUsersClass} fixedWidth />,
        access: ["all"],
        children: false,
    },

    {
        id: 7,
        label: "Super Admin",
        title: "Super Admin Menu Items",
        slug: "super",
        to: "/super",
        icon: <FontAwesomeIcon icon={faUserSecret} fixedWidth />,
        access: ["super"],
        children: [
            {
                id: 6.1,
                label: "Admins",
                title: "List of Matrix Admins",
                slug: "admins",
                to: "/super/admins",
                icon: <FontAwesomeIcon icon={faUsersCrown} fixedWidth />,
                children: false,
            },
            {
                id: 6.2,
                label: "Admin Roles",
                title: "List of Admin Roles",
                slug: "adminroles",
                to: "/super/adminroles",
                icon: <FontAwesomeIcon icon={faUserTag} fixedWidth />,
                children: false,
            },
            {
                id: 6.3,
                label: "Brands",
                title: "List of Brands",
                slug: "brands",
                to: "/super/brands",
                icon: <FontAwesomeIcon icon={faCopyright} fixedWidth />,
                children: false,
            },
            {
                id: 6.4,
                label: "Everyone",
                title: "List of Everyone in Okta",
                slug: "everyone",
                to: "/super/everyone",
                icon: <FontAwesomeIcon icon={faUsers} fixedWidth />,
                children: false,
            },
            {
                id: 6.5,
                label: "Help",
                title: "Create Help Items",
                slug: "help",
                to: "/super/help",
                icon: <FontAwesomeIcon icon={faUserHeadset} fixedWidth />,
                children: false,
            },
            {
                id: 6.6,
                label: "Tools",
                title: "Super Admin Tools",
                slug: "adminroles",
                to: "/super/tools",
                icon: <FontAwesomeIcon icon={faTools} fixedWidth />,
                children: false,
            },
            {
                id: 6.7,
                label: "User Roles",
                title: "List of User Roles",
                slug: "userroles",
                to: "/super/userroles",
                icon: <FontAwesomeIcon icon={faTag} fixedWidth />,
                children: false,
            },
        ],
    },
    {
        id: 8,
        label: "Matrix Admin",
        title: "Matrix Admin Menu Items",
        slug: "super",
        to: "/super",
        icon: <FontAwesomeIcon icon={faUserSecret} fixedWidth />,
        access: ["admin", "helpdesk", "appadmin"],
        children: [
            {
                id: 8.1,
                label: "Admins",
                title: "List of Matrix Admins",
                slug: "admins",
                to: "/super/admins",
                icon: <FontAwesomeIcon icon={faUsersCrown} fixedWidth />,
                children: false,
            },
        ],
    },
];
export default navitems;
