import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CardHeader, CardMedia } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        minWidth: 200,
    },

    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
const AppCardDisplay = (data) => {
    const { app } = data;
    const classes = useStyles();
    return (
        <Card className={classes.root} variant='outlined' raised>
            <CardContent>
                <div style={{ textAlign: "center" }}>
                    <img src={`${app.logoUrl}`} alt={`${app.label} Logo`} style={{ maxHeight: "60px" }} />
                </div>
                <div style={{ textAlign: "center" }}>{app.label}</div>
            </CardContent>
        </Card>
    );
};

export default AppCardDisplay;
