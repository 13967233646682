import axios from "axios";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import List from "../Common/List";
import StoresSalesTableData from "./StoresSalesTableData";

const StoreSalesPanel = (data) => {
    const { store, admin, showAdminBtns } = data;
    const { oktaAuth, authState } = useOktaAuth();
    const [showSales, setShowSales] = useState(false);
    const [salesData, setSalesData] = useState([]);

    const pagetitle = "Manually Reported Sales";
    const displaySalesEntries = async (storeguid) => {
        const token = oktaAuth.getAccessToken();
        axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getstoresalesdata`, {
                headers: {
                    authorization: `Bearer ${token}`,
                    storeguid,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setSalesData(_.orderBy(res.data.sales, "id", "desc"));
                }
            })
            .catch(function (error) {});
    };
    const localstoragekey = "matrixstoresalesview";

    useEffect(() => {
        if (store && store.ManualReporting === 1) {
            displaySalesEntries(store.FranchiseID);
            setShowSales(true);
        } else {
            setShowSales(false);
        }
    }, [store]);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {showSales && salesData ? (
                        // <List
                        //     pagetitle={pagetitle}
                        //     localstoragekey={localstoragekey}
                        //     listitems={salesData}
                        //     selectedRow={selectedRow}
                        //     setSelectedRow={setSelectedRow}
                        //     selectedColumns={selectedColumns}
                        //     setSelectedColumns={setSelectedColumns}
                        //     defaultColumns={defaultColumns}
                        //     defaultView={defaultView}
                        //     listfilter={filter}
                        //     headerRow={headerRow}
                        //     footerRow={footerRow}
                        //     resetList={resetList}
                        //     filterSortList={filterSortList}
                        //     parselistitems={parselistitems}
                        // />
                        <StoresSalesTableData pagetitle={pagetitle} localstoragekey={localstoragekey} listitems={salesData} showSelectCheckbox={false} canExport={true} />
                    ) : (
                        <>Store does not report sales manually.</>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default StoreSalesPanel;
