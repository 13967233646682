import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { isAfter, isBefore, differenceInDays, subDays, format, isSameDay, formatISO9075 } from "date-fns";
import { useOktaAuth } from "@okta/okta-react";
import MuiAlert from "@material-ui/lab/Alert";
import { Box, Button, Grid } from "@material-ui/core";
import { blue, grey, red } from "@material-ui/core/colors";

const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const BatchResendActivations = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const users = useSelector((state) => state.matrixusers.untouchedlist);
    const history = useHistory();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const [provisionedUsers, setProvisionedUsers] = useState([]);
    const [preparedProcessingGroups, setpreparedProcessingGroups] = useState([]);

    const cancelAPICalls = () => {
        source.cancel("Operation canceled by the user.");
    };
    const resendActivations = (user) => {
        let call = {
            method: "POST",
            href: `/api/v1/users/${user.id}/lifecycle/reactivate?sendEmail=true`,
        };
        call.action = "reactivate";
        call.username = user.profile.login;
        call.status = user.status;
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/oktalifecycleaction`, call, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
                cancelToken: source.token,
            })
            .then((res) => {
                if (res.data.success) {
                    console.log({ msg: res.data.message, color: "success", title: "Success!", persist: false });
                } else {
                    console.log({ msg: res.data.message, color: "danger", title: "Error!", persist: true });
                }
            })
            .catch(function (error) {});
    };
    const processUserData = (group) => {
        console.log(group);
        _.each(group, (user, i) => {
            resendActivations(user);
        });
    };
    useEffect(() => {
        if (users) {
            let _now = new Date();

            let _provisionedusers = _.compact(
                _.map(users, (user, i) => {
                    let _statuschanged = new Date(user.statusChanged);
                    let _dayssinceupdate = differenceInDays(_now, _statuschanged);

                    if (user.status === "PROVISIONED" && _dayssinceupdate >= 7) {
                        return user;
                    }
                })
            );
            setProvisionedUsers(_provisionedusers);
            let _chunkedJSONData = _.chunk(_provisionedusers, 500);
            setpreparedProcessingGroups(_chunkedJSONData);
        }
    }, [users]);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    Provisioned Accounts:
                </Grid>
                <Grid item xs={8}>
                    There are currently {provisionedUsers.length} Provisioned Matrix Users, that have not been sent an activation email in the past 7 days. Use the buttons below to batch reactivate
                    them. Wait 1 minute between each batch.
                </Grid>
                <Grid item xs={2}>
                    <RedButton variant='contained' size='small' onClick={cancelAPICalls}>
                        Cancel
                    </RedButton>
                </Grid>
                <Grid item xs={12}>
                    <Box display='flex' px={1}>
                        <Box p={1} flexGrow={1}>
                            <GreyButton variant='contained' size='small' onClick={() => history.push("/super/tools")}>
                                Cancel
                            </GreyButton>
                        </Box>
                        <Box p={1}>
                            {_.map(preparedProcessingGroups, (processGrp, i) => {
                                return (
                                    <Box key={i} m={1}>
                                        <BlueButton
                                            variant='contained'
                                            size='small'
                                            onClick={() => {
                                                processUserData(processGrp);
                                            }}>
                                            Process Group {i + 1}
                                        </BlueButton>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default BatchResendActivations;
