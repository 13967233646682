import { useEffect, useState } from "react";
import { getRequests } from "../actions";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Paper } from "@material-ui/core";
import { subDays } from "date-fns";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import useTheme from "@material-ui/core/styles/useTheme";

import AccessRequestsPanel from "../components/Main/AccessRequestsPanel";
import ProcessedRequestsPanel from "../components/Main/ProcessedRequestsPanel";
import ActivatedUsersPanel from "../components/Main/ActivatedUsersPanel";
import InfoControlsPanel from "../components/Main/InfoControlsPanel";
import UserSignInsPerDay from "../components/Main/UserSignInsPerDay";

const Main = () => {
    const dispatch = useDispatch();
    const _colormode = useTheme().palette.type;
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const currentAdmin = useSelector((state) => state.user.usable);
    const requests = useSelector((state) => state.requests.untouchedlist);
    const matrixusers = useSelector((state) => state.matrixusers.untouchedlist);
    const [daysCount, setDaysCount] = useState(30);
    const [locationView, setLocationView] = useState("domestic");
    const [startDate, setStartDate] = useState(subDays(new Date(), daysCount));
    const [endDate, setEndDate] = useState(new Date());
    const [colorMode, setColorMode] = useState(null);
    useEffect(() => {
        if (_.isEmpty(requests)) {
            dispatch(getRequests(token));
        }
    }, [requests]);
    useEffect(() => {
        setStartDate(subDays(new Date(), daysCount));
    }, [daysCount]);
    useEffect(() => {
        if (!_.isEmpty(currentAdmin)) {
            if (currentAdmin.profile.matrixAdminLocation !== "all") {
                setLocationView(currentAdmin.profile.matrixAdminLocation);
            }
        }
    }, [currentAdmin]);
    useEffect(() => {
        setColorMode(_colormode);
    }, [_colormode]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={1}>
                            <h1>Welcome!</h1>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={1}>
                            <InfoControlsPanel daysCount={daysCount} setDaysCount={setDaysCount} locationView={locationView} setLocationView={setLocationView} />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <AccessRequestsPanel startDate={startDate} endDate={endDate} requests={requests} locationView={locationView} colorMode={colorMode} />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <ProcessedRequestsPanel startDate={startDate} endDate={endDate} requests={requests} locationView={locationView} colorMode={colorMode} />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <ActivatedUsersPanel startDate={startDate} endDate={endDate} matrixusers={matrixusers} locationView={locationView} colorMode={colorMode} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={1}>{/* <UserSignInsPerDay startDate={startDate} endDate={endDate} /> */}</Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Main;
