import { faEnvelope, faToggleOff, faToggleOn, faUserEdit } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form } from "formik";
import { Box, Button, Divider, Grid, Link, Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import _ from "lodash";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import BrandIcons from "../Common/BrandIcons";
import LocationIcons from "../Common/LocationIcons";
import RoleIcons from "../Common/RoleIcons";
import UserStatusIcons from "../Common/UserStatusIcons";
import UserDetailMenuBar from "./UserDetailMenuBar";
import { isAfter, isBefore, differenceInDays, subDays, format, isSameDay, formatISO9075 } from "date-fns";
import MuiAlert from "@material-ui/lab/Alert";
import Countries from "i18n-iso-countries";
import { amber, blue, green, red, grey } from "@material-ui/core/colors";
import UserEditorForm from "../Common/UserEditorForm";
import ControlledSubmitBtn from "../Common/ControlledSubmitBtn";

const GreyBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[200]),
        backgroundColor: grey[200],
        "&:hover": {
            backgroundColor: grey[700],
            color: theme.palette.getContrastText(grey[700]),
        },
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const GreenBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Box);
const RedBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Box);
const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        borderRadius: "5px",
    },
}))(Box);
const AmberBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(amber[500]),
        backgroundColor: amber[500],
        "&:hover": {
            backgroundColor: amber[700],
        },
    },
}))(Box);

const UserDetailEditPanel = (data) => {
    const { user, admin, setEditPanel, getuserbyid } = data;
    const [editMenu, setEditMenu] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const formSchema = Yup.object().shape({
        firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
        lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        secondEmail: Yup.string().email("Invalid email").nullable(),
        userRole: Yup.string().required("Required"),
        location: Yup.string().required("Required"),
        assocBrands: Yup.array().required("Required"),
        primaryPhone: Yup.string().matches(phoneRegExp, "Phone number is not valid").nullable(),
        mobilePhone: Yup.string().matches(phoneRegExp, "Phone number is not valid").nullable(),
        streetAddress: Yup.string().min(2, "Too Short!").max(50, "Too Long!").nullable(),
        city: Yup.string().min(2, "Too Short!").max(50, "Too Long!").nullable(),
        zipCode: Yup.mixed().nullable(),
    });
    const [initFormValues, setInitFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        userRole: "",
        location: "",
        countryCode: "",
        assocBrands: "",
        secondEmail: "",
        primaryPhone: "",
        mobilePhone: "",
    });
    const [showFormHelp, setShowFormHelp] = useState(false);
    const [formActive, setFormActive] = useState(false);
    const [lookupEmailValidMsg, setLookupEmailValidMsg] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const checkContactEmail = async (email) => {
        setLookupEmailValidMsg("");
        const token = oktaAuth.getAccessToken();
        axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/getusersbycontactemail`,
                {
                    email,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                if (res.data.success) {
                    setLookupEmailValidMsg(
                        <AmberBox p={1} style={{ borderRadius: "5px" }}>
                            <small>
                                <div>
                                    This email address ({email}) has been used for {res.data.users.length} existing accounts. Please verify that you are not creating a duplicate account.
                                </div>
                                <ol>
                                    {_.map(res.data.users, (user, i) => {
                                        return (
                                            <li key={i}>
                                                <a href={`/users/${user.id}`} target='_blank' rel='noopener noreferrer'>
                                                    {user.profile.displayName} - {user.profile.login}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ol>
                            </small>
                        </AmberBox>
                    );
                } else {
                    setLookupEmailValidMsg("");
                }
            })
            .catch(function (error) {});
    };
    const handleSubmit = async (values) => {
        // if (_.includes(values.assocVenueType, "traditional") && _.includes(values.assocVenueType, "non-traditional")) {
        //     values.assocVenueType = "both";
        // } else {
        //     values.assocVenueType = _.toString(values.assocVenueType);
        // }
        // if (values.facMember === "Yes") {
        //     values.facMember = true;
        // } else {
        //     values.facMember = false;
        //     values.facTitle = "";
        // }
        let submitdata = {
            id: user.id,
            profile: {
                assocBrands: values.assocBrands,
                // assocVenueType: values.assocVenueType,
                city: values.city,
                countryCode: values.countryCode,
                displayName: `${values.firstName} ${values.lastName}`,
                email: values.email,
                // facMember: values.facMember,
                // facTitle: values.facTitle,
                firstName: values.firstName,
                lastName: values.lastName,
                location: values.location,
                mobilePhone: values.mobilePhone,
                primaryPhone: values.primaryPhone,
                secondEmail: values.secondEmail,
                state: values.state,
                streetAddress: values.streetAddress,
                userRole: values.userRole,
                zipCode: values.zipCode,
            },
        };
        console.log(submitdata);
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserprofile`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );

                    setTimeout(() => {
                        setEditPanel(false);
                        getuserbyid(user.id);
                    }, 2500);
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const toggleHelp = () => setShowFormHelp(!showFormHelp);
    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    useEffect(() => {
        if (user) {
            let _item = user.profile;
            let initValues = { ...initFormValues, ..._item };
            setInitFormValues(initValues);
            setFormActive(true);
            if (_item.email) {
                checkContactEmail(_item.email);
            }
        }
    }, [user]);
    return (
        <>
            {formActive ? (
                <Formik initialValues={initFormValues} onSubmit={handleSubmit} validationSchema={formSchema} enableReinitialize>
                    {(props) => {
                        let _menu = (
                            <Box display='flex'>
                                <Box>
                                    <ControlledSubmitBtn
                                        label='Save Changes'
                                        roles={["super", "admin", "appsadmin", "helpdesk"]}
                                        currentAdmin={admin}
                                        disabled={!props.dirty || props.isSubmitting || !props.isValid}
                                    />
                                </Box>
                                <Box p={1}>
                                    <small style={{ color: "red" }}>
                                        <sup>*</sup>
                                    </small>
                                    Required
                                </Box>
                                <Box mx={1} flexGrow={1}>
                                    <Divider orientation='vertical' />
                                </Box>
                                <Box>
                                    {showFormHelp ? (
                                        <BlueButton variant='contained' size='small' onClick={toggleHelp}>
                                            <FontAwesomeIcon icon={faToggleOn} />
                                            &nbsp;Help
                                        </BlueButton>
                                    ) : (
                                        <GreyButton variant='contained' size='small' onClick={toggleHelp}>
                                            <FontAwesomeIcon icon={faToggleOff} />
                                            &nbsp;Help
                                        </GreyButton>
                                    )}
                                </Box>
                                <Box mx={1}>
                                    <Button color='inherit' onClick={() => setEditPanel(false)}>
                                        <FontAwesomeIcon icon={faUserEdit} />
                                        &nbsp;Close
                                    </Button>
                                </Box>
                            </Box>
                        );
                        return (
                            <Form>
                                <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                                    <>{toastContent}</>
                                </Snackbar>
                                <UserDetailMenuBar page='editdetails' menu={_menu} />
                                <UserEditorForm
                                    props={props}
                                    showFormHelp={showFormHelp}
                                    checkContactEmail={checkContactEmail}
                                    lookupEmailValidMsg={lookupEmailValidMsg}
                                    setLookupEmailValidMsg={setLookupEmailValidMsg}
                                />
                                <UserDetailMenuBar page='editdetails' menu={_menu} />
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                ""
            )}
        </>
    );
};

export default UserDetailEditPanel;
