import { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faSquare } from "@fortawesome/pro-duotone-svg-icons";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);
const AdminRoleIcons = ({ slug, label }) => {
    const roles = useSelector((state) => state.adminroles.untouchedlist);
    const [display, setdisplay] = useState("");
    useEffect(() => {
        if (roles && slug) {
            if (slug === "not set") {
                setdisplay(
                    <>
                        <span className='fa-lg'>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon icon={faSquare} color='white' />
                                <FontAwesomeIcon title={"NOT SET"} style={{ color: "red" }} icon={["fad", "exclamation-triangle"]} transform='shrink-5' />
                            </span>
                        </span>
                        {label ? <>&nbsp;NOT SET</> : ""}
                    </>
                );
            } else {
                let _role = _.find(roles, { slug });
                setdisplay(
                    <>
                        <span className='fa-lg'>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon icon={faSquare} color='white' />
                                <FontAwesomeIcon title={_role.name} style={{ color: _role.hexcolor }} icon={["fad", _role.icon]} transform='shrink-5' />
                            </span>
                        </span>
                        {label ? <>&nbsp;{_role.name}</> : ""}
                    </>
                );
            }
        }
    }, [slug, roles]);
    return display;
};

export default AdminRoleIcons;
