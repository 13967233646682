import { useCallback } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import DataTable from "../Common/DataTable";
import BrandIcons from "../Common/BrandIcons";
import { Dropdown } from "primereact/dropdown";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import LocationIcons from "../Common/LocationIcons";
import RoleIcons from "../Common/RoleIcons";
import { format } from "date-fns";
import DataLoading from "../Common/DataLoading";

const RequestsTableData = (data) => {
    const { pagetitle, localstoragekey, listitems } = data;
    const brands = useSelector((state) => state.brands.untouchedlist);
    // Cell Data
    const requestDisplayNameCellData = (originalRow, rowIndex) => {
        return `${originalRow.firstName} ${originalRow.lastName}`;
    };
    const requestedDateCellData = (originalRow, rowIndex) => {
        return `${format(new Date(originalRow.request_date), "MMMM do, yyyy")}`;
    };

    // Cell Templates
    const requestDisplayNameTemplate = ({ row, value }) => {
        return (
            <div style={{ textTransform: "capitalize" }}>
                {row.original.firstName.toLowerCase()} {row.original.lastName.toLowerCase()}
            </div>
        );
    };
    const requestStatusTemplate = ({ row, value }) => {
        return <div style={{ textAlign: "center" }}>{value}</div>;
    };
    const assocBrandsCell = ({ row, value }) => {
        let _brdarry;
        if (Array.isArray(value)) {
            // console.log("an array");
            _brdarry = value;
        } else {
            _brdarry = value.split(";");
        }
        return (
            <div style={{ textAlign: "center" }} px={1}>
                {_.map(_brdarry, (brand, i) => {
                    return <BrandIcons key={i} slug={brand} location={row.original.location} />;
                })}
            </div>
        );
    };
    const locationTemplate = ({ row, value }) => {
        return (
            <div style={{ textAlign: "center" }}>
                <LocationIcons slug={value} />
            </div>
        );
    };
    const roleTemplate = ({ row, value }) => {
        return (
            <div style={{ textAlign: "center" }}>
                <RoleIcons slug={value} />
            </div>
        );
    };
    const dateTemplate = ({ row, value }) => {
        return <div style={{ textAlign: "center" }}>{format(new Date(value), "MMMM do, yyyy")}</div>;
    };
    // Filters
    const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = useMemo(() => {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                options.add(row.values[id]);
            });
            return [...options.values()];
        }, [id, preFilteredRows]);

        // Render a multi-select box
        return (
            <>
                <FormControl fullWidth>
                    <Select
                        id='filter-select'
                        style={{ textTransform: "capitalize" }}
                        value={filterValue || ""}
                        fullWidth
                        defaultValue={""}
                        onChange={(e) => {
                            setFilter(e.target.value || undefined);
                            // console.log(e.target);
                            // setFilter(["aa"]);
                            // const allValues = Array.from(e.target.value)
                            //     .map((o) => o.value)
                            //     .filter(Boolean);
                            // setFilter(allValues && allValues.length ? allValues : undefined);
                        }}>
                        <MenuItem value={""}>All</MenuItem>
                        {_.map(options, (option, i) => (
                            <MenuItem key={i} value={option} style={{ textTransform: "capitalize" }}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        );
    };
    const SelectBrandFilter = useCallback(
        ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
            // Calculate the options for filtering
            // using the preFilteredRows
            let options = _.compact(
                _.map(brands, (brand, i) => {
                    if (!brand.active && brand.slug !== "all") {
                        return brand;
                    }
                })
            );
            return (
                <>
                    <FormControl fullWidth>
                        {/* <InputLabel id='brand-filter-label' style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            Select Brand
                        </InputLabel> */}
                        <Select
                            labelId='brand-filter-label'
                            id='brand-filter-select'
                            value={filterValue || ""}
                            fullWidth
                            defaultValue={""}
                            onChange={(e) => {
                                let _brand = _.find(options, ["slug", e.target.value]);
                                if (_brand) {
                                    setFilter(_brand.slug || "");
                                } else {
                                    setFilter(undefined);
                                }
                                // console.log(e.target);
                                // setFilter(["aa"]);
                                // const allValues = Array.from(e.target.value)
                                //     .map((o) => o.value)
                                //     .filter(Boolean);
                                // setFilter(allValues && allValues.length ? allValues : undefined);
                            }}>
                            <MenuItem value={""}>All</MenuItem>
                            {_.map(options, (brand, i) => (
                                <MenuItem key={i} value={brand.slug}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            );
        },
        [brands]
    );
    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: requestDisplayNameCellData,
                id: "name",
                Cell: requestDisplayNameTemplate,
                filter: "fuzzyText",
            },
            {
                Header: "Email",
                accessor: "email",
                id: "email",
                filter: "fuzzyText",
            },
            {
                Header: "Requested On",
                accessor: "request_date",
                id: "request_date",
                Cell: dateTemplate,
                disableFilters: true,
            },
            {
                Header: "Role",
                accessor: "userRole",
                id: "userRole",
                Cell: roleTemplate,
                Filter: SelectColumnFilter,
            },
            {
                Header: "Brands",
                accessor: "assocBrands",
                id: "assocBrands",
                Cell: assocBrandsCell,
                Filter: SelectBrandFilter,
            },
            {
                Header: "Location",
                accessor: "location",
                id: "location",
                Cell: locationTemplate,
                Filter: SelectColumnFilter,
            },
            {
                Header: "Status",
                accessor: "status",
                id: "status",
                Cell: requestStatusTemplate,
                Filter: SelectColumnFilter,
            },
        ],
        [SelectBrandFilter]
    );

    return listitems ? (
        <DataTable
            pagetitle={pagetitle}
            localstoragekey={localstoragekey}
            listitems={listitems}
            columns={columns}
            createnew={{ label: "Create New User", path: "/createuser" }}
            clickable={true}
            selectAllEnabled={false}
            showSelectCheckbox={false}
            excelFileName={"matrixaccessrequests"}
            canExport={true}
            idfield={"id"}
            pageslug={"accessrequests"}
            filterData={[{ id: "status", value: "Pending" }]}
        />
    ) : (
        <DataLoading />
    );
};

export default RequestsTableData;
