import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { isAfter, isBefore, differenceInDays, subDays, format, isSameDay, formatISO9075 } from "date-fns";
import { useOktaAuth } from "@okta/okta-react";
import MuiAlert from "@material-ui/lab/Alert";
import { Box, Button, Divider, Grid, TextField } from "@material-ui/core";
import { blue, grey, red } from "@material-ui/core/colors";

const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);

const ResyncOktaToDB = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const [allUsers, setAllUsers] = useState([]);
    const [allDBUsers, setAllDBUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [chunkedUserArry, setChunkedUserArry] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [oktaUserResult, setOktaUserResult] = useState(false);
    const [dbUserResult, setDbUserResult] = useState(false);
    let _allusers = [];
    const getAllDBUsers = () => {
        axios
            .get(process.env.REACT_APP_APIHOST + "/api/getallusers", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setAllDBUsers(res.data.users);
                } else {
                    console.log(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const getUsers = () => {
        _allusers = [];
        axios
            .get(process.env.REACT_APP_APIHOST + "/api/getusersfromokta", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    _allusers = _.concat(_allusers, res.data.users);
                    console.log(_allusers);
                    setUserCount(_allusers.length);
                    if (res.data.pagingLinks && res.data.pagingLinks.next) {
                        pageUsers(token, res.data.pagingLinks.next.url);
                    } else {
                        setAllUsers(_.uniqBy(_.concat(_allusers, res.data.users), "id"));
                    }
                } else {
                    console.log(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const pageUsers = (token, url) => {
        axios
            .get(process.env.REACT_APP_APIHOST + "/api/pageusers", {
                headers: {
                    authorization: `Bearer ${token}`,
                    url,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    _allusers = _.concat(_allusers, res.data.users);
                    console.log(_allusers);
                    setUserCount(_allusers.length);
                    if (res.data.pagingLinks && res.data.pagingLinks.next) {
                        if (process.env.NODE_ENV === "development") {
                            if (_allusers.length <= 14000) {
                                // comment out the following line if you need to get all users
                                pageUsers(token, res.data.pagingLinks.next.url);
                            } else {
                                setAllUsers(_.uniqBy(_.concat(_allusers, res.data.users), "id"));
                            }
                            // uncomment this line if you need to get all users
                            // pageUsers(token, res.data.pagingLinks.next.url);
                        } else {
                            pageUsers(token, res.data.pagingLinks.next.url);
                        }
                    } else {
                        console.log("no more");
                        setAllUsers(_.uniqBy(_.concat(_allusers, res.data.users), "id"));
                    }
                } else {
                    console.log(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const processUserData = (group) => {
        console.log(group);
        _.each(group, (user, i) => {
            handleSubmit(user);
        });
    };
    const handleSubmit = async (user) => {
        // let _profilevalues = _.omit(user.profile, ["login", "firstName", "lastName"]);
        let submitdata = { ...user, profile: user.profile };
        updateProfileDB(submitdata);
    };
    const updateProfileDB = (submitdata) => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserprofiledb`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleChange = (e) => {
        setSearchValue(e.target.value);
    };
    const searchUsers = (id) => {
        console.log(id);
        let _oktauser = _.find(allUsers, { id: id });
        console.log(_oktauser);
        setOktaUserResult(_oktauser);
        let _dbuser = _.find(allDBUsers, { id: id });
        console.log(_dbuser);
        setDbUserResult(_dbuser);
    };
    useEffect(() => {
        if (!_.isEmpty(allUsers)) {
            setChunkedUserArry(_.chunk(allUsers, 1000));
        }
    }, [allUsers]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <div>Total Accounts:</div>
                    <div>
                        <BlueButton
                            variant='contained'
                            size='small'
                            onClick={() => {
                                getUsers();
                                getAllDBUsers();
                            }}>
                            Get All Users
                        </BlueButton>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    There are currently <strong>{userCount}</strong> Okta Users,
                </Grid>
            </Grid>
            <Box py={3}>
                <Divider />
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    {_.map(chunkedUserArry, (processGrp, i) => {
                        return (
                            <Box key={i} m={1}>
                                <BlueButton
                                    variant='contained'
                                    size='small'
                                    onClick={() => {
                                        processUserData(processGrp);
                                    }}>
                                    Process {i + 1}
                                </BlueButton>
                            </Box>
                        );
                    })}
                </Grid>
                <Grid item xs={10}>
                    <TextField id='standard-basic' onChange={handleChange} fullWidth variant='outlined' margin='dense' value={searchValue} label='Use Okta GUID' />
                    <BlueButton
                        variant='contained'
                        size='small'
                        onClick={() => {
                            searchUsers(searchValue);
                        }}>
                        Search Users
                    </BlueButton>
                    <Box py={3}>
                        <Divider />
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div>Okta User Details</div>
                            {oktaUserResult ? <pre>{JSON.stringify(oktaUserResult, false, 4)}</pre> : ""}
                        </Grid>
                        <Grid item xs={6}>
                            <div>DB User Details</div>
                            {dbUserResult ? <pre>{JSON.stringify(dbUserResult, false, 4)}</pre> : ""}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ResyncOktaToDB;
