import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isAfter, isBefore, differenceInDays, subDays, format, isSameDay } from "date-fns";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea, faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { Box, Grid } from "@material-ui/core";
import { Chart } from "primereact/chart";
const colormode = localStorage.getItem("color-mode");

const UserSignInsPerDay = ({ startDate, endDate }) => {
    const brands = useSelector((state) => state.brands.untouchedlist);
    const [signInEvents, setSignInEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loginData, setLoginData] = useState({});
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [chartLabels, setChartLabels] = useState([]);
    const [displayDataArry, setDisplayDataArry] = useState([]);
    const matrixsignins = useSelector((state) => state.matrixsignins.untouchedlist);

    let _startDate = subDays(new Date(endDate), 14);
    let _endDate = endDate;
    useEffect(() => {
        if (matrixsignins && brands) {
            let _matrixsignins = _.compact(
                _.map(matrixsignins, (event, i) => {
                    const _publishedDate = new Date(event.publisheddate);
                    if (isAfter(_publishedDate, _startDate) && isBefore(_publishedDate, _endDate)) {
                        return event;
                    }
                })
            );
            setSignInEvents(_matrixsignins);
        }
    }, [endDate, matrixsignins, brands]);
    useEffect(() => {
        if (!_.isEmpty(signInEvents)) {
            let _numofdaysdisplayed = differenceInDays(_endDate, _startDate);
            let _daystodisplay = _.reverse(
                _.map(Array(_numofdaysdisplayed), (pos, i) => {
                    return subDays(_endDate, i);
                })
            );
            let _serieslabels = _.map(_daystodisplay, (day, i) => {
                return format(new Date(day), "EEE MMM do yyyy");
            });
            let _logindata = {
                successlogins: _.map(_daystodisplay, (day, i) => {
                    const daysignins = _.compact(
                        _.map(signInEvents, (tick, i) => {
                            let _tickdate = new Date(tick.publisheddate);
                            if (isSameDay(_tickdate, day) && tick.outcomeresult === "SUCCESS") {
                                return tick;
                            }
                        })
                    );

                    return daysignins.length;
                }),
                failedlogins: _.map(_daystodisplay, (day, i) => {
                    const daysignins = _.compact(
                        _.map(signInEvents, (tick, i) => {
                            let _tickdate = new Date(tick.publisheddate);
                            if (isSameDay(_tickdate, day) && tick.outcomereason === "GENERAL_NONSUCCESS") {
                                return tick;
                            }
                        })
                    );

                    return daysignins.length;
                }),
                unknownlogins: _.map(_daystodisplay, (day, i) => {
                    const daysignins = _.compact(
                        _.map(signInEvents, (tick, i) => {
                            let _tickdate = new Date(tick.publisheddate);
                            if (isSameDay(_tickdate, day) && (tick.outcomereason === "VERIFICATION_ERROR" || tick.outcomereason === "UNKNOWN_USER")) {
                                return tick;
                            }
                        })
                    );

                    return daysignins.length;
                }),
                invalidcredlogins: _.map(_daystodisplay, (day, i) => {
                    const daysignins = _.compact(
                        _.map(signInEvents, (tick, i) => {
                            let _tickdate = new Date(tick.publisheddate);
                            if (isSameDay(_tickdate, day) && tick.outcomereason === "INVALID_CREDENTIALS") {
                                return tick;
                            }
                        })
                    );

                    return daysignins.length;
                }),
                lockedoutattempts: _.map(_daystodisplay, (day, i) => {
                    const daysignins = _.compact(
                        _.map(signInEvents, (tick, i) => {
                            let _tickdate = new Date(tick.publisheddate);
                            if (isSameDay(_tickdate, day) && tick.outcomereason === "LOCKED_OUT") {
                                return tick;
                            }
                        })
                    );

                    return daysignins.length;
                }),
                notactivatedlogins: _.map(_daystodisplay, (day, i) => {
                    const daysignins = _.compact(
                        _.map(signInEvents, (tick, i) => {
                            let _tickdate = new Date(tick.publisheddate);
                            if (isSameDay(_tickdate, day) && tick.outcomereason === "NOT_ACTIVATED") {
                                return tick;
                            }
                        })
                    );

                    return daysignins.length;
                }),
            };

            setLoginData(_logindata);

            setChartLabels(_serieslabels);
            // setDisplayDataArry(_daystodisplay);
            setIsLoading(false);
        }
    }, [endDate, signInEvents]);
    useEffect(() => {
        let _chartData;
        let _chartOptions;
        console.log("colorchanged", colormode);
        if (!_.isEmpty(loginData)) {
            _chartData = {
                labels: chartLabels,
                datasets: [
                    {
                        type: "line",
                        label: "Successful Logins",
                        borderColor: colormode === "light" ? "blue" : "red",
                        borderWidth: 2,
                        fill: false,
                        data: loginData.successlogins,
                    },
                    {
                        type: "bar",
                        label: "Failed Logins",
                        backgroundColor: "#AD5922",
                        data: loginData.failedlogins,
                    },
                    {
                        type: "bar",
                        label: "Unknown Username",
                        backgroundColor: "#298271",
                        data: loginData.unknownlogins,
                    },
                    {
                        type: "bar",
                        label: "Invalid Credentials",
                        backgroundColor: "#85091A",
                        data: loginData.invalidcredlogins,
                    },
                    {
                        type: "bar",
                        label: "While Locked Out",
                        backgroundColor: "#145200",
                        data: loginData.lockedoutattempts,
                    },
                    {
                        type: "bar",
                        label: "While Not Activated",
                        backgroundColor: "#EEA16F",
                        data: loginData.notactivatedlogins,
                    },
                ],
            };
            _chartOptions = {
                legend: {
                    labels: {
                        fontColor: "#495057",
                    },
                    position: "right",
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontColor: "#495057",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                fontColor: "#495057",
                            },
                        },
                    ],
                },
            };
        }
        setChartData(_chartData);
        setChartOptions(_chartOptions);
    }, [colormode, loginData]);
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box p={2}>
                        <FontAwesomeIcon icon={faChartArea} fixedWidth size='2x' />
                        &nbsp;
                        <span style={{ fontSize: "1.4rem", textTransform: "uppercase" }}>Sign Ins for the past 2 weeks</span>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={2}>{!isLoading && chartData && chartOptions ? <Chart type='bar' data={chartData} options={chartOptions} /> : "No Records to view"}</Box>
                </Grid>
            </Grid>
        </>
    );
};

export default UserSignInsPerDay;
