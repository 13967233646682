import _ from "lodash";
import Fuse from "fuse.js";
const initialState = {
    untouched: null,
    usable: null,
};
const currentUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_USER":
            return { untouched: action.payload, usable: action.payload };

        default:
            return state;
    }
};
export default currentUserReducer;
