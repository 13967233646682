import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import _ from "lodash";
import EmailLookupField from "../Fields/EmailLookupField";
import SimpleTextField from "../Fields/SimpleTextField";

import random from "./randomfacts.json";
import countrycodes from "./countrycodes";
import RadioField from "../Fields/RadioField";
import AdminRoleIcons from "./AdminRoleIcons";
import CheckboxField from "../Fields/CheckboxField";
import BrandIcons from "./BrandIcons";
import { cyan, green } from "@material-ui/core/colors";
import ChoiceField from "../Fields/ChoiceField";
import UserNameField from "../Fields/UserNameField";
const CyanBox = withStyles((theme) => ({
    root: {
        border: "thin solid",
        borderColor: cyan[500],
        borderRadius: "5px",
    },
}))(Box);
const GreenBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Box);
const AdminUserEditorForm = ({ props, user, showFormHelp, currentAdmin, checkedUsername, validateUsername, validUsername, setValidusername, usernameValidMsg, adminUser }) => {
    const brands = useSelector((state) => state.brands.untouchedlist);
    const adminRoles = useSelector((state) => state.adminroles.untouchedlist);
    return (
        <Grid container spacing={1}>
            {!user ? (
                <Grid item xs={12}>
                    <UserNameField
                        props={props}
                        showFormHelp={showFormHelp}
                        fieldHelpText={`You'll need to have the Focus Brands username to add a new admin.`}
                        autofilldomain={"@focusbrands.com"}
                        checkedUsername={checkedUsername}
                        validateUsername={validateUsername}
                        setValidusername={setValidusername}
                        usernameValidMsg={usernameValidMsg}
                    />
                    {validUsername === "valid" ? (
                        <GreenBox p={1} style={{ borderRadius: "5px" }}>
                            <small>
                                {adminUser.profile.displayName} ({adminUser.profile.login})
                            </small>
                        </GreenBox>
                    ) : (
                        ""
                    )}
                </Grid>
            ) : (
                ""
            )}
            <Grid item md={3} xs={12}>
                <RadioField
                    props={props}
                    fieldname={"matrixAdminRole"}
                    options={_.orderBy(
                        _.compact(
                            _.map(adminRoles, (role, i) => {
                                if (role.active) {
                                    let _roleObj = {
                                        slug: role.slug,
                                        label: <AdminRoleIcons slug={role.slug} label={true} />,
                                        level: role.level,
                                    };
                                    return _roleObj;
                                }
                            })
                        ),
                        "level",
                        "asc"
                    )}
                    fieldlabel='Admin Role'
                    fieldrequired={true}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>This determines what kind of content this account has access to. Please choose wisely.</>}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <RadioField
                    props={props}
                    fieldname={"matrixAdminLocation"}
                    options={[
                        { slug: "domestic", label: "Domestic" },
                        { slug: "international", label: "International" },
                        { slug: "all", label: "Both" },
                    ]}
                    fieldlabel='Location'
                    fieldrequired={true}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>This is based on the location the user should be associated with. An account can be associated with both domestic and international locations.</>}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <RadioField
                    props={props}
                    fieldname={"brandGroupSelection"}
                    options={[
                        { slug: "", label: <>Show Brands</> },
                        { slug: "all", label: <>All brands</> },
                        { slug: "specialty", label: <>Specialty brands</> },
                        { slug: "restaurants", label: <>Restaurants brands</> },
                    ]}
                    fieldlabel='Brand Group'
                    fieldrequired={false}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>This is based on the location the user should be associated with. An account can be associated with both domestic and international locations.</>}
                />
                {_.isEmpty(props.values.brandGroupSelection) ? (
                    <CheckboxField
                        props={props}
                        fieldname={"matrixAdminAssocBrands"}
                        options={_.orderBy(
                            _.compact(
                                _.map(brands, (brand, i) => {
                                    if (props.values.matrixAdminLocation === "all") {
                                        if (brand.active) {
                                            let _brandObj = {
                                                slug: brand.slug,
                                                label: <BrandIcons slug={brand.frm_name} location={false} label={true} />,
                                            };
                                            return _brandObj;
                                        }
                                    } else {
                                        if (brand.active && props.values.matrixAdminLocation === brand.location) {
                                            let _brandObj = {
                                                slug: brand.slug,
                                                label: <BrandIcons slug={brand.slug} location={props.values.matrixAdminLocation} label={true} />,
                                            };
                                            return _brandObj;
                                        }
                                    }
                                })
                            ),
                            "slug",
                            "asc"
                        )}
                        fieldlabel='Associated Brand(s)'
                        fieldrequired={true}
                        showFormHelp={showFormHelp}
                        fieldHelpText={<>Please choose the brands that the user should be associated with.</>}
                    />
                ) : (
                    ""
                )}
            </Grid>
        </Grid>
    );
};

export default AdminUserEditorForm;
