import { faBallot, faCheckSquare, faMagic, faSquare, faTimesSquare } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { format } from "date-fns";
import BrandIcons from "./BrandIcons";
import RoleIcons from "./RoleIcons";
import UserStatusIcons from "./UserStatusIcons";
import { Box, Chip, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import lightGreen from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";
import LocationIcons from "./LocationIcons";

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#A52031",
        color: "#ffffff",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
});
export const simpleTextCell = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    return <>{_cellvalue}</>;
};
export const booleanCellTmp = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    if (_cellvalue) {
        return (
            <div style={{ textAlign: "center", color: lightGreen["500"] }}>
                <FontAwesomeIcon icon={faCheckSquare} size='lg' title='Active' />
            </div>
        );
    } else {
        return (
            <div style={{ textAlign: "center", color: red[500] }}>
                <FontAwesomeIcon icon={faTimesSquare} size='lg' title='Inactive' />
            </div>
        );
    }
};
export const basicTextCellTmp = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    return (
        <div style={{ textAlign: "center", maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            <span title={_cellvalue}>{_cellvalue}</span>
        </div>
    );
};
export const salescellTmp = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    return <div style={{ textAlign: "center", maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{formatter.format(_cellvalue)}</div>;
};
export const usernameCellTmp = (rowData) => {
    return (
        <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", textTransform: "lowercase" }}>
            <span title={rowData.profile.login}>{rowData.profile.login}</span>
        </div>
    );
};

export const smallcodeCellTmp = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    return (
        <small>
            <code>{_cellvalue}</code>
        </small>
    );
};
export const franchiseStatusCellTmp = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    return (
        <div style={{ textAlign: "center" }} px={1}>
            {_cellvalue}
        </div>
    );
};
export const requestStatusCellTmp = (rowData) => {
    return <div style={{ textAlign: "center" }}>{rowData.status}</div>;
};
export const brandcellTmp = (rowData) => {
    let _brdarry;
    if (Array.isArray(rowData.assocBrands)) {
        // console.log("an array");
        _brdarry = rowData.assocBrands;
    } else {
        _brdarry = rowData.assocBrands.split(";");
    }
    return (
        <div style={{ textAlign: "center" }} px={1}>
            {_.map(_brdarry, (brand, i) => {
                return <BrandIcons key={i} slug={brand} location={rowData.location} />;
            })}
        </div>
    );
};
export const userbrandcellTmp = (rowData) => {
    let _brdarry = rowData.profile.assocBrands;

    return (
        <div style={{ textAlign: "center" }} px={1}>
            {_.map(_brdarry, (brand, i) => {
                return <BrandIcons key={i} slug={brand} location={rowData.profile.location} />;
            })}
        </div>
    );
};
export const frmBrandcellTmp = (rowData) => {
    return (
        <div style={{ textAlign: "center" }}>
            <BrandIcons slug={rowData.Brand} />
        </div>
    );
};
export const appAssignmentcellTmp = (rowData) => {
    return (
        <div style={{ textAlign: "center" }}>
            {rowData.oktagroupid === "okta_rule" ? <FontAwesomeIcon icon={faMagic} title='Auto/Rule Assignment' /> : <FontAwesomeIcon icon={faBallot} title='Manual Assignment' />}
        </div>
    );
};
export const locationcellTmp = (rowData) => {
    return (
        <div style={{ textAlign: "center" }}>
            <LocationIcons slug={rowData.location} />
        </div>
    );
};
export const userlocationcellTmp = (rowData, cellData) => {
    let _cellvalue = _.at(rowData, cellData.field);
    return (
        <div style={{ textAlign: "center" }}>
            <LocationIcons slug={_cellvalue} />
        </div>
    );
};
export const reqDisplaynameCellTmp = (rowData) => {
    if (rowData.displayName) {
        return <div style={{ textTransform: "capitalize" }}>{rowData.displayName.toLowerCase()}</div>;
    } else {
        return (
            <div style={{ textTransform: "capitalize" }}>
                {rowData.firstName.toLowerCase()} {rowData.lastName.toLowerCase()}
            </div>
        );
    }
};
export const displaynameCellTmp = (rowData) => {
    if (rowData.profile.displayName) {
        return <div style={{ textTransform: "capitalize" }}>{rowData.profile.displayName.toLowerCase()}</div>;
    } else {
        return (
            <div style={{ textTransform: "capitalize" }}>
                {rowData.profile.firstName.toLowerCase()} {rowData.profile.lastName.toLowerCase()}
            </div>
        );
    }
};
export const requestDatecellTmp = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    return <div style={{ textAlign: "center" }}>{_cellvalue ? format(new Date(_cellvalue), "MMMM do, yyyy") : ""}</div>;
};
export const basicDatecellTmp = (rowData, cellData) => {
    let _cellvalue = rowData[cellData.field];
    return <div style={{ textAlign: "center" }}>{_cellvalue ? format(new Date(_cellvalue), "MMMM do, yyyy") : ""}</div>;
};
export const requestApprovedBy = (rowData, cellData) => {
    let _cellvalue = rowData.approvalData;
    _cellvalue = JSON.parse(_cellvalue);
    return <div style={{ textAlign: "center" }}>{_cellvalue ? _cellvalue.approvingAdmin.profile.displayName : ""}</div>;
};
export const appadmincellTmp = (rowData) => {
    return (
        <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            <a href={`mailto: ${rowData.adminemail}`} style={{ color: "var(--text-color)", fontWeight: "bold" }}>
                {rowData.adminname}
            </a>
        </div>
    );
};
export const rolecellTmp = (rowData) => {
    return (
        <div style={{ textAlign: "center" }}>
            <RoleIcons slug={rowData.userRole} />
        </div>
    );
};
export const userrolecellTmp = (rowData) => {
    return (
        <div style={{ textAlign: "center" }}>
            <RoleIcons slug={rowData.profile.userRole} />
        </div>
    );
};
export const reqEmailcellTmp = (rowData) => {
    return (
        <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            <span title={rowData.email}>{rowData.email}</span>
        </div>
    );
};
export const emailcellTmp = (rowData) => {
    return (
        <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", textTransform: "lowercase" }}>
            <span title={rowData.profile.email}>{rowData.profile.email}</span>
        </div>
    );
};
export const storeNumberCellTmp = (rowData) => {
    return (
        <>
            <CustomTooltip title={rowData.StoreNumber || ""}>
                <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rowData.StoreNumber}</div>
            </CustomTooltip>
        </>
    );
};
export const storeLocationCellTmp = (rowData) => {
    return (
        <>
            <CustomTooltip title={rowData.Location || ""}>
                <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rowData.Location}</div>
            </CustomTooltip>
        </>
    );
};
export const storeFranchiseNameCellTmp = (rowData) => {
    return (
        <>
            <CustomTooltip title={rowData.FranchiseBusinessName || ""}>
                <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rowData.FranchiseBusinessName}</div>
            </CustomTooltip>
        </>
    );
};
export const storePCCellTmp = (rowData) => {
    // console.log(rowData.PrimaryContact, rowData.PrimaryContactEmail);
    return (
        <>
            <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rowData.PrimaryContact}</div>
        </>
    );
};
export const storeCityCellTemp = (rowData) => {
    return (
        <>
            <CustomTooltip title={rowData.City || ""}>
                <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rowData.City}</div>
            </CustomTooltip>
        </>
    );
};
export const storeStateCellTemp = (rowData) => {
    return (
        <>
            <CustomTooltip title={rowData.State || ""}>
                <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rowData.State}</div>
            </CustomTooltip>
        </>
    );
};
export const storeAddressCellTemp = (rowData) => {
    return (
        <>
            <CustomTooltip
                title={
                    <>
                        <div>{rowData.Address}</div>
                        {rowData.Address2 ? <div>{rowData.Address2}</div> : ""}
                    </>
                }>
                <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{rowData.Address}</div>
            </CustomTooltip>
        </>
    );
};
export const userStatuscellTemp = (rowData, cellData) => {
    return (
        <div style={{ textAlign: "center" }}>
            <UserStatusIcons status={rowData[cellData.field]} />
        </div>
    );
};
