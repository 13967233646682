import _ from "lodash";
import Fuse from "fuse.js";
const initialState = {
    untouchedlist: null,
    usablelist: null,
};
const storesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_STORES":
            return { untouchedlist: action.payload, usablelist: action.payload };
        case "SORT_FILTER_STORES":
            let _items = state.usablelist;
            if (!_.isEmpty(action.payload.filters)) {
                //filtering items
                let _fields = _.keys(action.payload.filters);
                if (_fields && _fields.length >= 1) {
                    const fuseOptions = {
                        keys: _fields,
                        useExtendedSearch: true,
                    };
                    let _combinekeysvalues = _.map(_fields, (item, i) => {
                        let _hashedObj;
                        switch (action.payload.filters[item].matchMode) {
                            case "equals":
                                _hashedObj = {
                                    [item]: `'${action.payload.filters[item].value}`,
                                };
                                break;
                            case "startsWith":
                                _hashedObj = {
                                    [item]: `^${action.payload.filters[item].value}`,
                                };
                                break;
                            case "in":
                                let _arryfilter = _.map(action.payload.filters[item].value, (item, i) => {
                                    return `="${item}"`;
                                });
                                _arryfilter = _.join(_arryfilter, " | ");
                                _hashedObj = {
                                    [item]: _arryfilter,
                                };
                                break;
                            default:
                                break;
                        }
                        return _hashedObj;
                    });
                    // console.log("keys", _combinekeysvalues);
                    const fuse = new Fuse(state.untouchedlist, fuseOptions);
                    const results = fuse.search({ $and: _combinekeysvalues });
                    _items = _.map(results, (result, i) => {
                        return result.item;
                    });
                    if (!_.isEmpty(action.payload.sortField)) {
                        //sorting items
                        _items = _.orderBy(_items, [action.payload.sortField], [action.payload.sortDirection]);
                    }
                }
            } else if (!_.isEmpty(action.payload.sortField)) {
                //sorting items
                _items = _.orderBy(state.usablelist, [action.payload.sortField], [action.payload.sortDirection]);
            } else {
                _items = state.untouchedlist;
            }
            // console.log(_items);
            return { ...state, usablelist: _items };
        default:
            return state;
    }
};
export default storesReducer;
