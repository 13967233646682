import _ from "lodash";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Breadcrumbs, Chip, Divider, Grid, Snackbar, Tooltip, Typography } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import StoreDetailTabs from "./StoreDetailTabs";
import { red } from "@material-ui/core/colors";
const RedBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[600]),
        backgroundColor: red[600],
        "&:hover": {
            backgroundColor: red[700],
            color: theme.palette.getContrastText(red[700]),
        },
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);
const StyledLink = styled(Link)({
    color: "var(--text-color)",
    textDecoration: "none",
});
const StoreDetailLayout = () => {
    let { id } = useParams();
    const currentAdmin = useSelector((state) => state.user.untouched);
    const { oktaAuth, authState } = useOktaAuth();
    const stores = useSelector((state) => state.stores.untouchedlist);
    const [currentStore, setCurrentStore] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const [showAdminBtns, setShowAdminBtns] = useState(false);
    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    useEffect(() => {
        if (!currentStore && id) {
            let _store = _.find(stores, { FranchiseID: id });
            setCurrentStore(_store);
        }
    }, [id, stores]);
    return (
        <>
            {currentStore ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <StyledLink to='/'>Home</StyledLink>
                            <StyledLink to='/stores'>Stores</StyledLink>
                            <Typography color='textPrimary'>{currentStore.StoreNumber}</Typography>
                        </Breadcrumbs>
                        <Box py={1}>
                            <Divider />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex'>
                            <Box mr={1}>
                                {!currentStore.Active ? (
                                    <HtmlTooltip
                                        title={
                                            <>
                                                <Typography color='inherit'>Inactive FRM Record:</Typography>
                                                <div>
                                                    Active/Inactive is the status of the record in FRM. Inactives are the archived versions of stores prior to transfers/renewals. There is only ever 1
                                                    active record for a store, but it can have unlimited inactive ones.
                                                </div>
                                            </>
                                        }>
                                        <RedBox>
                                            <Typography component='span' gutterBottom>
                                                Inactive FRM Record
                                            </Typography>
                                        </RedBox>
                                    </HtmlTooltip>
                                ) : (
                                    ""
                                )}
                            </Box>
                            <Box mr={1}>
                                <Chip label={currentStore.FranchiseStatus} />
                            </Box>
                            <Box flexGrow={1}>
                                <Typography variant='h5' component='h1' gutterBottom>
                                    {currentStore.Brand} - {currentStore.StoreNumber}
                                </Typography>
                            </Box>
                            <Box p={1}></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={2}>
                            <StoreDetailTabs store={currentStore} admin={currentAdmin} showAdminBtns={showAdminBtns} />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                ""
            )}
            <Snackbar open={toastOpen} autoHideDuration={2000} onClose={handleToastClose}>
                <>{toastContent}</>
            </Snackbar>
        </>
    );
};

export default StoreDetailLayout;
