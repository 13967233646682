import { faEnvelope, faToggleOff, faToggleOn, faUserEdit, faUserLock } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form } from "formik";
import { Box, Button, Divider, Grid, Link, Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import _ from "lodash";
import * as Yup from "yup";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import UserDetailMenuBar from "../../User/UserDetailMenuBar";
import MuiAlert from "@material-ui/lab/Alert";
import { amber, blue, green, red, grey } from "@material-ui/core/colors";
import AdminUserEditorForm from "../../Common/AdminUserEditorForm";
import ControlledSubmitBtn from "../../Common/ControlledSubmitBtn";
import { getAdmins } from "../../../actions";

const GreyBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[200]),
        backgroundColor: grey[200],
        "&:hover": {
            backgroundColor: grey[700],
            color: theme.palette.getContrastText(grey[700]),
        },
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const GreenBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Box);
const RedBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Box);
const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        borderRadius: "5px",
    },
}))(Box);
const AmberBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(amber[500]),
        backgroundColor: amber[500],
        "&:hover": {
            backgroundColor: amber[700],
        },
    },
}))(Box);

const AdminDetailEditPanel = (data) => {
    const { user, admin, setEditPanel, getuserbyid } = data;
    const [editMenu, setEditMenu] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const formSchema = Yup.object().shape({
        matrixAdminRole: Yup.string().required("Required"),
        matrixAdminLocation: Yup.string().required("Required"),
        matrixAdminAssocBrands: Yup.array(),
    });
    const [initFormValues, setInitFormValues] = useState({
        matrixAdminAssocBrands: "",
        matrixAdminLocation: "",
        matrixAdmin: "",
        matrixAdminRole: "",
        matrixAdminApps: "",
        brandGroupSelection: [],
    });
    const [showFormHelp, setShowFormHelp] = useState(false);
    const [checkedUsername, setCheckedUsername] = useState("");
    const [usernameValidMsg, setUsernameValidMsg] = useState(null);
    const [validUsername, setValidusername] = useState();
    const [adminUser, setAdminUser] = useState();
    const [formActive, setFormActive] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");

    const handleSubmit = async (values) => {
        let _assocbrands = values.matrixAdminAssocBrands;
        if (!_.isEmpty(values.brandGroupSelection)) {
            _assocbrands = [values.brandGroupSelection];
        }
        let submitdata = {
            id: user ? user.id : adminUser.id,
            profile: {
                matrixAdminAssocBrands: _assocbrands,
                matrixAdminLocation: values.matrixAdminLocation,
                matrixAdmin: true,
                matrixAdminRole: values.matrixAdminRole,
                // matrixAdminApps: values.matrixAdminApps,
            },
        };
        console.log(submitdata);
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserprofile`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );

                    if (user) {
                        setTimeout(() => {
                            setEditPanel(false);
                            getuserbyid(user.id);
                        }, 4500);
                    } else {
                        setTimeout(() => {
                            dispatch(getAdmins(token));
                            history.push("/super/admins");
                        }, 4500);
                    }
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const toggleHelp = () => setShowFormHelp(!showFormHelp);
    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    const validateUsername = async (username) => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/verifyadminusername`,
                {
                    username,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    if (res.data.acct.profile.matrixAdmin) {
                        setCheckedUsername("");
                        setValidusername("invalid");
                        setUsernameValidMsg(
                            <RedBox p={1} style={{ borderRadius: "5px" }}>
                                <>User is already an admin. Please add a new admin.</>
                            </RedBox>
                        );
                    } else {
                        setUsernameValidMsg(
                            <GreenBox p={1} style={{ borderRadius: "5px" }}>
                                {res.data.message}
                            </GreenBox>
                        );
                        setAdminUser(res.data.acct);
                        setCheckedUsername(username);
                        setValidusername("valid");
                        setTimeout(() => {
                            setUsernameValidMsg(null);
                        }, 3000);
                    }
                } else {
                    setCheckedUsername("");
                    setValidusername("invalid");
                    setUsernameValidMsg(
                        <RedBox p={1} style={{ borderRadius: "5px" }}>
                            {res.data.message}
                        </RedBox>
                    );
                }
            })
            .catch(function (error) {});
    };
    useEffect(() => {
        if (user) {
            let _item = user.profile;
            document.title = `Admin: Editing ${_item.displayName}`;
            let initValues = { ...initFormValues, ..._item };
            if (_.indexOf(_item.matrixAdminAssocBrands, "all") !== -1) {
                initValues.brandGroupSelection = ["all"];
            } else if (_.indexOf(_item.matrixAdminAssocBrands, "specialty") !== -1) {
                initValues.brandGroupSelection = ["specialty"];
            } else if (_.indexOf(_item.matrixAdminAssocBrands, "restaurants") !== -1) {
                initValues.brandGroupSelection = ["restaurants"];
            }
            setInitFormValues(initValues);
            setFormActive(true);
        } else {
            document.title = `Admin: Adding Admin`;
            setFormActive(true);
        }
    }, [user]);
    return (
        <>
            {formActive ? (
                <Formik initialValues={initFormValues} onSubmit={handleSubmit} validationSchema={formSchema} enableReinitialize>
                    {(props) => {
                        let _menu = (
                            <Box display='flex'>
                                <Box>
                                    <ControlledSubmitBtn
                                        label={user ? "Save Changes" : "Create"}
                                        roles={["super"]}
                                        currentAdmin={admin}
                                        disabled={!props.dirty || props.isSubmitting || !props.isValid}
                                    />
                                </Box>
                                <Box p={1}>
                                    <small style={{ color: "red" }}>
                                        <sup>*</sup>
                                    </small>
                                    Required
                                </Box>

                                <Box mx={1} flexGrow={1}>
                                    <Divider orientation='vertical' />
                                </Box>
                                <Box>
                                    {showFormHelp ? (
                                        <BlueButton variant='contained' size='small' onClick={toggleHelp}>
                                            <FontAwesomeIcon icon={faToggleOn} />
                                            &nbsp;Help
                                        </BlueButton>
                                    ) : (
                                        <GreyButton variant='contained' size='small' onClick={toggleHelp}>
                                            <FontAwesomeIcon icon={faToggleOff} />
                                            &nbsp;Help
                                        </GreyButton>
                                    )}
                                </Box>
                                <Box mx={1}>
                                    {setEditPanel ? (
                                        <Button color='inherit' onClick={() => setEditPanel(false)}>
                                            <FontAwesomeIcon icon={faUserEdit} />
                                            &nbsp;Close
                                        </Button>
                                    ) : (
                                        <Button color='inherit' onClick={() => history.push(`/super/admins`)}>
                                            &nbsp;Cancel
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        );
                        return (
                            <Form>
                                <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                                    <>{toastContent}</>
                                </Snackbar>
                                <UserDetailMenuBar page='editdetails' menu={_menu} />
                                <AdminUserEditorForm
                                    props={props}
                                    user={user}
                                    showFormHelp={showFormHelp}
                                    currentAdmin={admin}
                                    checkedUsername={checkedUsername}
                                    validateUsername={validateUsername}
                                    validUsername={validUsername}
                                    setValidusername={setValidusername}
                                    usernameValidMsg={usernameValidMsg}
                                    adminUser={adminUser}
                                />
                                <UserDetailMenuBar page='editdetails' menu={_menu} />
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                ""
            )}
        </>
    );
};

export default AdminDetailEditPanel;
