import { Chip, Typography, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Tag } from "primereact/tag";
import green from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";
import { blue, blueGrey, grey, orange, yellow } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faSquare } from "@fortawesome/pro-duotone-svg-icons";
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const UserStatusIcons = (data) => {
    const { status, hidelabel } = data;
    let _badge;
    if (hidelabel) {
        switch (status) {
            case "STAGED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Staged:</Typography>
                                <div>
                                    This mean that the account has been created, but the activation email has not been sent out. Typically this status only appears when IT is generating accounts.
                                </div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: grey["800"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            case "PROVISIONED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Provisioned:</Typography>
                                <div>A new user account, that has had the activation email sent out. The account owner however has not gone through the process of activating the account.</div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: blueGrey["800"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            case "ACTIVE":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Active:</Typography>
                                <div>Account is active and in use.</div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: green["900"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            case "PASSWORD_EXPIRED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Password Expired:</Typography>
                                <div>Account is active but the password has expired. The account owner, or an admin, will need to issue a password reset to activate the account again.</div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: orange["400"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            case "LOCKED_OUT":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Locked Out:</Typography>
                                <div>Account is locked out. This happens after an user puts in the account credentials wrong 10 times. After 20 minutes the account will unlock automatically.</div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: orange["400"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            case "RECOVERY":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Password/Credentials Reset:</Typography>
                                <div>Account is active and the account has requested a password reset.</div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: blue["600"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            case "SUSPENDED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Suspended:</Typography>
                                <div>Account is suspended. All current permissions &amp; app access are being retained, but the account may be deactivated after a period of time.</div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: yellow["700"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            case "DEPROVISIONED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Deactivated:</Typography>
                                <div>Account is inactive and will be removed from the system shortly.</div>
                            </>
                        }>
                        <span>
                            <FontAwesomeIcon icon={faSquare} style={{ color: red["800"], marginRight: "5px" }} />
                        </span>
                    </HtmlTooltip>
                );
                break;
            default:
                _badge = <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: red["800"], marginRight: "5px" }} title='Not Set' />;
                break;
        }
    } else {
        switch (status) {
            case "STAGED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Staged:</Typography>
                                <div>
                                    This mean that the account has been created, but the activation email has not been sent out. Typically this status only appears when IT is generating accounts.
                                </div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: grey["800"], color: "var(--primary-color-highlight-text)" }} label='Staged' /> */}
                        <Tag style={{ backgroundColor: grey["800"], color: "var(--primary-color-highlight-text)" }} value='Staged' />
                    </HtmlTooltip>
                );
                break;
            case "PROVISIONED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Provisioned:</Typography>
                                <div>A new user account, that has had the activation email sent out. The account owner however has not gone through the process of activating the account.</div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: blueGrey["500"], color: "var(--primary-color-highlight-text)" }} label='Provisioned' /> */}
                        <Tag style={{ backgroundColor: blueGrey["500"], color: "var(--primary-color-highlight-text)" }} value='Provisioned' />
                    </HtmlTooltip>
                );
                break;
            case "ACTIVE":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Active:</Typography>
                                <div>Account is active and in use.</div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: green["900"], color: "var(--primary-color-highlight-text)" }} label='Active' /> */}
                        <Tag style={{ backgroundColor: green["900"], color: "var(--primary-color-highlight-text)" }} value='Active' />
                    </HtmlTooltip>
                );
                break;
            case "PASSWORD_EXPIRED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Password Expired:</Typography>
                                <div>Account is active but the password has expired. The account owner, or an admin, will need to issue a password reset to activate the account again.</div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: orange["400"], color: "var(--primary-color-highlight-text)" }} label='Password Expired' /> */}
                        <Tag style={{ backgroundColor: orange["400"], color: "var(--primary-color-highlight-text)" }} value='Password Expired' />
                    </HtmlTooltip>
                );
                break;
            case "LOCKED_OUT":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Locked Out:</Typography>
                                <div>Account is locked out. This happens after an user puts in the account credentials wrong 10 times. After 20 minutes the account will unlock automatically.</div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: orange["400"], color: "var(--primary-color-highlight-text)" }} label='Locked Out' /> */}
                        <Tag style={{ backgroundColor: orange["400"], color: "var(--primary-color-highlight-text)" }} value='Locked Out' />
                    </HtmlTooltip>
                );
                break;
            case "RECOVERY":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Password/Credentials Reset:</Typography>
                                <div>Account is active and the account has requested a password reset.</div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: blue["600"], color: "var(--primary-color-highlight-text)" }} label='Password Reset' /> */}
                        <Tag style={{ backgroundColor: blue["600"], color: "var(--primary-color-highlight-text)" }} value='Password Reset' />
                    </HtmlTooltip>
                );
                break;
            case "SUSPENDED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Suspended:</Typography>
                                <div>Account is suspended. All current permissions &amp; app access are being retained, but the account may be deactivated after a period of time.</div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: yellow["700"], color: "var(--primary-color-highlight-text)" }} label='Suspended' /> */}
                        <Tag style={{ backgroundColor: yellow["700"], color: "var(--primary-color-highlight-text)" }} value='Suspended' />
                    </HtmlTooltip>
                );
                break;
            case "DEPROVISIONED":
                _badge = (
                    <HtmlTooltip
                        title={
                            <>
                                <Typography color='inherit'>Deactivated:</Typography>
                                <div>Account is inactive and will be removed from the system shortly.</div>
                            </>
                        }>
                        {/* <Chip style={{ backgroundColor: red["800"], color: "var(--primary-color-highlight-text)" }} label='Deactivated' /> */}
                        <Tag style={{ backgroundColor: red["800"], color: "var(--primary-color-highlight-text)" }} value='Deactivated' />
                    </HtmlTooltip>
                );
                break;
            default:
                _badge = "Not Set";
                break;
        }
    }

    return _badge;
};

export default UserStatusIcons;
