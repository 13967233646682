import { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import DataTable from "../Common/DataTable";
import BrandIcons from "../Common/BrandIcons";
import { Dropdown } from "primereact/dropdown";
import { FormControl, Tooltip } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import LocationIcons from "../Common/LocationIcons";
import RoleIcons from "../Common/RoleIcons";
import { format } from "date-fns";
import { blue, grey } from "@material-ui/core/colors";
import { Tag } from "primereact/tag";
import { withStyles } from "@material-ui/core";
import DataLoading from "../Common/DataLoading";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);
const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
});
const StoresSalesTableData = (data) => {
    const { pagetitle, localstoragekey, listitems, showSelectCheckbox, clickable, setSelectedItems, selectedItems, canExport, filterData } = data;
    // Cell Data

    // Cell Templates

    const currencyCellTemplate = ({ row, value }) => {
        return <div style={{ textAlign: "center" }}>{formatter.format(value)}</div>;
    };
    const dateTemplate = ({ row, value }) => {
        return <div style={{ textAlign: "center" }}>{format(new Date(value), "MMM do, yyyy")}</div>;
    };
    // Filters

    const columns = useMemo(
        () => [
            {
                Header: "WeekEndingDate",
                accessor: "WeekEndingDate",
                id: "WeekEndingDate",
                Cell: dateTemplate,
                disableFilters: true,
            },
            {
                Header: "TotalNetSales",
                accessor: "TotalNetSales",
                id: "TotalNetSales",
                Cell: currencyCellTemplate,
                disableFilters: true,
            },
            {
                Header: "TransactionCount",
                accessor: "TransactionCount",
                id: "TransactionCount",
                disableFilters: true,
            },
            {
                Header: "CateringSales",
                accessor: "CateringSales",
                id: "CateringSales",
                Cell: currencyCellTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "DiscountSales",
                accessor: "DiscountSales",
                id: "DiscountSales",
                Cell: currencyCellTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "CateringTransactionCount",
                accessor: "CateringTransactionCount",
                id: "CateringTransactionCount",
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "OnlineNetSales",
                accessor: "OnlineNetSales",
                id: "OnlineNetSales",
                Cell: currencyCellTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "Voids",
                accessor: "Voids",
                id: "Voids",
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "MobileDriveThruSales",
                accessor: "MobileDriveThruSales",
                id: "MobileDriveThruSales",
                Cell: currencyCellTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "ToGoSales",
                accessor: "ToGoSales",
                id: "ToGoSales",
                Cell: currencyCellTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "CreatedBy",
                accessor: "CreatedBy",
                id: "CreatedBy",
                filter: "fuzzyText",
            },
            {
                Header: "CreatedDate",
                accessor: "CreatedDate",
                id: "CreatedDate",
                Cell: dateTemplate,
            },
            {
                Header: "ModifiedBy",
                accessor: "ModifiedBy",
                id: "ModifiedBy",
                filter: "fuzzyText",
                hidden: true,
            },
            {
                Header: "ModifiedDate",
                accessor: "ModifiedDate",
                id: "ModifiedDate",
                Cell: dateTemplate,
                hidden: true,
            },
        ],
        []
    );
    useEffect(() => {
        console.log("listitems", listitems);
    }, [listitems]);
    return listitems ? (
        <DataTable
            pagetitle={pagetitle}
            localstoragekey={localstoragekey}
            listitems={listitems}
            columns={columns}
            clickable={clickable}
            selectAllEnabled={false}
            showSelectCheckbox={showSelectCheckbox}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            excelFileName={"matrixstoresales"}
            canExport={canExport}
            idfield={"id"}
            pageslug={"stores"}
            filterData={filterData || []}
        />
    ) : (
        <DataLoading />
    );
};

export default StoresSalesTableData;
