import _ from "lodash";
import Fuse from "fuse.js";
const initialState = {
    untouchedlist: null,
    usablelist: null,
};
const brandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BRANDS":
            return { untouchedlist: action.payload, usablelist: action.payload };
        default:
            return state;
    }
};
export default brandsReducer;
