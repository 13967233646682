import { faSlidersH, faTasks } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

const InfoControlsPanel = ({ daysCount, setDaysCount, locationView, setLocationView }) => {
    const brands = useSelector((state) => state.brands.untouchedlist);
    const [brandLegend, setBrandLegend] = useState([]);
    let _locationoptions = [
        { name: "Domestic Brands", value: "domestic" },
        { name: "Intl Brands", value: "international" },
    ];
    let _daysoptions = [
        { name: "Few Days", value: 3 },
        { name: "Week", value: 7 },
        { name: "Month", value: 30 },
        { name: "6 Months", value: 180 },
        { name: "Year", value: 365 },
    ];
    useEffect(() => {
        if (!_.isEmpty(brands)) {
            let _brandlegend = _.compact(
                _.map(brands, (brand, i) => {
                    if (brand.location === locationView && brand.active) {
                        return brand;
                    }
                })
            );
            setBrandLegend(_brandlegend);
        }
    }, [locationView, brands]);
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <FontAwesomeIcon icon={faSlidersH} fixedWidth size='2x' />
                &nbsp;
                <span style={{ fontSize: "1.4rem", textTransform: "uppercase" }}>Viewing</span>
            </Grid>
            <Grid item xs={6}>
                <Box component='span'>
                    {_.map(brandLegend, (item, i) => {
                        return <Chip key={i} style={{ background: item.hexcolor, color: "var(--primary-color-highlight-text)", marginRight: "2px", marginBottom: "2px" }} label={item.name} />;
                    })}
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box component='span' mx={1}>
                    Viewing: {""}
                    <Dropdown optionLabel='name' value={locationView} options={_locationoptions} onChange={(e) => setLocationView(e.value)} placeholder='Select a location' />
                </Box>
                <Box component='span' mx={1}>
                    For the Past <Dropdown optionLabel='name' value={daysCount} options={_daysoptions} onChange={(e) => setDaysCount(e.value)} placeholder='Select an option' />
                </Box>
            </Grid>
        </Grid>
    );
};

export default InfoControlsPanel;
