import { Box, Paper, Typography, withStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import _ from "lodash";
import { useEffect, useState } from "react";
import random from "../Common/randomfacts.json";
const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const DataLoading = () => {
    const [message, setMessage] = useState("");
    useEffect(() => {
        setMessage(_.sample(random.facts));
    }, []);
    return (
        <Paper elevation={3}>
            <BlueBox p={3}>
                <Typography component='h1' variant='h4'>
                    Loading...
                </Typography>
                <Box p={1}>
                    <Typography component='h2' variant='h5'>
                        {message}
                    </Typography>
                </Box>
            </BlueBox>
        </Paper>
    );
};

export default DataLoading;
