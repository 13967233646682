import { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faExclamationTriangle, faSquare } from "@fortawesome/pro-duotone-svg-icons";
const BrandIcons = (data) => {
    const { slug, location, label } = data;
    const brands = useSelector((state) => state.brands.untouchedlist);
    const [display, setdisplay] = useState("");
    useEffect(() => {
        if (slug && brands) {
            switch (slug) {
                case "specialty":
                case "restaurants":
                    if (location) {
                        let _brands = _.filter(brands, { type: slug, location });
                        setdisplay(
                            _brands ? (
                                _.map(_brands, (brand, i) => {
                                    return (
                                        <span key={i}>
                                            <span className='fa-lg'>
                                                <span className='fa-layers fa-fw'>
                                                    <FontAwesomeIcon icon={faCircle} color='white' mask={["fad", brand.icon]} />
                                                    <FontAwesomeIcon title={brand.name} style={{ color: brand.hexcolor }} icon={["fad", brand.icon]} />
                                                </span>
                                            </span>
                                            {label ? <>&nbsp;{brand.name}</> : ""}
                                        </span>
                                    );
                                })
                            ) : (
                                <FontAwesomeIcon icon={faExclamationTriangle} title='There was an error. Check Assigned Brands.' />
                            )
                        );
                    } else {
                        let _brands = _.filter(brands, { type: slug });
                        setdisplay(
                            _brands ? (
                                _.map(_brands, (brand, i) => {
                                    return (
                                        <span key={i}>
                                            <span className='fa-lg' key={i}>
                                                <span className='fa-layers fa-fw'>
                                                    <FontAwesomeIcon icon={faCircle} color='white' mask={["fad", brand.icon]} />
                                                    <FontAwesomeIcon title={brand.name} style={{ color: brand.hexcolor }} icon={["fad", brand.icon]} />
                                                </span>
                                            </span>
                                            {label ? <>&nbsp;{brand.name}</> : ""}
                                        </span>
                                    );
                                })
                            ) : (
                                <FontAwesomeIcon icon={faExclamationTriangle} title='There was an error. Check Assigned Brands.' />
                            )
                        );
                    }
                    break;
                case "all":
                    if (location && location === "all") {
                        let _brand = _.find(brands, { slug, location: "all" });
                        setdisplay(
                            _brand ? (
                                <div>
                                    <span className='fa-lg'>
                                        <span className='fa-layers fa-fw'>
                                            <FontAwesomeIcon icon={faCircle} color='white' mask={["fad", "circle-notch"]} />
                                            <FontAwesomeIcon title={_brand.name} style={{ color: _brand.hexcolor }} icon={["fad", "circle-notch"]} />
                                        </span>
                                    </span>
                                    {label ? <>&nbsp;{_brand.name}</> : ""}
                                </div>
                            ) : (
                                <FontAwesomeIcon icon={faExclamationTriangle} title='There was an error. Check Assigned Brands.' />
                            )
                        );
                    } else if (location && location !== "all") {
                        let _brand = _.find(brands, { slug, location });
                        setdisplay(
                            _brand ? (
                                <div>
                                    <span className='fa-lg'>
                                        <span className='fa-layers fa-fw'>
                                            <FontAwesomeIcon icon={faCircle} color='white' mask={["fad", "circle-notch"]} />
                                            <FontAwesomeIcon title={_brand.name} style={{ color: _brand.hexcolor }} icon={["fad", "circle-notch"]} />
                                        </span>
                                    </span>
                                    {label ? <>&nbsp;{_brand.name}</> : ""}
                                </div>
                            ) : (
                                <FontAwesomeIcon icon={faExclamationTriangle} title='There was an error. Check Assigned Brands.' />
                            )
                        );
                    } else {
                        setdisplay(<FontAwesomeIcon icon={faExclamationTriangle} title='There was an error. Check Assigned Brands.' />);
                    }
                    break;
                default:
                    if (location) {
                        let _brand = _.find(brands, { slug, location });
                        setdisplay(
                            _brand ? (
                                <>
                                    <span className='fa-lg'>
                                        <span className='fa-layers fa-fw'>
                                            <FontAwesomeIcon icon={faCircle} color='white' mask={["fad", _brand.icon]} />
                                            <FontAwesomeIcon title={_brand.name} style={{ color: _brand.hexcolor }} icon={["fad", _brand.icon]} />
                                        </span>
                                    </span>
                                    {label ? <>&nbsp;{_brand.name}</> : ""}
                                </>
                            ) : (
                                <FontAwesomeIcon icon={faExclamationTriangle} title='There was an error. Check Assigned Brands.' />
                            )
                        );
                    } else {
                        let _brand = _.find(brands, { frm_name: slug });
                        setdisplay(
                            _brand ? (
                                <>
                                    <span className='fa-lg'>
                                        <span className='fa-layers fa-fw'>
                                            <FontAwesomeIcon icon={faCircle} color='white' mask={["fad", _brand.icon]} />
                                            <FontAwesomeIcon title={_brand.name} style={{ color: _brand.hexcolor }} icon={["fad", _brand.icon]} />
                                        </span>
                                    </span>
                                    {label ? <>&nbsp;{_brand.name}</> : ""}
                                </>
                            ) : (
                                <FontAwesomeIcon icon={faExclamationTriangle} title='There was an error. Check Assigned Brands.' />
                            )
                        );
                    }

                    break;
            }
        }
    }, [slug, location, brands]);
    return display;
};

export default BrandIcons;
