import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

import colTemplate from "./columnTemplates";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import { MultiSelect } from "primereact/multiselect";
import BrandIcons from "./BrandIcons";
import ExportToExcel from "./ExportToExcel";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const SimpleList = ({
    list,
    pagetitle,
    columns,
    columnselect,
    footer,
    dtfilter,
    filterSortFunct,
    selectedRow,
    setSelectedRow,
    selectionRow,
    resetList,
    resetDefaultView,
    saveView,
    canExport,
    excelFileName,
}) => {
    const dt = useRef(null);
    const cm = useRef(null);
    const brands = useSelector((state) => state.brands.untouchedlist);
    const roles = useSelector((state) => state.roles.untouchedlist);
    const [records, setRecords] = useState([]);
    const [datalist, setDatalist] = useState([]);
    const [tableColumns, setTableColumns] = useState(columns);
    const [brandOptions, setBrandOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState(["Active", "Inactive"]);
    const [manualReportingOptions, setManualReportingOptions] = useState(["Active", "Inactive"]);
    const [reqStatusOptions, setReqStatusOptions] = useState(["Pending", "Approved", "Cancelled"]);
    const [userStatusOptions, setUserStatusOptions] = useState(["STAGED", "PROVISIONED", "ACTIVE", "PASSWORD_EXPIRED", "LOCKED_OUT", "RECOVERY", "SUSPENDED"]);
    const [storeStatusOptions, setStoreStatusOptions] = useState([
        "Contract Signed - Site Approved",
        "Contract Signed - TA",
        "Open",
        "Open - Seasonal",
        "Open - Contract Expired",
        "Open - Temporarily Closed",
        "Open - No Contract",
        "Transferred/Renewed",
        "Terminated - Closed",
        "Closed - Termination Pending",
        "Terminated - Pre-Opening",
        "Terminated - Contract Expired",
        "Terminated - Contract Replaced",
        "Application Denied/Dead",
    ]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedReqStatus, setSelectedReqStatus] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedLoc, setSelectedLoc] = useState(null);
    const [selectedStoreStatus, setSelectedStoreStatus] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [tableExportData, setTableExportData] = useState([]);

    // lazy loading test
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 25,
        page: 0,
        filters: dtfilter,
    });
    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    };
    const onSortFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        // console.log("filtering", _lazyParams);
        if ((!_.isEmpty(_lazyParams.filters) || !_.isEmpty(event.filters)) && (_.isEmpty(_lazyParams.sortField) || _.isEmpty(event.sortField))) {
            filterSortFunct({ filters: _lazyParams.filters });
            _lazyParams["first"] = 0;
        } else if ((!_.isEmpty(_lazyParams.filters) || !_.isEmpty(event.filters)) && !_.isEmpty(_lazyParams.sortField) && !_.isEmpty(event.sortField)) {
            filterSortFunct({ filters: _lazyParams.filters, sortDirection: _lazyParams.sortOrder === 1 ? "asc" : "desc", sortField: _lazyParams.sortField });
            _lazyParams["first"] = 0;
        } else if ((_.isEmpty(_lazyParams.filters) || _.isEmpty(event.filters)) && (!_.isEmpty(_lazyParams.sortField) || !_.isEmpty(event.sortField))) {
            filterSortFunct({ sortDirection: _lazyParams.sortOrder === 1 ? "asc" : "desc", sortField: _lazyParams.sortField });
        } else if ((_.isEmpty(_lazyParams.filters) || _.isEmpty(event.filters)) && (_.isEmpty(_lazyParams.sortField) || _.isEmpty(event.sortField))) {
            resetList();
        }
        setLazyParams(_lazyParams);
    };
    const loadLazyData = () => {
        setLoading(true);
        let _chunkedlist = _.chunk(records, lazyParams.rows);
        setDatalist(_chunkedlist[lazyParams.page]);
        setTotalRecords(list.length);
        setLoading(false);
    };
    const resetListFilter = () => {
        resetList();
        let _lazyParams = { ...lazyParams, filters: dtfilter };
        setLazyParams(_lazyParams);
    };
    const onRoleSelectChange = (e) => {
        let _role = _.find(roleOptions, ["name", e.value]);
        if (e.value) {
            dt.current.filter(_role.slug, "userRole", "equals");
        } else {
            dt.current.filter(null, "userRole", "equals");
        }

        setSelectedRole(e.value);
    };
    const onLocSelectChange = (e, field) => {
        console.log(e);
        if (e.value) {
            dt.current.filter(e.value, "location", "equals");
        } else {
            dt.current.filter(null, "location", "equals");
        }
        setSelectedLoc(e.value);
    };
    const onStatusSelectChange = (e) => {
        if (e.value === "Active") {
            dt.current.filter("1", "active", "equals");
        } else if (e.value === "Inactive") {
            dt.current.filter("0", "active", "equals");
        } else {
            dt.current.filter("1", "active", "equals");
        }
    };
    const onManualReportingSelectChange = (e) => {
        if (e.value === "Active") {
            dt.current.filter("1", "ManualReporting", "equals");
        } else if (e.value === "Inactive") {
            dt.current.filter("0", "ManualReporting", "equals");
        } else {
            dt.current.filter(null, "ManualReporting", "equals");
        }
    };
    const onUserStatusSelectChange = (e) => {
        if (e.value) {
            dt.current.filter(e.value, "status", "equals");
        } else {
            dt.current.filter(null, "status", "equals");
        }
    };
    const onFRMStatusSelectChange = (e) => {
        if (e.value === "Active") {
            dt.current.filter("1", "Active", "equals");
        } else if (e.value === "Inactive") {
            dt.current.filter("0", "Active", "equals");
        } else {
            dt.current.filter("1", "Active", "equals");
        }
    };
    const onReqStatusSelectChange = (e) => {
        if (e.value) {
            dt.current.filter(e.value, "status", "equals");
        } else {
            dt.current.filter(null, "status", "equals");
        }
    };
    const onStoreStatusSelectChange = (e) => {
        if (e.value) {
            dt.current.filter(e.value, "FranchiseStatus", "equals");
        } else {
            dt.current.filter(null, "FranchiseStatus", "equals");
        }
    };
    const onBrandSelectChange = (e) => {
        let _brand = _.find(brandOptions, ["name", e.value]);

        if (e.value && _brand) {
            dt.current.filter(_brand.slug, "assocBrands", "contains");
        } else {
            dt.current.filter(null, "assocBrands", "equals");
        }
    };
    const onBrandMultiSelectChange = (e) => {
        if (e.value) {
            dt.current.filter(e.value, "Brand", "in");
        } else {
            dt.current.filter(null, "Brand", "in");
        }
        setSelectedBrands(e.value);
    };
    const getFilterElement = (filter, field) => {
        let _filterElm;
        switch (filter) {
            case "status":
                _filterElm = (
                    <Dropdown
                        value={lazyParams.filters.active.value === "1" ? "Active" : lazyParams.filters.active.value === "0" ? "Inactive" : null}
                        options={statusOptions}
                        onChange={onStatusSelectChange}
                        placeholder='Select a Status'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "userstatus":
                _filterElm = (
                    <Dropdown
                        value={lazyParams.filters.status ? lazyParams.filters.status.value : null}
                        options={userStatusOptions}
                        onChange={onUserStatusSelectChange}
                        placeholder='Select a Status'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "storestatus":
                _filterElm = (
                    <Dropdown value={selectedStoreStatus} options={storeStatusOptions} onChange={onStoreStatusSelectChange} placeholder='Select a Status' className='p-column-filter' showClear />
                );
                break;
            case "manualreporting":
                _filterElm = (
                    <Dropdown
                        value={
                            lazyParams.filters.ManualReporting && lazyParams.filters.ManualReporting.value === "1"
                                ? "Active"
                                : lazyParams.filters.ManualReporting && lazyParams.filters.ManualReporting.value === "0"
                                ? "Inactive"
                                : null
                        }
                        options={manualReportingOptions}
                        onChange={onManualReportingSelectChange}
                        placeholder='Select an Option'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "frmstatus":
                _filterElm = (
                    <Dropdown
                        value={lazyParams.filters.Active.value === "1" ? "Active" : lazyParams.filters.Active.value === "0" ? "Inactive" : null}
                        options={statusOptions}
                        onChange={onFRMStatusSelectChange}
                        placeholder='Select a Status'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "reqstatus":
                _filterElm = (
                    <Dropdown
                        value={lazyParams.filters.status ? lazyParams.filters.status.value : null}
                        options={reqStatusOptions}
                        onChange={onReqStatusSelectChange}
                        placeholder='Select a Status'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "locations":
                _filterElm = (
                    <Dropdown
                        name={field}
                        value={selectedLoc}
                        options={["All", "Domestic", "International"]}
                        onChange={onLocSelectChange}
                        placeholder='Select a Location'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "roles":
                _filterElm = (
                    <Dropdown
                        value={selectedRole}
                        options={_.map(roleOptions, (role, i) => role.name)}
                        onChange={onRoleSelectChange}
                        placeholder='Select a Role'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "brands":
                _filterElm = (
                    <Dropdown
                        value={selectedBrand}
                        options={_.map(brandOptions, (brand, i) => brand.name)}
                        onChange={onBrandSelectChange}
                        placeholder='Select a Brand'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            case "frmbrands":
                _filterElm = (
                    <MultiSelect
                        value={selectedBrands}
                        options={_.map(brandOptions, (brand, i) => brand)}
                        itemTemplate={(option) => (
                            <>
                                <BrandIcons slug={option.frm_name} />
                                {option.name}
                            </>
                        )}
                        onChange={onBrandMultiSelectChange}
                        placeholder='Select a Brand'
                        optionLabel='name'
                        optionValue='name'
                        className='p-column-filter'
                        showClear
                    />
                );
                break;
            default:
                break;
        }

        return _filterElm;
    };
    const showSelectionOption = (row) => {
        if (row.hideSelectionElement) {
            return false;
        } else {
            return true;
        }
    };
    useEffect(() => {
        let _brandOpts = _.compact(
            _.map(brands, (brand, i) => {
                if (brand.active) {
                    return brand;
                }
            })
        );
        setBrandOptions(_brandOpts);
    }, [brands]);
    useEffect(() => {
        let _roleOpts = _.compact(
            _.map(roles, (role, i) => {
                if (role.active) {
                    return role;
                }
            })
        );
        setRoleOptions(_roleOpts);
    }, [roles]);
    useEffect(() => {
        setTableColumns(colTemplate({ columns }));
    }, [columns]);
    useEffect(() => {
        if (records && records.length >= 1) {
            loadLazyData();
        } else {
            setDatalist([]);
        }
    }, [lazyParams, records]);
    useEffect(() => {
        setRecords(list);
    }, [list]);
    useEffect(() => {
        if (!_.isEmpty(dtfilter)) {
            onSortFilter({ page: 0 });
        }
    }, [dtfilter]);
    useEffect(() => {
        if (columns && !_.isEmpty(records)) {
            let _tableData = _.map(tableColumns, (col, i) => {
                let _filterElement = getFilterElement(col.filter, col.field);
                return <Column key={i} field={col.field} header={col.header} sortable={col.sortable} filter={col.filter ? true : false} filterElement={_filterElement} body={col.body} />;
            });
            setTableData(_tableData);
            if (canExport) {
                let _exportdata = _.map(records, (item, i) => {
                    let _testobj = {};
                    _.each(columns, (col, n) => {
                        let _key = col.header;
                        let _val = _.get(item, col.field);
                        if (_.isArray(_val)) {
                            _val = _.join(_val, ",");
                        }
                        // let _dataobj = { [_key]: _val };
                        _.set(_testobj, _key, _val);
                    });
                    return _testobj;
                });
                setTableExportData(_exportdata);
            }
        }
    }, [tableColumns, records, canExport]);
    return (
        <>
            <DataTable
                id='datatable'
                value={datalist}
                emptyMessage={"No records found."}
                ref={dt}
                stripedRows
                scrollable
                scrollHeight='55vh'
                className='p-datatable-gridlines p-datatable-sm'
                // contextMenuSelection={selectedRow}
                // onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
                // onContextMenu={(e) => cm.current.show(e.originalEvent)}
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                header={
                    <>
                        <Box>
                            <Typography variant='h5' component='h1'>
                                {pagetitle}
                            </Typography>
                            {JSON.stringify(lazyParams)}
                        </Box>
                        <Box display='flex'>
                            <Box px={1}>
                                {!_.isEmpty(lazyParams.filters) ? (
                                    <HtmlTooltip
                                        title={
                                            <>
                                                <Typography color='inherit'>Reset Filters:</Typography>
                                                <div>This removes any filters that are currently applied to the list.</div>
                                            </>
                                        }>
                                        <Button size='small' variant='contained' color='primary' onClick={resetListFilter}>
                                            Reset Filters
                                        </Button>
                                    </HtmlTooltip>
                                ) : (
                                    ""
                                )}
                            </Box>
                            <Box flexGrow={1}></Box>

                            <Box>{columnselect}</Box>
                            <Box>
                                <HtmlTooltip
                                    title={
                                        <>
                                            <Typography color='inherit'>Save View:</Typography>
                                            <div>Saves the current selection of columns as a view.</div>
                                        </>
                                    }>
                                    <Button size='small' variant='contained' color='primary' onClick={() => saveView(columns)}>
                                        Save View
                                    </Button>
                                </HtmlTooltip>
                            </Box>
                        </Box>
                    </>
                }
                footer={
                    <>
                        <Box display='flex'>
                            <Box px={1}>
                                <HtmlTooltip
                                    title={
                                        <>
                                            <Typography color='inherit'>Reset View:</Typography>
                                            <div>This sets the viewable columns back to the default view.</div>
                                        </>
                                    }>
                                    <Button size='small' variant='contained' color='primary' onClick={resetDefaultView}>
                                        Reset View
                                    </Button>
                                </HtmlTooltip>
                            </Box>
                            <Box flexGrow={1}></Box>
                            <Box>
                                {canExport ? (
                                    <ExportToExcel
                                        filename={excelFileName ? `${excelFileName}_${format(new Date(), "yyyy-MM-dd-HHmmss")}` : `${format(new Date(), "yyyy-MM-dd-HHmmss")}`}
                                        tabledata={tableExportData}
                                        elm={"datatable"}
                                    />
                                ) : (
                                    ""
                                )}
                            </Box>
                        </Box>
                    </>
                }
                paginator
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport'
                rows={25}
                sortMode='single'
                //lazyloading
                lazy
                first={lazyParams.first}
                totalRecords={totalRecords}
                onPage={onPage}
                onSort={onSortFilter}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onFilter={onSortFilter}
                filters={lazyParams.filters}
                loading={loading}
                rowHover
                selectionMode={selectionRow ? null : "single"}
                showSelectionElement={showSelectionOption}>
                {selectionRow ? <Column selectionMode='multiple' headerStyle={{ width: "3em" }} /> : null}
                {tableData}
            </DataTable>
        </>
    );
};

export default SimpleList;
