import { Box, Chip, Divider, Grid, Typography } from "@material-ui/core";
import { isAfter, isBefore, differenceInDays, subDays, format, isSameDay, formatISO9075 } from "date-fns";

import BrandIcons from "../Common/BrandIcons";

const StoreDetailPanel = (data) => {
    const { store, admin, showAdminBtns } = data;
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Store Number:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.StoreNumber}
                </Grid>
                <Grid item xs={2}>
                    <strong>Brand:</strong>
                </Grid>
                <Grid item xs={4}>
                    <BrandIcons slug={store.Brand} label={true} />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Store Address:</strong>
                </Grid>
                <Grid item xs={4}>
                    <address>
                        {store.Location}
                        <br />
                        {store.Address}
                        {store.Address2}
                        <br />
                        {store.City}, {store.State} {store.ZIP}
                        <br />
                        {store.Country}
                    </address>
                </Grid>
                <Grid item xs={2}>
                    <strong>Franchisee:</strong>
                </Grid>
                <Grid item xs={4}>
                    <span>{store.FranchiseBusinessName}</span>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Venue Type:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.Venue}
                </Grid>
                <Grid item xs={2}>
                    <strong>Store Status:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.FranchiseStatus}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>POS Type:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.POS ? store.POS : <Chip label='Not Set' />}
                </Grid>
                <Grid item xs={2}>
                    <strong>Company Owned:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.CompanyOwnedFlag ? "Yes" : "No"}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Currency:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.CurrencyCode}
                </Grid>
                <Grid item xs={2}>
                    <strong>Manual Sales Reporting:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.ManualReporting ? "Yes" : "No"}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Primary Contact:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.PrimaryContact ? store.PrimaryContact : <Chip label='Not Set' />}
                </Grid>
                <Grid item xs={2}>
                    <strong>Contact Email:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.PrimaryContactEmail ? <a href={`mailto:${store.PrimaryContactEmail}`}>{store.PrimaryContactEmail.toLowerCase()}</a> : <Chip label='Not Set' />}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Contact Phone:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.Phone ? <a href={`tel:${store.Phone}`}>{store.Phone}</a> : <Chip label='Not Set' />}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <Box p={1}>
                <Divider variant='middle' />
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Store Opened:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.StoreOpen ? format(new Date(store.StoreOpen), "PPP pp") : <Chip label='Not Set' />}
                </Grid>
                <Grid item xs={2}>
                    <strong>Date Added:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.DateAdded ? format(new Date(store.DateAdded), "PPP pp") : <Chip label='Not Set' />}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>Last Updated:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.DateLastUpdated ? format(new Date(store.DateLastUpdated), "PPP pp") : <Chip label='Not Set' />}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <Box p={1}>
                <Divider variant='middle' />
            </Box>
            <Box>
                <Typography component='h2' variant='h5'>
                    Focus Brands Related Info
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>FranchiseID:</strong>
                </Grid>
                <Grid item xs={4}>
                    <code>{store.FranchiseID}</code>
                </Grid>
                <Grid item xs={2}>
                    <strong>Short ID:</strong>
                </Grid>
                <Grid item xs={4}>
                    <code>{store.StoreShortID}</code>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>FBC:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.FBC}
                </Grid>
                <Grid item xs={2}>
                    <strong>FBC Email:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.FBCEmail ? <a href={`mailto:${store.FBCEmail}`}>{store.FBCEmail.toLowerCase()}</a> : <Chip label='Not Set' />}
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <strong>RVP:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.RVP ? store.RVP : <Chip label='Not Set' />}
                </Grid>
                <Grid item xs={2}>
                    <strong>RVP Email:</strong>
                </Grid>
                <Grid item xs={4}>
                    {store.RVPEmail ? <a href={`mailto:${store.RVPEmail}`}>{store.RVPEmail.toLowerCase()}</a> : <Chip label='Not Set' />}
                </Grid>
            </Grid>
        </>
    );
};

export default StoreDetailPanel;
