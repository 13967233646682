import { Box, Grid, Typography, Divider, Snackbar, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { withStyles, styled } from "@material-ui/core/styles";

import ToolsBreadcrumb from "../../components/Tools/ToolsBreadcrumb";
import UpdateProfileFromJSON from "../../components/Tools/UpdateProfileFromJSON";
import BatchResendActivations from "../../components/Tools/BatchResendActivations";
import MatchUserDataOnUnqiue from "../../components/Tools/MatchUserDataOnUnqiue";
import ResyncOktaToDB from "../../components/Tools/ResyncOktaToDB";

const StyledLink = styled(Link)({
    color: "var(--text-color)",
    textDecoration: "none",
});
const Tools = () => {
    const params = useParams();
    const [currentTool, setCurrentTool] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    const adminToolsItems = [
        {
            slug: "updateuserprofilesfromjson",
            label: "Update Profiles from JSON",
            component: <UpdateProfileFromJSON setToastContent={setToastContent} setToastOpen={setToastOpen} />,
        },
        {
            slug: "batchresendactivations",
            label: "Batch Resend Activations",
            component: <BatchResendActivations setToastContent={setToastContent} setToastOpen={setToastOpen} />,
        },
        {
            slug: "matchuserdataonunique",
            label: "Match User Data on Unique",
            component: <MatchUserDataOnUnqiue setToastContent={setToastContent} setToastOpen={setToastOpen} />,
        },
        {
            slug: "resyncoktatodb",
            label: "Resync Okta Data to DB",
            component: <ResyncOktaToDB setToastContent={setToastContent} setToastOpen={setToastOpen} />,
        },
    ];
    useEffect(() => {
        if (params && params.id) {
            let _currtool = _.find(adminToolsItems, { slug: params.id });
            // console.log(_currtool);
            setCurrentTool(_currtool);
        } else {
            setCurrentTool(false);
        }
    }, [params]);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box py={1}>
                        <ToolsBreadcrumb item={currentTool} />
                        <Divider />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box>
                        <Typography component='h1' variant='h6'>
                            Tools
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <List>
                            {_.map(adminToolsItems, (tool, i) => {
                                let _listitem = (
                                    <StyledLink to={`/super/tools/${tool.slug}`} key={i}>
                                        <ListItem button title={tool.label}>
                                            <ListItemText primary={tool.label} />
                                        </ListItem>
                                    </StyledLink>
                                );
                                return _listitem;
                            })}
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    {currentTool ? currentTool.component : <div>Please select a tool from the list to the left.</div>}
                </Grid>
            </Grid>
            <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                <>{toastContent}</>
            </Snackbar>
        </>
    );
};

export default Tools;
