import { useCallback } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import DataTable from "../Common/DataTable";
import BrandIcons from "../Common/BrandIcons";
import { Dropdown } from "primereact/dropdown";
import { FormControl, Tooltip } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import LocationIcons from "../Common/LocationIcons";
import RoleIcons from "../Common/RoleIcons";
import { format } from "date-fns";
import { blue, grey } from "@material-ui/core/colors";
import { Tag } from "primereact/tag";
import { withStyles } from "@material-ui/core";
import DataLoading from "../Common/DataLoading";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);
const StoresTableData = (data) => {
    const { pagetitle, localstoragekey, listitems, showSelectCheckbox, clickable, selectAllEnabled, setSelectedItems, selectedItems, canExport, filterData } = data;
    const brands = useSelector((state) => state.brands.untouchedlist);
    // Cell Data
    const addressCellData = (originalRow, rowIndex) => {
        return `${originalRow.Address} ${originalRow.Address2}`;
    };

    // Cell Templates
    const requestDisplayNameTemplate = ({ row, value }) => {
        return (
            <div style={{ textTransform: "capitalize" }}>
                {row.original.firstName.toLowerCase()} {row.original.lastName.toLowerCase()}
            </div>
        );
    };
    const numBoolCellTemplate = ({ row, value }) => {
        if (value) {
            return (
                <div style={{ textAlign: "center" }}>
                    <Tag style={{ backgroundColor: blue["800"], color: "var(--primary-color-highlight-text)" }} value='Active' />
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: "center" }}>
                    <Tag style={{ backgroundColor: ["800"], color: "var(--primary-color-highlight-text)" }} value='Inactive' />
                </div>
            );
        }
    };
    const brandCellTemplate = ({ row, value }) => {
        return (
            <div style={{ textAlign: "center" }} px={1}>
                <BrandIcons slug={value} />
            </div>
        );
    };
    const addressCellTemplate = ({ row, value }) => {
        return (
            <HtmlTooltip
                title={
                    <>
                        <div>{row.original.Address}</div>
                        {row.original.Address2 ? <div>{row.original.Address2}</div> : ""}
                    </>
                }>
                <div style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{row.original.Address}</div>
            </HtmlTooltip>
        );
    };
    const roleTemplate = ({ row, value }) => {
        return (
            <div style={{ textAlign: "center" }}>
                <RoleIcons slug={value} />
            </div>
        );
    };
    const dateTemplate = ({ row, value }) => {
        return <div style={{ textAlign: "center" }}>{format(new Date(value), "MMMM do, yyyy")}</div>;
    };
    // Filters
    const numBoolFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        return (
            <>
                <FormControl fullWidth>
                    <Select
                        id='bool-filter-select'
                        style={{ textTransform: "capitalize" }}
                        value={filterValue || ""}
                        fullWidth
                        defaultValue={""}
                        onChange={(e) => {
                            setFilter(e.target.value || undefined);
                        }}>
                        <MenuItem value={""}>All</MenuItem>
                        <MenuItem value={"1"}>Active</MenuItem>
                        <MenuItem value={"0"}>Inactive</MenuItem>
                    </Select>
                </FormControl>
            </>
        );
    };
    const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = useMemo(() => {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                if (row.values[id]) {
                    options.add(row.values[id]);
                }
                // else {
                //     options.add("Not Set");
                // }
            });
            return [...options.values()];
        }, [id, preFilteredRows]);
        // Render a multi-select box
        return (
            <>
                <FormControl fullWidth>
                    <Select
                        id='filter-select'
                        style={{ textTransform: "capitalize" }}
                        value={filterValue || ""}
                        fullWidth
                        defaultValue={""}
                        onChange={(e) => {
                            setFilter(e.target.value || undefined);
                            // console.log(e.target);
                            // setFilter(["aa"]);
                            // const allValues = Array.from(e.target.value)
                            //     .map((o) => o.value)
                            //     .filter(Boolean);
                            // setFilter(allValues && allValues.length ? allValues : undefined);
                        }}>
                        <MenuItem value={""}>All</MenuItem>
                        {_.map(options, (option, i) => (
                            <MenuItem key={i} value={option} style={{ textTransform: "capitalize" }}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        );
    };
    const SelectFRMBrandFilter = useCallback(
        ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
            // Calculate the options for filtering
            // using the preFilteredRows
            let options = _.compact(
                _.map(brands, (brand, i) => {
                    if (brand.active && brand.slug !== "all") {
                        return brand;
                    }
                })
            );
            return (
                <>
                    <FormControl fullWidth>
                        {/* <InputLabel id='brand-filter-label' style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            Select Brand
                        </InputLabel> */}
                        <Select
                            labelId='brand-filter-label'
                            id='brand-filter-select'
                            value={filterValue || ""}
                            fullWidth
                            defaultValue={""}
                            onChange={(e) => {
                                let _brand = _.find(options, ["frm_name", e.target.value]);
                                if (_brand) {
                                    setFilter(_brand.frm_name || "");
                                } else {
                                    setFilter(undefined);
                                }
                                // console.log(e.target);
                                // setFilter(["aa"]);
                                // const allValues = Array.from(e.target.value)
                                //     .map((o) => o.value)
                                //     .filter(Boolean);
                                // setFilter(allValues && allValues.length ? allValues : undefined);
                            }}>
                            <MenuItem value={""}>All</MenuItem>
                            {_.map(options, (brand, i) => (
                                <MenuItem key={i} value={brand.frm_name}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            );
        },
        [brands]
    );
    const columns = useMemo(
        () => [
            {
                Header: "Store GUID",
                accessor: "FranchiseID",
                id: "FranchiseID",
                filter: "fuzzyText",
                hidden: true,
            },
            {
                Header: "Store #",
                accessor: "StoreNumber",
                id: "StoreNumber",
                filter: "fuzzyText",
            },
            {
                Header: "Status",
                accessor: "FranchiseStatus",
                id: "FranchiseStatus",
                Filter: SelectColumnFilter,
            },
            {
                Header: "Brand",
                accessor: "Brand",
                id: "Brand",
                Cell: brandCellTemplate,
                Filter: SelectFRMBrandFilter,
            },
            {
                Header: "FRM",
                accessor: "Active",
                id: "Active",
                Cell: numBoolCellTemplate,
                Filter: numBoolFilter,
            },
            {
                Header: "Manual Reporting",
                accessor: "ManualReporting",
                id: "ManualReporting",
                Cell: numBoolCellTemplate,
                Filter: numBoolFilter,
                hidden: true,
            },
            {
                Header: "Location",
                accessor: "Location",
                id: "Location",
                filter: "fuzzyText",
            },
            {
                Header: "Franchise Name",
                accessor: "FranchiseBusinessName",
                id: "FranchiseBusinessName",
                filter: "fuzzyText",
            },
            {
                Header: "Address",
                accessor: addressCellData,
                id: "Address",
                Cell: addressCellTemplate,
                filter: "fuzzyText",
                hidden: true,
            },
            {
                Header: "City",
                accessor: "City",
                id: "City",
                filter: "fuzzyText",
                hidden: true,
            },
            {
                Header: "State",
                accessor: "State",
                id: "State",
                Filter: SelectColumnFilter,
                hidden: true,
            },
            {
                Header: "Country",
                accessor: "Country",
                id: "Country",
                Filter: SelectColumnFilter,
                hidden: true,
            },
            {
                Header: "Primary Contact",
                accessor: "PrimaryContact",
                id: "PrimaryContact",
                filter: "fuzzyText",
                hidden: true,
            },
        ],
        [SelectFRMBrandFilter]
    );

    return listitems ? (
        <DataTable
            pagetitle={pagetitle}
            localstoragekey={localstoragekey}
            listitems={listitems}
            columns={columns}
            clickable={clickable}
            selectAllEnabled={selectAllEnabled}
            showSelectCheckbox={showSelectCheckbox}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            excelFileName={"matrixstores"}
            canExport={canExport}
            idfield={"FranchiseID"}
            pageslug={"stores"}
            filterData={
                filterData || [
                    { id: "FranchiseStatus", value: "Open" },
                    { id: "Active", value: "1" },
                ]
            }
        />
    ) : (
        <DataLoading />
    );
};

export default StoresTableData;
