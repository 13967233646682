import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Snackbar, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import _ from "lodash";
import axios from "axios";
import * as Yup from "yup";
import { useHistory, NavLink } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { getRequests } from "../../actions";
import { useState, useEffect } from "react";
import { isAfter, isBefore, differenceInDays, subDays, format, isSameDay, formatISO9075 } from "date-fns";
import MuiAlert from "@material-ui/lab/Alert";

import RequestsBreadcrumb from "./RequestsBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-duotone-svg-icons";
import { amber, blue, green, grey, red } from "@material-ui/core/colors";

import UserEditorForm from "../Common/UserEditorForm";
import ControlledSubmitBtn from "../Common/ControlledSubmitBtn";
import UserNameField from "../Fields/UserNameField";
import PendingRequestFormMenu from "./PendingRequestFormMenu";
import EmailLookupField from "../Fields/EmailLookupField";
import StoresListSelection from "../Common/StoresListSelection";
import AppsListSelection from "../Common/AppsListSelection";
import NewUserBreadcrumb from "../User/NewUserBreadcrumb";
import NewUserFormMenu from "../User/NewUserFormMenu";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const GreenBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Box);
const RedBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Box);
const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        borderRadius: "5px",
    },
}))(Box);
const AmberBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(amber[500]),
        backgroundColor: amber[500],
        "&:hover": {
            backgroundColor: amber[700],
        },
    },
}))(Box);
const GreyBox = withStyles((theme) => ({
    root: {
        border: "thin solid",
        borderColor: grey[300],
        borderRadius: "5px",
    },
}))(Box);

const CreateUserForm = ({ item, currentAdmin }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const stores = useSelector((state) => state.stores.untouchedlist);
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const formSchema = Yup.object().shape({
        firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
        lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        secondEmail: Yup.string().email("Invalid email").nullable(),
        userRole: Yup.string().required("Required"),
        location: Yup.string().required("Required"),
        assocBrands: Yup.array().required("Required"),
        primaryPhone: Yup.string().matches(phoneRegExp, "Phone number is not valid").nullable(),
        mobilePhone: Yup.string().matches(phoneRegExp, "Phone number is not valid").nullable(),
        streetAddress: Yup.string().min(2, "Too Short!").max(50, "Too Long!").nullable(),
        city: Yup.string().min(2, "Too Short!").max(50, "Too Long!").nullable(),
        zipCode: Yup.mixed().nullable(),
    });
    const [initFormValues, setInitFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        userRole: "",
        location: "",
        countryCode: "",
        assocBrands: "",
        request_date: "",
        status: "",
        approval_date: "",
        approvalData: "",
        secondEmail: "",
        storeList: "",
        comments: "",
        submission_slug: "",
        username: "",
        primaryPhone: "",
        mobilePhone: "",
    });
    const [formActive, setFormActive] = useState(false);
    const [usernameValidMsg, setUsernameValidMsg] = useState(null);
    const [lookupEmailValidMsg, setLookupEmailValidMsg] = useState(null);
    const [validUsername, setValidusername] = useState();
    const [showFormHelp, setShowFormHelp] = useState(false);
    const [checkedUsername, setCheckedUsername] = useState("");
    const [cancelmsg, setcancelmsg] = useState("");
    const [assignedApps, setAssignedApps] = useState([]);
    const [assignedStores, setAssignedStores] = useState([]);
    const [rejectModal, setRejectModal] = useState(false);
    const [showStoreListField, setShowStoreListField] = useState(false);
    const [showAppsListField, setShowAppsListField] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const openRejectModal = () => setRejectModal(true);
    const closeRejectModal = () => setRejectModal(false);
    const toggleHelp = () => setShowFormHelp(!showFormHelp);
    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            dispatch(getRequests(token));
            return;
        }
        setToastOpen(false);
        dispatch(getRequests(token));
    };
    const validateUsername = async (username) => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/verifyusername`,
                {
                    username,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setUsernameValidMsg(
                        <GreenBox p={1} style={{ borderRadius: "5px" }}>
                            {res.data.message}
                        </GreenBox>
                    );
                    setCheckedUsername(username);
                    setValidusername("valid");
                    setTimeout(() => {
                        setUsernameValidMsg(null);
                    }, 4000);
                } else {
                    setCheckedUsername("");
                    setValidusername("invalid");
                    setUsernameValidMsg(
                        <RedBox p={1} style={{ borderRadius: "5px" }}>
                            {res.data.message}
                        </RedBox>
                    );
                    setTimeout(() => {
                        setUsernameValidMsg(null);
                    }, 4000);
                }
            })
            .catch(function (error) {});
    };
    const checkContactEmail = async (email) => {
        setLookupEmailValidMsg("");
        const token = oktaAuth.getAccessToken();
        axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/getusersbycontactemail`,
                {
                    email,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                if (res.data.success) {
                    setLookupEmailValidMsg(
                        <AmberBox p={1} style={{ borderRadius: "5px" }}>
                            <small>
                                <div>
                                    This email address ({email}) has been used for {res.data.users.length} existing accounts. Please verify that you are not creating a duplicate account.
                                </div>
                                <ol>
                                    {_.map(res.data.users, (user, i) => {
                                        return (
                                            <li key={i}>
                                                <a href={`/users/${user.id}`} target='_blank' rel='noopener noreferrer'>
                                                    {user.profile.displayName} - {user.profile.login}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ol>
                            </small>
                        </AmberBox>
                    );
                } else {
                    setLookupEmailValidMsg("");
                }
            })
            .catch(function (error) {});
    };
    const handleSubmit = async (values) => {
        // let appGUIDs = _.compact(
        //     _.map(assignedApps, (app, i) => {
        //         if (app.oktagroupid !== "okta_rule") {
        //             return app.oktagroupid;
        //         }
        //     })
        // );
        // if (_.includes(values.assocVenueType, "traditional") && _.includes(values.assocVenueType, "non-traditional")) {
        //     values.assocVenueType = "both";
        // } else {
        //     values.assocVenueType = _.toString(values.assocVenueType);
        // }
        // if (values.facMember === "Yes") {
        //     values.facMember = true;
        // } else {
        //     values.facMember = false;
        //     values.facTitle = "";
        // }
        let submitdata = {
            profile: {
                assocBrands: values.assocBrands,
                assocVenueType: values.assocVenueType,
                city: values.city,
                countryCode: values.countryCode,
                displayName: `${values.firstName} ${values.lastName}`,
                email: values.email,
                facMember: values.facMember,
                facTitle: values.facTitle,
                firstName: values.firstName,
                lastName: values.lastName,
                location: values.location,
                login: `${values.username}@focusbrandsmatrix.com`,
                mobilePhone: values.mobilePhone,
                primaryPhone: values.primaryPhone,
                secondEmail: values.secondEmail,
                state: values.state,
                storeGUIDs: assignedStores,
                guestRelationsAccessList: _.join(
                    _.compact(
                        _.map(assignedStores, (store, i) => {
                            let _currstore = _.find(stores, { FranchiseID: store });
                            if (_currstore) {
                                return store.StoreShortID;
                            }
                        })
                    ),
                    ","
                ),
                streetAddress: values.streetAddress,
                userRole: values.userRole,
                zipCode: values.zipCode,
            },
            // groupIds: appGUIDs,
        };
        console.log(submitdata);
        let formvalues = {
            ...values,
            approvingAdmin: {
                profile: { id: currentAdmin.id, displayName: currentAdmin.profile.displayName, login: currentAdmin.profile.login },
            },
        };
        console.log(formvalues);
        let approvaldata = {
            id: values.id,
            username: `${values.username}@focusbrandsmatrix.com`,
            status: "Approved",
            approval_date: formatISO9075(new Date()),
            approvalData: JSON.stringify(formvalues),
            email: `${item.email}${item.secondEmail ? `; ${item.secondEmail}` : ""}`,
            fromEmail: `ssoadmin@focusbrands.com`,
            fromName: `SSO Admin`,
            name: `${item.firstName} ${item.lastName}`,
            bcc: currentAdmin.profile.email,
            sendingadmin: currentAdmin.profile.displayName,
            sendingadminlogin: currentAdmin.profile.login,
            sendingtype: "approvalmsg",
            sendingtimestamp: formatISO9075(new Date()),
            sendingcontent: "Request Approval Msg",
            sendingrecipients: JSON.stringify(`${item.firstName} ${item.lastName} ${item.email}`),
        };

        console.log(approvaldata);

        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/createuser`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                    if (item) {
                        setTimeout(() => {
                            setRequestStatus(approvaldata, res.data.res.id);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            history.push(`/users`);
                        }, 1000);
                    }
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const changeCancelMsg = (e) => {
        setcancelmsg(e.currentTarget.value);
    };
    const setRequestStatus = async (bodyData, newuserid) => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/setrequeststatus`, bodyData, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const rejectRequest = async (cancelmsg, item) => {
        setRejectModal(false);
        const bodyData = {
            status: "Cancelled",
            id: item.id,
            comments: cancelmsg,
            email: `${item.email}${item.secondEmail ? `; ${item.secondEmail}` : ""}`,
            fromEmail: `ssoadmin@focusbrands.com`,
            fromName: `SSO Admin`,
            name: `${item.firstName} ${item.lastName}`,
            bcc: currentAdmin.profile.email,
        };
        bodyData.sendingadmin = currentAdmin.profile.displayName;
        bodyData.sendingadminlogin = currentAdmin.profile.login;
        bodyData.sendingtype = "cancelmsg";
        bodyData.sendingtimestamp = formatISO9075(new Date());
        bodyData.sendingcontent = JSON.stringify(cancelmsg);
        bodyData.sendingrecipients = JSON.stringify(`${item.firstName} ${item.lastName} ${item.email}`);
        const token = oktaAuth.getAccessToken();
        console.log(bodyData);
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/setrequeststatus`, bodyData, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    useEffect(() => {
        if (item) {
            // new user from request
            let _item = item;
            if (Array.isArray(_item.assocBrands)) {
                // console.log("an array");
            } else {
                _item.assocBrands = _.compact(_item.assocBrands.split(";"));
            }
            _item.firstName = _item.firstName
                .trim()
                .toLowerCase()
                .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
            _item.lastName = _item.lastName
                .trim()
                .toLowerCase()
                .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
            _item.apps = [];
            _item.stores = [];
            const initValues = { ...initFormValues, ..._item };
            setInitFormValues(initValues);
            setFormActive(true);
            if (_item.email) {
                checkContactEmail(_item.email);
            }
        } else {
            // new user from blank
            const initValues = { ...initFormValues, assocBrands: [], apps: [], stores: [] };
            setInitFormValues(initValues);
            setFormActive(true);
        }
    }, [item]);

    return (
        <>
            <Paper>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box p={1}>{item ? <RequestsBreadcrumb item={item} /> : <NewUserBreadcrumb />}</Box>
                        <Box display='flex' p={1}>
                            <Box p={1} flexGrow={1}>
                                <Typography variant='h4' component='h1'>
                                    {item ? <>Pending Request</> : <>Create New User</>}
                                </Typography>
                            </Box>
                            <Box p={1}>
                                <small style={{ color: "red" }}>
                                    <sup>*</sup>
                                </small>
                                Required
                            </Box>
                            <Box p={1}>
                                {showFormHelp ? (
                                    <BlueButton variant='contained' size='small' onClick={toggleHelp}>
                                        <FontAwesomeIcon icon={faToggleOn} />
                                        &nbsp;Help
                                    </BlueButton>
                                ) : (
                                    <GreyButton variant='contained' size='small' onClick={toggleHelp}>
                                        <FontAwesomeIcon icon={faToggleOff} />
                                        &nbsp;Help
                                    </GreyButton>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {formActive ? (
                            <Formik initialValues={initFormValues} onSubmit={handleSubmit} validationSchema={formSchema} enableReinitialize>
                                {(props) => {
                                    return (
                                        <Form>
                                            <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                                                <>{toastContent}</>
                                            </Snackbar>
                                            <Dialog open={rejectModal} onClose={closeRejectModal} aria-labelledby='form-dialog-title' maxWidth='md'>
                                                <DialogTitle id='form-dialog-title'>Reject Request</DialogTitle>
                                                <DialogContent>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={2}>
                                                            <strong>Send To:</strong>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            {props.values.firstName} {props.values.lastName}
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <strong>Email:</strong>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            {props.values.email}
                                                            {props.values.secondEmail ? <>; {props.values.secondEmail}</> : ""}
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <strong>Message:</strong>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <TextField
                                                                type='textarea'
                                                                name='cancelmsg'
                                                                value={cancelmsg}
                                                                onChange={changeCancelMsg}
                                                                id='cancelmsg'
                                                                variant='outlined'
                                                                multiline
                                                                rows={5}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}></Grid>
                                                        <Grid item xs={10}>
                                                            <Alert color='info'>
                                                                <strong>NOTE:</strong> Message will be sent from: <strong>SSO Admin</strong> - ssoadmin@focusbrands.com
                                                            </Alert>
                                                        </Grid>
                                                    </Grid>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={closeRejectModal} color='primary'>
                                                        Cancel
                                                    </Button>
                                                    <RedButton variant='contained' onClick={() => rejectRequest(cancelmsg, props.values)}>
                                                        Reject
                                                    </RedButton>
                                                </DialogActions>
                                            </Dialog>
                                            {item ? (
                                                <PendingRequestFormMenu props={props} currentAdmin={currentAdmin} openRejectModal={openRejectModal} validUsername={validUsername} />
                                            ) : (
                                                <NewUserFormMenu props={props} currentAdmin={currentAdmin} validUsername={validUsername} />
                                            )}
                                            <Box px={1}>
                                                <UserNameField
                                                    props={props}
                                                    showFormHelp={showFormHelp}
                                                    fieldHelpText={`Username should be at least 3 characters long, contain no numbers. If you validate the username and then change it, you will need to validate it again. Valid examples: bwayne,
                                            brucewayne, b.wayne, bruce.wayne`}
                                                    autofilldomain={"@focusbrandsmatrix.com"}
                                                    checkedUsername={checkedUsername}
                                                    validateUsername={validateUsername}
                                                    setValidusername={setValidusername}
                                                    usernameValidMsg={usernameValidMsg}
                                                />

                                                <UserEditorForm
                                                    props={props}
                                                    showFormHelp={showFormHelp}
                                                    checkContactEmail={checkContactEmail}
                                                    lookupEmailValidMsg={lookupEmailValidMsg}
                                                    setLookupEmailValidMsg={setLookupEmailValidMsg}
                                                />
                                                <Grid item xs={12}>
                                                    <GreyBox p={1} my={1}>
                                                        <Box display='flex'>
                                                            <Box p={1}>
                                                                <Typography variant='h5' component='h2'>
                                                                    Select Stores
                                                                </Typography>
                                                            </Box>
                                                            <Box p={1} flexGrow={1}>
                                                                {props.values.requestedStores ? (
                                                                    <BlueBox p={1}>
                                                                        <strong>Requested Comments:</strong> {props.values.requestedStores}{" "}
                                                                    </BlueBox>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Box>
                                                            <Box p={1}>
                                                                {showStoreListField ? (
                                                                    <Button variant='outlined' onClick={() => setShowStoreListField(!showStoreListField)}>
                                                                        Hide Stores List
                                                                    </Button>
                                                                ) : (
                                                                    <Button variant='outlined' onClick={() => setShowStoreListField(!showStoreListField)}>
                                                                        Show Stores List
                                                                    </Button>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        {showStoreListField ? (
                                                            <StoresListSelection
                                                                props={props}
                                                                currentAdmin={currentAdmin}
                                                                showFormHelp={showFormHelp}
                                                                fieldHelpText={
                                                                    <>
                                                                        Stores are shown depending location and brands that are selected. This list will only show active stores. Please double check
                                                                        the <NavLink to={`/stores`}>Stores</NavLink> list if a store that you are expecting to see does not appear here.
                                                                    </>
                                                                }
                                                                selectedItems={assignedStores}
                                                                setSelectedItems={setAssignedStores}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </GreyBox>
                                                </Grid>
                                            </Box>
                                            {item ? (
                                                <PendingRequestFormMenu props={props} currentAdmin={currentAdmin} openRejectModal={openRejectModal} validUsername={validUsername} />
                                            ) : (
                                                <NewUserFormMenu props={props} currentAdmin={currentAdmin} validUsername={validUsername} />
                                            )}
                                        </Form>
                                    );
                                }}
                            </Formik>
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default CreateUserForm;
