import { useState, useEffect, useMemo, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import _ from "lodash";
import {
    Grid,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    FormControl,
    InputLabel,
    makeStyles,
    Box,
    Button,
    Divider,
    ListItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { blue, grey, red } from "@material-ui/core/colors";
import MuiAlert from "@material-ui/lab/Alert";

import oktaUserData from "../Common/tempadpdata";
import { json } from "body-parser";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const MatchUserDataOnUnqiue = (data) => {
    const { setToastContent, setToastOpen } = data;
    const history = useHistory();
    const classes = useStyles();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const { oktaAuth, authState } = useOktaAuth();
    const [jsonData, setjsonData] = useState(false);
    const [fieldNames, setFieldNames] = useState([]);
    const [userBaseSchema, setUserBaseSchema] = useState([]);
    const [userCustomSchema, setUserCustomSchema] = useState([]);
    const [idFieldNameChoices, setidFieldNameChoices] = useState([]);
    const [idField, setidField] = useState(false);
    const [oktaField, setOktaField] = useState(false);
    const [oktaFieldNamesValid, setoktaFieldNamesValid] = useState(false);
    const [ignoreFields, setignoreFields] = useState();
    const [dataLength, setDataLength] = useState(false);
    const [oktaUserUpdates, setOktaUserUpdates] = useState([]);
    const [preparedProcessingGroups, setpreparedProcessingGroups] = useState([]);
    const cancelAPICalls = () => {
        source.cancel("Operation canceled by the user.");
    };
    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    const processFile = (e) => {
        getOktaUserSchema();
        const file = e.target.files[0];
        let reader = new FileReader();
        if (file) {
            reader.readAsText(file);
            reader.onload = function () {
                let resultjson = JSON.parse(reader.result);
                setjsonData(resultjson);
            };

            reader.onerror = function () {
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {reader.error}
                    </Alert>
                );
                setToastOpen(true);
            };
        }
    };
    const userIdSelected = (e) => {
        setidField(e.target.value);
    };
    const oktaMatchFieldSelected = (e) => {
        setOktaField(e.target.value);
    };
    const oktaFieldSelected = (e, field) => {
        let _fieldarry = _.map(fieldNames, (item, i) => {
            if (item.name === field.name) {
                item.oktafield = e.target.value;
            }
            return item;
        });
        setFieldNames(_fieldarry);
    };
    const fieldsOktaValid = (e) => {
        setoktaFieldNamesValid(!oktaFieldNamesValid);
    };
    const updateIgnoreFields = (field) => {
        setignoreFields({ ...ignoreFields, [field]: !ignoreFields[field] });
    };
    const fieldTypeSelected = (e, field) => {
        let _fieldarry = _.map(fieldNames, (item, i) => {
            if (item.name === field.name) {
                item.type = e.target.value;
            }
            return item;
        });
        setFieldNames(_fieldarry);
    };
    const getOktaUserSchema = () => {
        const token = oktaAuth.getAccessToken();
        axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getoktauserschema`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    let _baseschema = _.map(res.data.response.definitions.base.properties, (property, i) => {
                        return i;
                    });
                    setUserBaseSchema(_baseschema);
                    let _customschema = _.map(res.data.response.definitions.custom.properties, (property, i) => {
                        return i;
                    });
                    setUserCustomSchema(_customschema);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getUserByUniqueID = async (oktaField, adpitemdata) => {
        const token = oktaAuth.getAccessToken();
        let searchstring = `profile.${oktaField} eq ${adpitemdata[idField]}`;
        return axios
            .get(`${process.env.REACT_APP_APIHOST}/api/getuserbyuniqueid`, {
                headers: {
                    authorization: `Bearer ${token}`,
                    searchstring,
                },
                cancelToken: source.token,
            })
            .then((res) => {
                // console.log(`${adpitemdata["First Name"]} ${adpitemdata["Last Name"]}`, res.data);

                if (res.data.success) {
                    // let _updateduser = {
                    //     id: res.data.user.id,
                    //     profile: {
                    //         login: res.data.user.profile.login,
                    //         firstName: res.data.user.profile.firstName,
                    //         lastName: res.data.user.profile.lastName,
                    //     },
                    // };
                    let _updateduser = res.data.user;
                    _.each(fieldNames, (field, i) => {
                        if (field.oktafield) {
                            _updateduser.profile[field.oktafield] = adpitemdata[field.name];
                        }
                    });

                    return _updateduser;
                } else {
                    return false;
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const findUsersBySetID = () => {
        console.log(idField, oktaField);

        let _prmsArry = _.compact(
            _.map(jsonData, (item, i) => {
                // FOR TESTING ONLY
                // if (i < 25) {
                //     return getUserByUniqueID(oktaField, item);
                // } else {
                //     return false;
                // }

                return getUserByUniqueID(oktaField, item);
            })
        );
        Promise.all(_prmsArry).then((values) => {
            setOktaUserUpdates(_.compact(values));
            let _chunkedJSONData = _.chunk(_.compact(values), 500);
            setpreparedProcessingGroups(_chunkedJSONData);
        });
    };
    const processUserData = (group) => {
        console.log(group);
        _.each(group, (user, i) => {
            handleSubmit(user);
        });
    };
    const handleSubmit = async (user) => {
        console.log(user);
        let _profilevalues = _.omit(user.profile, ["login", "firstName", "lastName"]);
        let submitdata = { ...user, profile: _profilevalues };
        let oktasubmitdata = { id: user.id, profile: _profilevalues };
        console.log(submitdata);
        // updateProfileDB(submitdata);
        updateProfileOkta(oktasubmitdata);
    };
    const updateProfileDB = (submitdata) => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserprofiledb`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const updateProfileOkta = (submitdata) => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserprofile`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    useEffect(() => {
        if (jsonData) {
            setDataLength(jsonData.length);
            let _datatemplaterow = jsonData[0];
            let _fieldnames = _.keys(_datatemplaterow);
            setidFieldNameChoices(_fieldnames);
            _fieldnames = _.map(_fieldnames, (field, i) => {
                return { name: field, type: "string" };
            });
            setFieldNames(_fieldnames);
            let _ignorefields = _.mapValues(_datatemplaterow, (val, i) => {
                return false;
            });
            setignoreFields(_ignorefields);
        }
    }, [jsonData]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {" "}
                    <FormGroup row>
                        <FormControlLabel control={<input type='file' name='file' id='exampleFile' accept='application/JSON' onChange={processFile} />} label='Upload JSON file' />
                    </FormGroup>
                    <div>Upload a JSON file that makes sure to include any fields you want to update along with the Okta user id/guid.</div>
                </Grid>
                <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id='idFieldSelect-label'>Unique Field</InputLabel>
                        <Select labelId='idFieldSelect-label' id='idFieldSelect' value={idField} required disabled={!jsonData} onChange={userIdSelected}>
                            <MenuItem value={false}>-select-</MenuItem>
                            {_.map(idFieldNameChoices, (name, i) => {
                                return (
                                    <MenuItem key={i} value={name}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    Matches to
                </Grid>
                <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id='oktaFieldSelect-label'>Okta Field</InputLabel>
                        <Select labelId='oktaFieldSelect-label' id='OktaFieldSelect' value={oktaField} required disabled={!jsonData} onChange={oktaMatchFieldSelected}>
                            <MenuItem value={false}>-select-</MenuItem>
                            {_.map(userBaseSchema, (name, i) => {
                                return (
                                    <MenuItem key={i} value={name}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}></Grid>
            </Grid>
            <Box p={1}>Total Items: {dataLength}</Box>
            <Box p={1}>
                <Divider />
            </Box>
            {jsonData ? (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            Field
                        </Grid>
                        <Grid item xs={2}>
                            Ignore?
                        </Grid>
                        <Grid item xs={2}>
                            {/* Type */}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}>
                            Okta Field
                        </Grid>
                    </Grid>
                    {_.map(fieldNames, (field, i) => {
                        return (
                            <Grid key={i} container spacing={2}>
                                <Grid item xs={2}>
                                    {field.name}
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={`ignorefield-${field.name}`}
                                                className='d-inline'
                                                label={"Ignore Field?"}
                                                checked={ignoreFields[field.name] || false}
                                                value={ignoreFields[field.name]}
                                                onChange={() => updateIgnoreFields(field.name)}
                                            />
                                        }
                                        label={"Yes"}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    {/* <FormControl className={classes.formControl}>
                                <InputLabel id={`fieldtype-${field.name}-label`}>Type:</InputLabel>
                                <Select
                                    labelId={`fieldtype-${field.name}-label`}
                                    id={`fieldtype-${field.name}l`}
                                    value={field.type || ""}
                                    required
                                    disabled={!jsonData}
                                    onChange={(e) => fieldTypeSelected(e, field)}>
                                    <MenuItem value={"string"}>String</MenuItem>
                                    <MenuItem value={"boolean"}>Boolean</MenuItem>
                                    <MenuItem value={"array"}>Array</MenuItem>
                                </Select>
                            </FormControl> */}
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id={`oktafield-${field.name}-label`}>Okta Field</InputLabel>
                                        <Select
                                            labelId={`oktafield-${field.name}-label`}
                                            id={`oktafield-${field.name}`}
                                            value={field.oktafield || ""}
                                            required
                                            disabled={!jsonData}
                                            onChange={(e) => oktaFieldSelected(e, field)}>
                                            <ListItem divider>
                                                <strong>Base Fields</strong>
                                            </ListItem>
                                            {_.map(userBaseSchema, (name, i) => {
                                                return (
                                                    <MenuItem key={i} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                );
                                            })}
                                            <Divider />
                                            <ListItem divider>
                                                <strong>Custom Fields</strong>
                                            </ListItem>
                                            {_.map(userCustomSchema, (name, i) => {
                                                return (
                                                    <MenuItem key={i} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <Box p={1}>
                        <BlueButton
                            variant='contained'
                            size='small'
                            disabled={!idField && !oktaField}
                            onClick={() => {
                                findUsersBySetID();
                            }}>
                            Combine ADP Data to Okta Profiles
                        </BlueButton>
                        <RedButton variant='contained' size='small' onClick={cancelAPICalls}>
                            Cancel
                        </RedButton>
                    </Box>
                    <Box p={1}>
                        <Divider />
                    </Box>
                    <Box hidden={_.isEmpty(oktaUserUpdates)}>
                        <Box p={1}>Updating {oktaUserUpdates.length} Items</Box>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label='simple table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User Name</TableCell>
                                        <TableCell>Name</TableCell>
                                        {/* <TableCell align='right'>Fat&nbsp;(g)</TableCell>
                                        <TableCell align='right'>Carbs&nbsp;(g)</TableCell>
                                        <TableCell align='right'>Protein&nbsp;(g)</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(oktaUserUpdates, (row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{row.profile.login}</TableCell>
                                            <TableCell>
                                                {row.profile.firstName} {row.profile.lastName}
                                            </TableCell>
                                            {/* <TableCell align='right'>{row.fat}</TableCell>
                                            <TableCell align='right'>{row.carbs}</TableCell>
                                            <TableCell align='right'>{row.protein}</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <BlueButton
                            variant='contained'
                            size='small'
                            disabled={!idField && !oktaField}
                            onClick={() => {
                                handleSubmit();
                            }}>
                            Process Updates
                        </BlueButton> */}
                        {_.map(preparedProcessingGroups, (processGrp, i) => {
                            return (
                                <Box key={i} m={1}>
                                    <BlueButton
                                        variant='contained'
                                        size='small'
                                        onClick={() => {
                                            processUserData(processGrp);
                                        }}>
                                        Process Group {i + 1}
                                    </BlueButton>
                                </Box>
                            );
                        })}
                        <RedButton variant='contained' size='small' onClick={cancelAPICalls}>
                            Cancel
                        </RedButton>
                    </Box>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default MatchUserDataOnUnqiue;
