import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { create } from "jss";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import PrimeReact from "primereact/api";
import config from "./config";

import Layout from "./components/Layout";

import Main from "./pages/Main";
// import NotFound from "./pages/NotFound";
import Requests from "./pages/Requests";
import RequestID from "./pages/RequestID";
import CreateUser from "./pages/CreateUser";
import Stores from "./pages/Stores";
import StoreDetailLayout from "./components/Stores/StoreDetailLayout";
import Apps from "./pages/Apps";
// import CreateApp from "./pages/CreateApp";
// import AppUsers from "./pages/AppUsers";
import Users from "./pages/Users";
import UserDetailLayout from "./components/User/UserDetailLayout";
// import Comms from "./pages/Comms";
// import Franchisees from "./pages/Franchisees";
// import CreateCommsTemplate from "./pages/CreateCommsTemplate";
// import EditCommsTemplate from "./pages/EditCommsTemplate";
import Help from "./pages/Help";
import HelpDetail from "./components/Help/HelpPage";

// import Super from "./pages/super/Super";
import Admins from "./pages/super/Admins";
import SharedAccounts from "./pages/SharedAccounts";
import AdminUserDetailLayout from "./components/Super/Admins/AdminUserDetailLayout";
// import AdminHelp from "./pages/super/Help";
// import CreateHelp from "./pages/super/CreateHelp";
import CreateAdmin from "./pages/super/CreateAdmin";
import AdminTools from "./pages/super/Tools";
import Everyone from "./pages/super/Everyone";
// import UserRoles from "./pages/super/UserRoles";
// import CreateUserRole from "./pages/super/CreateUserRole";
// import AdminRoles from "./pages/super/AdminRoles";
// import CreateAdminRole from "./pages/super/CreateAdminRole";
// import Brands from "./pages/super/Brands";
// import CreateBrand from "./pages/super/CreateBrand";
// import Messaging from "./pages/Messaging";

const oktaAuth = new OktaAuth(config.oidc); //authClient
const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    insertionPoint: document.getElementById("jss-insertion-point"),
});
PrimeReact.ripple = true;
const App = (props) => {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };
    return (
        <StylesProvider jss={jss}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Switch>
                    <Route path='/implicit/callback' component={LoginCallback} />
                    <Layout>
                        <SecureRoute path='/' exact component={Main} {...props} />
                        <SecureRoute path='/accessrequests' exact component={Requests} {...props} />
                        <SecureRoute path='/accessrequests/:id' exact component={RequestID} {...props} />
                        <SecureRoute path='/createuser' component={CreateUser} {...props} />
                        <SecureRoute path='/stores' exact component={Stores} {...props} />
                        <SecureRoute path='/stores/:id' component={StoreDetailLayout} {...props} />
                        <SecureRoute path='/apps/:id?' component={Apps} {...props} />
                        {/* <SecureRoute path='/createapp' component={CreateApp} {...props} /> */}
                        {/* <SecureRoute path='/franchisees/:id?' component={Franchisees} {...props} /> */}
                        {/* <SecureRoute path='/guestfocus/users' component={AppUsers} {...props} /> */}
                        <SecureRoute path='/users' exact component={Users} {...props} />
                        <SecureRoute path='/users/:id' component={UserDetailLayout} {...props} />
                        <SecureRoute path='/sharedaccounts' exact component={SharedAccounts} {...props} />
                        <SecureRoute path='/sharedaccounts/:id' component={UserDetailLayout} {...props} />

                        <SecureRoute path='/help/' exact component={Help} {...props} />
                        <SecureRoute path='/help/:slug' component={HelpDetail} {...props} />

                        {/* <SecureRoute path='/super' component={Super} {...props} /> */}
                        <SecureRoute path='/super/admins' exact component={Admins} {...props} />
                        <SecureRoute path='/super/admins/:id' component={AdminUserDetailLayout} {...props} />
                        <SecureRoute path='/super/createadmin' component={CreateAdmin} {...props} />
                        <SecureRoute path='/super/everyone' exact component={Everyone} {...props} />
                        <SecureRoute path='/super/everyone/:id' exact component={UserDetailLayout} {...props} />
                        {/* <SecureRoute path='/super/userroles/:id?' component={UserRoles} {...props} /> */}
                        {/* <SecureRoute path='/super/createuserrole' component={CreateUserRole} {...props} /> */}
                        {/* <SecureRoute path='/super/adminroles/:id?' component={AdminRoles} {...props} /> */}
                        {/* <SecureRoute path='/super/createadminrole' exact component={CreateAdminRole} {...props} /> */}
                        {/* <SecureRoute path='/super/brands/:id?' component={Brands} {...props} /> */}
                        {/* <SecureRoute path='/super/createbrand' component={CreateBrand} {...props} /> */}
                        <SecureRoute path='/super/tools/:id?' component={AdminTools} {...props} />
                        {/* <SecureRoute path='/super/help/:id?' component={AdminHelp} {...props} /> */}
                        {/* <SecureRoute path='/super/createhelp' component={CreateHelp} {...props} /> */}
                        {/* <SecureRoute component={NotFound} {...props} /> */}
                    </Layout>
                </Switch>
            </Security>
        </StylesProvider>
    );
};

export default App;
