import { Box, FormControl, FormControlLabel, FormLabel, Radio } from "@material-ui/core";
import { Field, useField } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { blue, red } from "@material-ui/core/colors";

const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Box);
const CheckboxField = ({ props, fieldname, options, fieldlabel, fieldrequired, fieldHelpText, showFormHelp }) => {
    const fieldProps = useField(fieldname);
    const [field, meta, helpers] = fieldProps;

    return (
        <>
            <FormControl component='fieldset' required={fieldrequired} variant='outlined' margin='dense'>
                <FormLabel component='label'>{fieldlabel}</FormLabel>
                <ul style={{ columns: 2, listStyle: "none", marginTop: "0px", marginBottom: "0px", marginLeft: "0px" }}>
                    {_.map(options, (item, i) => {
                        return (
                            <li key={i}>
                                <Field
                                    component={CheckboxWithLabel}
                                    className='dense-choice-field'
                                    type='checkbox'
                                    name={fieldname}
                                    value={item.slug}
                                    checked={_.includes(props.values[fieldname], item.slug) || false}
                                    Label={{ label: item.label }}
                                    onChange={props.handleChange}
                                />
                            </li>
                        );
                    })}
                </ul>
            </FormControl>
            {showFormHelp ? (
                <BlueBox p={1} style={{ borderRadius: "5px" }}>
                    <small>{fieldHelpText}</small>
                </BlueBox>
            ) : (
                ""
            )}
        </>
    );
};

export default CheckboxField;
