import { useState, useEffect } from "react";
import { isAfter, isBefore, differenceInDays, subDays, format, isSameDay, formatISO9075 } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faRedo, faUserUnlock, faPaperPlane, faUserLock, faUserSlash, faKey, faEnvelope } from "@fortawesome/pro-duotone-svg-icons";
import _ from "lodash";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Countries from "i18n-iso-countries";

// import EditUserPanel from "./EditUserPanel";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Link, Snackbar, TextField, Tooltip, Typography } from "@material-ui/core";
import { amber, blue, green, grey, purple, red } from "@material-ui/core/colors";
import RoleIcons from "../../Common/RoleIcons";
import UserStatusIcons from "../../Common/UserStatusIcons";
import BrandIcons from "../../Common/BrandIcons";
import LocationIcons from "../../Common/LocationIcons";
import UserDetailMenuBar from "../../User/UserDetailMenuBar";
import MuiAlert from "@material-ui/lab/Alert";
import AdminRoleIcons from "../../Common/AdminRoleIcons";
import AdminDetailEditPanel from "./AdminDetailEditPanel";
// import UserDetailEditPanel from "./UserDetailEditPanel";
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const AmberButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(amber[500]),
        backgroundColor: amber[500],
        "&:hover": {
            backgroundColor: amber[700],
        },
    },
}))(Button);
const PurpleButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        "&:hover": {
            backgroundColor: purple[700],
        },
    },
}))(Button);
const GreenBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[600]),
        backgroundColor: green[600],
        "&:hover": {
            backgroundColor: green[700],
            color: theme.palette.getContrastText(green[700]),
        },
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const RedBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Box);
const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const AmberBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(amber[500]),
        backgroundColor: amber[500],
        "&:hover": {
            backgroundColor: amber[700],
        },
    },
}))(Box);
const GreyBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[200]),
        backgroundColor: grey[200],
        "&:hover": {
            backgroundColor: grey[700],
            color: theme.palette.getContrastText(grey[700]),
        },
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const AdminUserDetailPanel = (data) => {
    const { user, admin, showAdminBtns, getuserbyid } = data;
    const history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    const [editPanel, setEditPanel] = useState(false);
    const [adminBtns, setAdminBtns] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const [passwordRecoveryModal, setPasswordRecoveryModal] = useState(false);
    const [passwordResetModal, setPasswordResetModal] = useState(false);
    const [messageUserModal, setMessageUserModal] = useState(false);
    const [usrMessage, setUsrMessage] = useState({ subject: "", message: "" });
    const subChange = (e) => {
        setUsrMessage({ ...usrMessage, subject: e.currentTarget.value });
    };
    const msgChange = (e) => {
        setUsrMessage({ ...usrMessage, message: e.currentTarget.value });
    };
    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    const closePasswordRecoveryModal = () => setPasswordRecoveryModal(false);
    const closePasswordResetModal = () => setPasswordResetModal(false);
    const closeMessageUserModal = () => {
        setUsrMessage({ subject: "", message: "" });
        setMessageUserModal(false);
    };
    const sendMessage = async (msgContent) => {
        let bodyData = {
            ...msgContent,
            email: [user.profile.email, user.profile.secondEmail],
            fromEmail: `ssoadmin@focusbrands.com`,
            fromName: `SSO Admin`,
            name: `${user.profile.firstName} ${user.profile.lastName}`,
        };
        if (admin.profile.email.toLowerCase() === user.profile.email.toLowerCase()) {
        } else if (user.profile.secondEmail && admin.profile.email.toLowerCase() === user.profile.secondEmail.toLowerCase()) {
        } else {
            bodyData.bcc = admin.profile.email.toLowerCase();
        }
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/sendmessage`, bodyData, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                closeMessageUserModal();
                closePasswordResetModal();
                closePasswordRecoveryModal();
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const acctLifecycleAction = async (action, user) => {
        let call;

        switch (action) {
            case "reactivate":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${user.id}/lifecycle/reactivate?sendEmail=true`,
                };
                break;
            case "unlock":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${user.id}/lifecycle/unlock`,
                };
                break;
            case "suspend":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${user.id}/lifecycle/suspend`,
                };
                break;
            case "unsuspend":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${user.id}/lifecycle/unsuspend`,
                };
                break;
            case "changeRecoveryQuestion":
                setPasswordRecoveryModal(true);
                call = {
                    method: "PUT",
                    href: `/api/v1/users/${user.id}`,
                };
                break;
            case "expirePassword":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${user.id}/lifecycle/expire_password?tempPassword=true`,
                };
                break;
            case "resetPassword":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${user.id}/lifecycle/reset_password?sendEmail=true`,
                };
                break;
            default:
                break;
        }
        call.action = action;
        call.username = user.profile.login;
        call.status = user.status;
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/oktalifecycleaction`, call, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    if (res.data.action === "expirePassword") {
                        const msgContent = {
                            subject: "Focus Brands Matrix Temporary Password",
                            message: `<p>Your account <strong>${user.profile.login}</strong> just had it's password reset, by an admin, to a temporary password.</p><br/><p>Your temporary password is: <strong>${res.data.response.tempPassword}</strong></p><br/><p><strong><em>Note:</em></strong> Please use this temporary password the next time you log in.  You will then need to choose a new, secure password. (You'll also use this password as the "old password" on the Expired Password screen.)</p><br/><p><small>Sign in: <a href="https://www.focusbrandsmatrix.com">https://www.focusbrandsmatrix.com</></small> | <p><small>Help: <a href="https://www.focusbrandsmatrix.com/help">https://www.focusbrandsmatrix.com/help</></small></p>`,
                        };
                        sendMessage(msgContent);
                    } else if (res.data.action === "changeRecoveryQuestion") {
                        const msgContent = {
                            subject: "Focus Brands Matrix Forgotten Password Questions Reset",
                            message: `<p>Your account <strong>${user.profile.login}</strong> just had it's password recovery questions reset, by an admin, to a default setting.</p><br/>
                            <div>
                                <strong>Default Password Recovery Question</strong>
                            </div>
                            <hr />
                            <p>
                                <strong>Question: </strong>Who is Bruce Wayne?
                            </p>
                            <p>
                                <strong>Answer: </strong>Batman
                            </p>
                            <br/><p><small>Sign in: <a href="https://www.focusbrandsmatrix.com">https://www.focusbrandsmatrix.com</></small> | <p><small>Help: <a href="https://www.focusbrandsmatrix.com/help">https://www.focusbrandsmatrix.com/help</></small></p>`,
                        };

                        sendMessage(msgContent);
                    } else {
                        setToastContent(
                            <Alert onClose={handleToastClose} severity='success'>
                                {res.data.message}
                            </Alert>
                        );
                    }
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                closeMessageUserModal();
                closePasswordResetModal();
                closePasswordRecoveryModal();
                setToastOpen(true);
                setTimeout(() => {
                    getuserbyid(user.id);
                }, 4500);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const removeAdmin = async (id) => {
        const token = oktaAuth.getAccessToken();
        let submitData = {
            id,
            profile: {
                matrixAdmin: false,
                matrixAdminLocation: "",
                matrixAdminRole: "",
                matrixAdminAssocBrands: [],
            },
        };
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateadmin`, submitData, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );

                    setTimeout(() => {
                        history.push("/super/admins");
                    }, 4500);
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    useEffect(() => {
        if (admin && showAdminBtns) {
            setAdminBtns(
                <Box display='flex'>
                    <Box>
                        <Button color='inherit' onClick={() => setMessageUserModal(true)}>
                            <FontAwesomeIcon icon={faEnvelope} />
                            &nbsp;Message User
                        </Button>
                        <Button color='inherit' onClick={() => setEditPanel(true)}>
                            <FontAwesomeIcon icon={faUserEdit} />
                            &nbsp;Edit Admin
                        </Button>
                    </Box>
                    <Box mx={1} flexGrow={1}>
                        <Divider orientation='vertical' />
                    </Box>
                    <Box mx={1}></Box>
                    <Box mx={1}></Box>
                    <Box mx={1}>
                        <RedButton color='inherit' onClick={() => removeAdmin(user.id)}>
                            <FontAwesomeIcon icon={faUserLock} />
                            &nbsp;Remove Admin
                        </RedButton>
                    </Box>
                </Box>
            );
        }
    }, [admin, showAdminBtns]);

    return (
        <>
            {editPanel ? (
                <AdminDetailEditPanel user={user} admin={admin} setEditPanel={setEditPanel} getuserbyid={getuserbyid} removeAdmin={removeAdmin} />
            ) : (
                <>
                    <UserDetailMenuBar page='details' menu={adminBtns} />

                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <strong>Username:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            <span id='username-field'>{user.profile.login}</span>
                        </Grid>
                        <Grid item xs={2}>
                            <strong>Role:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            <AdminRoleIcons slug={user.profile.matrixAdminRole} label={true} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <strong>Location:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            {user.profile.matrixAdminLocation ? <LocationIcons slug={user.profile.matrixAdminLocation} label={true} /> : <span>Location not set.</span>}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <strong>Status:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            <UserStatusIcons status={user.status} />
                        </Grid>
                        <Grid item xs={2}>
                            <strong>Brand(s):</strong>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ columnCount: 2 }}>
                                {user.profile.matrixAdminLocation ? (
                                    <>
                                        {_.map(
                                            user.profile.matrixAdminAssocBrands.sort(function (a, b) {
                                                return a.length - b.length;
                                            }),
                                            (brand, i) => {
                                                return <BrandIcons key={i} slug={brand} location={user.profile.matrixAdminLocation} label={true} />;
                                            }
                                        )}
                                    </>
                                ) : (
                                    <span className='badge badge-danger'>Invalid. Location not set.</span>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Box p={1}>
                        <Divider variant='middle' />
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <strong>First Name:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            {user.profile.firstName}
                        </Grid>
                        <Grid item xs={2}>
                            <strong>Last Name:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            {user.profile.lastName}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <strong>Email:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <Link href={`mailto:${user.profile.email}`} className='text-lowercase'>
                                    {user.profile.email}
                                </Link>
                            </div>
                            <div>
                                {user.profile.secondEmail ? (
                                    <Link href={`mailto:${user.profile.secondEmail}`} className='text-lowercase'>
                                        {user.profile.secondEmail}
                                    </Link>
                                ) : (
                                    <GreyBox>2nd Email Not Present</GreyBox>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <strong>Phone:</strong>
                        </Grid>
                        <Grid item xs={4}>
                            <div>{user.profile.primaryPhone ? <Link href={`tel:${user.profile.primaryPhone}`}>{user.profile.primaryPhone}</Link> : <GreyBox>Primary Phone Missing</GreyBox>}</div>
                            <div>{user.profile.mobilePhone ? <Link href={`tel:${user.profile.mobilePhone}`}>{user.profile.mobilePhone}</Link> : <GreyBox>Mobile Phone Missing</GreyBox>}</div>
                        </Grid>
                    </Grid>
                    {user.profile.userRole === "store" || user.profile.sharedAccount ? (
                        ""
                    ) : (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <strong>Street:</strong>
                                </Grid>
                                <Grid item xs={10}>
                                    {user.profile.streetAddress ? user.profile.streetAddress : <GreyBox>Address Missing</GreyBox>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <strong>City:</strong>
                                </Grid>
                                <Grid item xs={2}>
                                    {user.profile.city ? user.profile.city : <GreyBox>City Missing</GreyBox>}
                                </Grid>
                                <Grid item xs={2}>
                                    <strong>State:</strong>
                                </Grid>
                                <Grid item xs={2}>
                                    {user.profile.state ? user.profile.state : <GreyBox>State Missing</GreyBox>}
                                </Grid>
                                <Grid item xs={2}>
                                    <strong>Zip:</strong>
                                </Grid>
                                <Grid item xs={2}>
                                    {user.profile.zipCode ? user.profile.zipCode : <GreyBox>Zip Code Missing</GreyBox>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <strong>Country:</strong>
                                </Grid>
                                <Grid item xs={10}>
                                    {user.profile.countryCode ? Countries.getName(user.profile.countryCode, "en", { select: "official" }) : <GreyBox>Country Missing</GreyBox>}
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Box p={1}>
                        <Divider variant='middle' />
                    </Box>
                    <small>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <strong>Created:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                {format(new Date(user.created), "PPP pp")}
                            </Grid>
                            <Grid item xs={2}>
                                <strong>Activated:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                {user.activated ? format(new Date(user.activated), "PPP pp") : <GreyBox>User is not activated.</GreyBox>}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <strong>Last Login:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                {user.lastLogin ? format(new Date(user.lastLogin), "PPP pp") : <GreyBox>User has not logged in.</GreyBox>}
                            </Grid>
                            <Grid item xs={2}>
                                <strong>Last Password Change:</strong>
                            </Grid>
                            <Grid item xs={4}>
                                {format(new Date(user.passwordChanged), "PPP pp")}
                            </Grid>
                        </Grid>
                    </small>

                    <UserDetailMenuBar page='details' menu={adminBtns} />
                </>
            )}
            <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                <>{toastContent}</>
            </Snackbar>

            <Dialog open={passwordRecoveryModal} onClose={closePasswordRecoveryModal} aria-labelledby='passwordrecoveryform-dialog-title' maxWidth='md' fullWidth>
                <DialogTitle id='passwordrecoveryform-dialog-title'>Reset Default Recovery Question</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <p>This will reset the user's recovery question and answer credentials to a default listed below:</p>
                            <div>
                                <strong>Default Password Recovery Question</strong>
                            </div>
                            <hr />
                            <p>
                                <strong>Question: </strong>Who is Bruce Wayne?
                            </p>
                            <p>
                                <strong>Answer: </strong>Batman
                            </p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePasswordRecoveryModal} color='primary'>
                        Cancel
                    </Button>
                    <RedButton variant='contained' onClick={() => acctLifecycleAction("changeRecoveryQuestion", user)}>
                        Reset to Default
                    </RedButton>
                </DialogActions>
            </Dialog>
            <Dialog open={passwordResetModal} onClose={closePasswordResetModal} aria-labelledby='passwordresetform-dialog-title' maxWidth='sm' fullWidth>
                <DialogTitle id='passwordresetform-dialog-title'>Reset Password</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <p>This will display a temporary password on the profile screen.</p>
                            <BlueButton color='inherit' onClick={() => acctLifecycleAction("expirePassword", user)}>
                                <FontAwesomeIcon icon={faRedo} />
                                &nbsp;Get Temp Password
                            </BlueButton>
                        </Grid>
                        <Grid item xs={2}>
                            <Divider variant='middle' orientation='vertical' />
                        </Grid>
                        <Grid item xs={5}>
                            <p>This will send a password reset email to the user.</p>
                            <PurpleButton color='inherit' onClick={() => acctLifecycleAction("resetPassword", user)}>
                                <FontAwesomeIcon icon={faKey} />
                                &nbsp;Send Password Reset
                            </PurpleButton>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePasswordResetModal} color='primary'>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={messageUserModal} onClose={closeMessageUserModal} aria-labelledby='msguserform-dialog-title' maxWidth='md' fullWidth>
                <DialogTitle id='msguserform-dialog-title'>Send Message</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <strong>Send to:</strong>
                        </Grid>
                        <Grid item xs={8}>
                            {user.profile.displayName}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <strong>Email:</strong>
                        </Grid>
                        <Grid item xs={8}>
                            {user.profile.email} {user.profile.secondEmail ? <>, {user.profile.secondEmail}</> : ""}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <strong>Subject:</strong>
                        </Grid>
                        <Grid item xs={8}>
                            <form noValidate autoComplete='off'>
                                <TextField variant='outlined' name='subject' margin='dense' fullWidth value={usrMessage.subject} onChange={subChange} id='msgUserTextArea' placeholder='Subject' />
                            </form>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <strong>Message:</strong>
                        </Grid>
                        <Grid item xs={12}>
                            <form noValidate autoComplete='off'>
                                <TextField
                                    multiline
                                    rows={5}
                                    fullWidth
                                    variant='outlined'
                                    name='subject'
                                    margin='dense'
                                    value={usrMessage.message}
                                    onChange={msgChange}
                                    id='msgUserTextArea'
                                    placeholder='Message'
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>NOTE:</strong> Message will be sent from: <strong>SSO Admin</strong> - ssoadmin@focusbrands.com. You will also receive a BBC of the email.
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeMessageUserModal} color='primary'>
                        Cancel
                    </Button>
                    <BlueButton
                        variant='contained'
                        onClick={() => {
                            sendMessage(usrMessage);
                        }}
                        disabled={!usrMessage.subject || !usrMessage.message}>
                        Send Message
                    </BlueButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AdminUserDetailPanel;
