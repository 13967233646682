import { useEffect, useState } from "react";
import { Box, Chip } from "@material-ui/core";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getStores, sortFilterStores } from "../actions";
import { differenceInMinutes } from "date-fns";

import List from "../components/Common/List";
import * as Columns from "../components/Common/DataTableColData";
import StoresTableData from "../components/Stores/StoresTableData";

const Stores = () => {
    let history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const currentAdmin = useSelector((state) => state.user.usable);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const pagetitle = "Stores";
    const localstoragekey = "matrixstorelistview";
    const listitems = useSelector((state) => state.stores.usablelist);
    // name from columnTemplates
    const defaultView = Columns.storesDefaultColumns;
    const defaultColumns = Columns.storesAvailableColumns;
    const [headerRow, setHeaderRow] = useState(
        <div className='table-header' style={{ fontSize: "1.2rem" }}>
            {pagetitle}
        </div>
    );
    const [filter, setfilter] = useState({});
    const resetList = () => {
        if (!_.isEmpty(filter)) {
            dispatch(sortFilterStores({ filters: filter }));
        } else {
            dispatch(getStores(token));
        }
    };
    const filterSortList = (filterObj) => {
        dispatch(sortFilterStores(filterObj));
    };
    const parselistitems = (items) => {
        return items;
    };
    useEffect(() => {
        if (selectedRow) {
            history.push(`/stores/${selectedRow.FranchiseID}`);
        }
    }, [selectedRow]);
    useEffect(() => {
        // let _localoktasynctime = localStorage.getItem("oktausersynctime");
        // _localoktasynctime = JSON.parse(_localoktasynctime);
        // let _offsetsynctime = differenceInMinutes(new Date(), new Date(_localoktasynctime.lastran));
        if (selectedColumns && selectedColumns.length >= 1) {
            // <Box p={1} textAlign='center'>
            //     <Chip label={`Last Updated ${_offsetsynctime} minute(s) ago`} color='primary' />
            // </Box>;
            setHeaderRow(
                <Box flexGrow={1}>
                    <span style={{ fontSize: "1.2rem" }}>{pagetitle}</span>
                </Box>
            );
        }
    }, [selectedColumns]);
    useEffect(() => {
        if (!_.isEmpty(currentAdmin)) {
            setfilter({
                Active: {
                    value: "1",
                    matchMode: "equals",
                },
            });
        }
    }, [currentAdmin]);
    return (
        // <List
        //     pagetitle={pagetitle}
        //     localstoragekey={localstoragekey}
        //     listitems={listitems}
        //     selectedRow={selectedRow}
        //     setSelectedRow={setSelectedRow}
        //     selectedColumns={selectedColumns}
        //     setSelectedColumns={setSelectedColumns}
        //     defaultColumns={defaultColumns}
        //     defaultView={defaultView}
        //     listfilter={filter}
        //     headerRow={headerRow}
        //     resetList={resetList}
        //     filterSortList={filterSortList}
        //     parselistitems={parselistitems}
        // />
        <StoresTableData pagetitle={pagetitle} localstoragekey={localstoragekey} listitems={listitems} showSelectCheckbox={false} clickable={true} canExport={true} />
    );
};

export default Stores;
