import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import StoreDetailPanel from "./StoreDetailPanel";
import StoreUsersPanel from "./StoreUsersPanel";
import StoreSalesPanel from "./StoreSalesPanel";
import StoreHierarchyPanel from "./StoreHierarchyPanel";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const StoreDetailTabs = (data) => {
    const { store, admin, showAdminBtns } = data;
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const storeDetailTabs = [
        {
            tab: 0,
            label: "Details",
            navItemDisabled: false,
            component: <StoreDetailPanel store={store} admin={admin} showAdminBtns={showAdminBtns} />,
        },
        {
            tab: 1,
            label: "Users",
            navItemDisabled: store.Active ? false : true,
            component: <StoreUsersPanel store={store} admin={admin} showAdminBtns={showAdminBtns} />,
        },
        {
            tab: 2,
            label: "Hierarchy",
            navItemDisabled: store.Active ? false : true,
            component: <StoreHierarchyPanel store={store} />,
        },
        {
            tab: 3,
            label: "Sales",
            navItemDisabled: store.Active ? (!store.ManualReporting ? true : false) : true,
            component: <StoreSalesPanel store={store} admin={admin} showAdminBtns={showAdminBtns} />,
        },
        { tab: 3, label: "WiseTail", navItemDisabled: true, component: <>WiseTail</> },
    ];
    const changeActiveTab = (event) => {
        setActiveTab(Number(event.currentTarget.dataset.activetab));
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <Tabs orientation='vertical' variant='scrollable' value={activeTab} onChange={changeActiveTab} aria-label='Store Detail Tabs' className={classes.tabs}>
                        {_.map(storeDetailTabs, (tab, i) => {
                            return <Tab key={i} label={<>{tab.label}</>} disabled={tab.navItemDisabled} data-activetab={tab.tab} onClick={(e) => changeActiveTab(e)} />;
                        })}
                    </Tabs>
                </Grid>
                <Grid item xs={11}>
                    <Box style={{ minHeight: "350px" }}>
                        {_.map(storeDetailTabs, (tab, i) => {
                            return (
                                <div key={i} role='tabpanel' hidden={activeTab !== i} id={`vertical-tabpanel-${i}`} aria-labelledby={`vertical-tab-${i}`}>
                                    {activeTab === i && tab.component}
                                </div>
                            );
                        })}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default StoreDetailTabs;
