import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useEffect, useState } from "react";
import UserDetailPanel from "./UserDetailPanel";
import { Grid } from "@material-ui/core";
import UserStoresPanel from "./UserStoresPanel";
import _ from "lodash";
import UserAppsPanel from "./UserAppsPanel";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const UserDetailTabs = (data) => {
    const { user, admin, showAdminBtns, getuserbyid } = data;
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const userDetailTabs = [
        {
            tab: 0,
            label: "Details",
            navItemDisabled: false,
            component: <UserDetailPanel user={user} admin={admin} showAdminBtns={showAdminBtns} getuserbyid={getuserbyid} />,
        },
        {
            tab: 1,
            label: "Stores",
            navItemDisabled: false,
            component: <UserStoresPanel user={user} admin={admin} showAdminBtns={showAdminBtns} getuserbyid={getuserbyid} />,
        },
        {
            tab: 2,
            label: "Apps",
            navItemDisabled: false,
            component: <UserAppsPanel user={user} admin={admin} showAdminBtns={showAdminBtns} getuserbyid={getuserbyid} />,
        },

        //USE FOR NEW APPS if NEEDED
        // { tab: 3, label: "GuestFocus", navItemDisabled: !user.profile.guestRelationsRole, component: <>GuestFocus</> },
        // { tab: 3, label: "WiseTail", navItemDisabled: true, component: <>WiseTail</> },
    ];
    const changeActiveTab = (event) => {
        setActiveTab(Number(event.currentTarget.dataset.activetab));
    };
    useEffect(() => {
        if (user) {
            setActiveTab(0);
        }
    }, [user]);
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <Tabs orientation='vertical' variant='scrollable' value={activeTab} onChange={changeActiveTab} aria-label='User Detail Tabs' className={classes.tabs}>
                        {_.map(userDetailTabs, (tab, i) => {
                            return <Tab key={i} label={<>{tab.label}</>} disabled={tab.navItemDisabled} data-activetab={tab.tab} onClick={(e) => changeActiveTab(e)} />;
                        })}
                    </Tabs>
                </Grid>
                <Grid item xs={11}>
                    <Box style={{ minHeight: "350px" }}>
                        {_.map(userDetailTabs, (tab, i) => {
                            return (
                                <div key={i} role='tabpanel' hidden={activeTab !== i} id={`vertical-tabpanel-${i}`} aria-labelledby={`vertical-tab-${i}`}>
                                    {activeTab === i && tab.component}
                                </div>
                            );
                        })}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserDetailTabs;
