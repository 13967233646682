import { Field, useField } from "formik";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { TextField } from "formik-material-ui";
import { blue, red } from "@material-ui/core/colors";

const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Box);
const SimpleTextField = ({ props, fieldname, fieldtype, fieldlabel, fieldrequired, showFormHelp, fieldHelpText, fieldValidationText, startAdornment, endAdornment }) => {
    const fieldProps = useField(fieldname);
    const [field, meta, helpers] = fieldProps;
    const handleChange = (event) => {
        helpers.setValue(event.target.value);
        helpers.setTouched(true, true);
    };
    return (
        <>
            <Field
                component={TextField}
                name={fieldname}
                type={fieldtype}
                label={<>{fieldlabel}</>}
                onChange={handleChange}
                onBlur={handleChange}
                error={props.errors[fieldname] && props.touched[fieldname] ? true : false}
                fullWidth
                required={fieldrequired}
                variant='outlined'
                margin='dense'
                value={field.value ? field.value : ""}
                InputProps={{ startAdornment: startAdornment, endAdornment: endAdornment }}
            />
            {fieldValidationText ? fieldValidationText : ""}
            {showFormHelp ? (
                <BlueBox p={1} style={{ borderRadius: "5px" }}>
                    <small>{fieldHelpText}</small>
                </BlueBox>
            ) : (
                ""
            )}
        </>
    );
};

export default SimpleTextField;
