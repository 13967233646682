import { combineReducers } from "redux";
import currentUserReducer from "./currentUserReducer";
import requestsReducer from "./requestsReducer";
import appsReducer from "./appsReducer";
import storesReducer from "./storesReducer";
import brandsReducer from "./brandsReducer";
import rolesReducer from "./rolesReducer";
import matrixusersReducer from "./matrixusersReducer";
import focusbrandsusersReducer from "./focusbrandsusersReducer";
import matrixsigninsReducer from "./matrixsigninsReducer";
import focusbrandssigninsReducer from "./focusbrandssigninsReducer";
import adminrolesReducer from "./adminrolesReducer";
import adminsReducer from "./adminsReducer";
import helpReducer from "./helpReducer";

const rootReducer = combineReducers({
    user: currentUserReducer,
    requests: requestsReducer,
    brands: brandsReducer,
    roles: rolesReducer,
    admins: adminsReducer,
    adminroles: adminrolesReducer,
    help: helpReducer,
    apps: appsReducer,
    stores: storesReducer,
    matrixusers: matrixusersReducer,
    focusbrandsusers: focusbrandsusersReducer,
    matrixsignins: matrixsigninsReducer,
    focusbrandssignins: focusbrandssigninsReducer,
});

export default rootReducer;
