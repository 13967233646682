import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-duotone-svg-icons";
import _ from "lodash";
const LocationIcons = ({ slug, label }) => {
    const [display, setdisplay] = useState("");
    let _location;
    if (_.isArray(slug)) {
        _location = slug[0];
    } else {
        _location = slug;
    }
    useEffect(() => {
        if (_location) {
            switch (_location) {
                case "domestic":
                    setdisplay(
                        <>
                            <span className='fa-lg'>
                                <span className='fa-layers fa-fw'>
                                    <FontAwesomeIcon icon={faSquare} color='white' />
                                    <FontAwesomeIcon title={"Domestic"} style={{ color: "A52031" }} icon={["fad", "flag-usa"]} transform='shrink-5' />
                                </span>
                            </span>
                            {label ? <span>&nbsp;Domestic</span> : ""}
                        </>
                    );
                    break;
                case "international":
                    setdisplay(
                        <>
                            <span className='fa-lg'>
                                <span className='fa-layers fa-fw'>
                                    <FontAwesomeIcon icon={faSquare} color='white' />
                                    <FontAwesomeIcon title={"International"} style={{ color: "A52031" }} icon={["fad", "globe"]} transform='shrink-5' />
                                </span>
                            </span>
                            {label ? <span>&nbsp;International</span> : ""}
                        </>
                    );
                    break;
                case "not set":
                    setdisplay(
                        <>
                            <span className='fa-lg'>
                                <span className='fa-layers fa-fw'>
                                    <FontAwesomeIcon icon={faSquare} color='white' />
                                    <FontAwesomeIcon title={"NOT SET"} style={{ color: "red" }} icon={["fad", "exclamation-triangle"]} transform='shrink-5' />
                                </span>
                            </span>
                            {label ? <>&nbsp;NOT SET</> : ""}
                        </>
                    );
                    break;
                default:
                    setdisplay(
                        <>
                            <span className='fa-lg'>
                                <span className='fa-layers fa-fw'>
                                    <FontAwesomeIcon icon={faSquare} color='white' />
                                    <FontAwesomeIcon title={"All"} style={{ color: "A52031" }} icon={["fad", "users"]} transform='shrink-5' />
                                </span>
                            </span>
                            {label ? <span>&nbsp;All</span> : ""}
                        </>
                    );
                    break;
            }
        }
    }, [_location]);
    return display;
};

export default LocationIcons;
