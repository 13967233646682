import { useEffect, useState } from "react";
import { Box, Button, Chip, Paper, Snackbar } from "@material-ui/core";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getStores, getUsers, sortFilterMatrixUsers } from "../../actions";
import { blue, green, grey, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { differenceInMinutes } from "date-fns";
import MuiAlert from "@material-ui/lab/Alert";

import List from "../Common/List";
import * as Columns from "../Common/DataTableColData";
import axios from "axios";
import UsersTableData from "../User/UsersTableData";
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreenButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Button);
const StoreUsersPanel = (data) => {
    const { store, admin, showAdminBtns } = data;
    let history = useHistory();
    let { id } = useParams();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const currentAdmin = useSelector((state) => state.user.usable);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [storeUsers, setStoreUsers] = useState([]);
    const pagetitle = "Assigned Users";
    const localstoragekey = "matrixuserlistview";
    const users = useSelector((state) => state.matrixusers.untouchedlist);
    const brands = useSelector((state) => state.brands.untouchedlist);
    const [storeBrand, setStoreBrand] = useState();
    const [modifyUsers, setModifyUsers] = useState(false);
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [nonAssignedUsers, setNonAssignedUsers] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    let source = axios.CancelToken.source();
    const defaultViewColumns = Columns.usersDefaultColumns;
    const availableColumns = Columns.usersAvailableColumns;
    const [headerRow, setHeaderRow] = useState(
        <div className='table-header' style={{ fontSize: "1.2rem" }}>
            {pagetitle}
        </div>
    );
    const [footerRow, setFooterRow] = useState("");
    const [filter, setfilter] = useState({});
    const resetList = () => {
        if (filter) {
            dispatch(sortFilterMatrixUsers({ filters: filter }));
        } else {
            dispatch(getUsers(token));
        }
    };
    const filterSortList = (filterObj) => {
        dispatch(sortFilterMatrixUsers(filterObj));
    };
    const parselistitems = (items) => {
        let _filtereditems;
        if (items && currentAdmin && _.includes(["super"], currentAdmin.profile.matrixAdminRole)) {
            _filtereditems = _.compact(
                _.map(items, (user, i) => {
                    if (!user.profile.matrixTestAccount) {
                        return user;
                    }
                })
            );
        } else {
            _filtereditems = items;
        }
        return _filtereditems;
    };
    const modifyStoreUsers = (selectedUsers) => {
        console.log(`Performing: ${modifyUsers}`);
        _.each(selectedUsers, (id, i) => {
            let _user = _.find(users, { id });
            if (!_user.profile.sharedAccount) {
                let _updatedStores;
                if (modifyUsers === "add") {
                    _updatedStores = _.concat(_user.profile.storeGUIDs, store.FranchiseID);
                } else if (modifyUsers === "remove") {
                    _updatedStores = _.without(_user.profile.storeGUIDs, store.FranchiseID);
                }
                let submitdata = { profile: { storeGUIDs: _updatedStores }, id };
                modifyUserStores(submitdata);
            } else {
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        This is a Shared Account and cannot be removed from it's respective store.
                    </Alert>
                );
                setToastOpen(true);
            }
        });
        setModifyUsers(false);
        getUsersToDisplay();
    };
    const modifyUserStores = async (updateduser) => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserstores`, updateduser, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
                cancelToken: source.token,
            })
            .then((res) => {
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                    setTimeout(() => {
                        dispatch(getUsers(token));
                        dispatch(getStores(token));
                    }, 1000);
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            // dispatch(getRequests(token));
            return;
        }
        setToastOpen(false);
        setModifyUsers(false);
        // dispatch(getRequests(token));
    };
    // useEffect(() => {
    //     // customize per list
    //     if (!_.isEmpty(selectedRow) && modifyUsers) {
    //         console.log("selected users", selectedRow);
    //     } else if (!_.isEmpty(selectedRow) && !modifyUsers) {
    //         history.push(`/users/${selectedRow.id}`);
    //     } else {
    //         history.push(`/stores/${store.FranchiseID}`);
    //     }
    // }, [selectedRow, modifyUsers]);
    const getUsersToRemove = () => {
        setDisplayedUsers([]);
        let _assignedUsers = _.compact(
            _.map(users, (user, i) => {
                if (_.includes(user.profile.storeGUIDs, store.FranchiseID)) {
                    return user;
                }
            })
        );
        setDisplayedUsers(_assignedUsers);
    };
    const getUsersToAdd = () => {
        setDisplayedUsers([]);
        let _nonassignedbrandusers = _.compact(
            _.map(users, (user, i) => {
                if (_.includes(user.profile.assocBrands, storeBrand.slug) && _.includes(user.profile.location, storeBrand.location)) {
                    return user;
                }
            })
        );
        setDisplayedUsers(_nonassignedbrandusers);
    };
    const getUsersToDisplay = () => {
        setDisplayedUsers([]);
        let _assignedUsers = _.compact(
            _.map(users, (user, i) => {
                if (_.includes(user.profile.storeGUIDs, store.FranchiseID)) {
                    return user;
                }
            })
        );
        setDisplayedUsers(_assignedUsers);
    };
    useEffect(() => {
        if (storeBrand) {
            getUsersToDisplay();
        }
    }, [users, store, storeBrand]);
    useEffect(() => {
        if (selectedColumns && selectedColumns.length >= 1) {
            setHeaderRow(
                <Box flexGrow={1}>
                    <span style={{ fontSize: "1.2rem" }}>{pagetitle}</span>
                </Box>
            );
        }
    }, [selectedColumns]);
    useEffect(() => {
        if (!_.isEmpty(currentAdmin)) {
            setfilter({});
        }
    }, [currentAdmin]);
    useEffect(() => {
        setStoreBrand(_.find(brands, { frm_name: store.Brand }));
    }, [brands, store]);
    return (
        <>
            <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                <>{toastContent}</>
            </Snackbar>
            {modifyUsers === "add" || modifyUsers === "remove" ? (
                <>
                    <Box display='flex' component={Paper} py={2}>
                        <Box px={1}>
                            <GreyButton
                                variant='contained'
                                onClick={() => {
                                    setSelectedRow(null);
                                    setModifyUsers(false);
                                    getUsersToDisplay();
                                }}>
                                Cancel
                            </GreyButton>
                        </Box>
                        <Box px={1}>
                            {modifyUsers === "add" ? (
                                <BlueButton variant='contained' onClick={() => modifyStoreUsers(selectedRow)} disabled={_.isEmpty(selectedRow)}>
                                    Add User(s)
                                </BlueButton>
                            ) : (
                                <RedButton variant='contained' onClick={() => modifyStoreUsers(selectedRow)} disabled={_.isEmpty(selectedRow)}>
                                    Remove User(s)
                                </RedButton>
                            )}
                        </Box>
                    </Box>
                    {/* <List
                        pagetitle={pagetitle}
                        localstoragekey={localstoragekey}
                        listitems={modifyUsers === "add" ? nonAssignedUsers : assignedUsers}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        selectedColumns={selectedColumns}
                        setSelectedColumns={setSelectedColumns}
                        defaultColumns={availableColumns}
                        defaultView={defaultViewColumns}
                        listfilter={filter}
                        headerRow={headerRow}
                        footerRow={footerRow}
                        resetList={resetList}
                        filterSortList={filterSortList}
                        parselistitems={parselistitems}
                        selectionRow={true}
                    /> */}
                    {displayedUsers ? (
                        <UsersTableData
                            pagetitle={pagetitle}
                            localstoragekey={localstoragekey}
                            listitems={displayedUsers}
                            showSelectCheckbox={true}
                            clickable={false}
                            setSelectedItems={setSelectedRow}
                            selectedItems={selectedRow}
                        />
                    ) : (
                        "Loading..."
                    )}
                </>
            ) : (
                <>
                    <Box display='flex' component={Paper} py={2}>
                        <Box px={1}>
                            <BlueButton
                                variant='contained'
                                onClick={() => {
                                    getUsersToAdd();
                                    setModifyUsers("add");
                                    setSelectedRow(null);
                                }}>
                                Add User(s)
                            </BlueButton>
                        </Box>
                        <Box px={1}>
                            <RedButton
                                variant='contained'
                                onClick={() => {
                                    getUsersToRemove();
                                    setModifyUsers("remove");
                                    setSelectedRow(null);
                                }}>
                                Remove User(s)
                            </RedButton>
                        </Box>
                    </Box>

                    {/* <List
                        pagetitle={pagetitle}
                        localstoragekey={localstoragekey}
                        listitems={assignedUsers}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        selectedColumns={selectedColumns}
                        setSelectedColumns={setSelectedColumns}
                        defaultColumns={availableColumns}
                        defaultView={defaultViewColumns}
                        listfilter={filter}
                        headerRow={headerRow}
                        footerRow={footerRow}
                        resetList={resetList}
                        filterSortList={filterSortList}
                        parselistitems={parselistitems}
                    /> */}
                    {displayedUsers ? <UsersTableData pagetitle={pagetitle} localstoragekey={localstoragekey} listitems={displayedUsers} clickable={true} /> : "Loading..."}
                </>
            )}
        </>
    );
};

export default StoreUsersPanel;
