const config = {
    oidc: {
        clientId: process.env.REACT_APP_OKTA_CLIENTID,
        issuer: process.env.REACT_APP_OKTA_ISSUER,
        redirectUri: `${window.location.origin}/implicit/callback`,
        scopes: ["openid", "profile", "email"],
        pkce: true,
    },
};
export default config;
