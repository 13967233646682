import _ from "lodash";
import Fuse from "fuse.js";
const initialState = {
    untouchedlist: null,
    usablelist: null,
};
const adminrolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ADMROLES":
            return { untouchedlist: action.payload, usablelist: action.payload };
        default:
            return state;
    }
};
export default adminrolesReducer;
