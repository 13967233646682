import _ from "lodash";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppBar, Box, Breadcrumbs, Divider, Grid, Snackbar, Tab, Tabs, Typography } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { TabPanel } from "@material-ui/lab";
import UserDetailTabs from "./UserDetailTabs";
import { blue, green } from "@material-ui/core/colors";
import UsersBreadcrumb from "./UsersBreadcrumb";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const GreenBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[600]),
        backgroundColor: green[600],
        "&:hover": {
            backgroundColor: green[700],
            color: theme.palette.getContrastText(green[700]),
        },
        padding: "5px",
        borderRadius: "5px",
    },
}))(Box);
const StyledLink = styled(Link)({
    color: "var(--text-color)",
    textDecoration: "none",
});
const UserDetailLayout = () => {
    let { id } = useParams();
    const currentAdmin = useSelector((state) => state.user.untouched);
    const { oktaAuth, authState } = useOktaAuth();
    const [currentUser, setCurrentUser] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const [showAdminBtns, setShowAdminBtns] = useState(false);
    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const getuserbyid = async (id) => {
        const token = oktaAuth.getAccessToken();
        axios
            .get(
                `${process.env.REACT_APP_APIHOST}/api/getuserbyid`,

                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        id,
                    },
                }
            )
            .then((res) => {
                if (res.data.success) {
                    document.title = `User: ${res.data.user.profile.displayName}`;
                    setCurrentUser(res.data.user);
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };

    useEffect(() => {
        if (!currentUser && id) {
            getuserbyid(id);
        }
    }, [id]);
    useEffect(() => {
        const allowedRoles = ["super", "admin", "fbc", "appadmin", "helpdesk"];
        if (currentAdmin && _.includes(allowedRoles, currentAdmin.profile.matrixAdminRole)) {
            setShowAdminBtns(true);
        }
    }, [currentAdmin]);
    return (
        <>
            {currentUser && currentAdmin ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <UsersBreadcrumb item={currentUser} />
                        <Box py={1}>
                            <Divider />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex'>
                            <Box mr={1}>
                                {currentUser.profile.sharedAccount ? (
                                    <BlueBox>
                                        <Typography component='span' gutterBottom>
                                            Shared Account
                                        </Typography>
                                    </BlueBox>
                                ) : currentUser.profile.matrixTestAccount ? (
                                    <GreenBox>
                                        <Typography component='span' gutterBottom>
                                            Test Account
                                        </Typography>
                                    </GreenBox>
                                ) : (
                                    ""
                                )}
                            </Box>
                            <Box flexGrow={1}>
                                <Typography variant='h5' component='h1' gutterBottom>
                                    {currentUser.profile.displayName} - {currentUser.profile.login}
                                </Typography>
                            </Box>
                            <Box p={1}></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p={2}>
                            <UserDetailTabs user={currentUser} admin={currentAdmin} showAdminBtns={showAdminBtns} getuserbyid={getuserbyid} />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                ""
            )}
            <Snackbar open={toastOpen} autoHideDuration={2000} onClose={handleToastClose}>
                <>{toastContent}</>
            </Snackbar>
        </>
    );
};

export default UserDetailLayout;
