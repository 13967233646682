import _ from "lodash";
import Fuse from "fuse.js";
const initialState = {
    untouchedlist: null,
    usablelist: null,
};
const appsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_APPS":
            return { untouchedlist: action.payload, usablelist: action.payload };
        case "SORT_FILTER_APPS":
            let _items = state.usablelist;
            if (!_.isEmpty(action.payload.filters)) {
                //filtering items
                let _fields = _.keys(action.payload.filters);
                if (_fields && _fields.length >= 1) {
                    const fuseOptions = {
                        keys: _fields,
                    };
                    let _combinekeysvalues = _.map(_fields, (item, i) => {
                        return {
                            [item]: `${action.payload.filters[item].matchMode === "equals" ? "'" : action.payload.filters[item].matchMode === "startsWith" ? "^" : ""}${
                                action.payload.filters[item].value
                            }`,
                        };
                    });
                    const fuse = new Fuse(state.untouchedlist, fuseOptions);
                    const results = fuse.search({ $and: _combinekeysvalues });
                    _items = _.map(results, (result, i) => {
                        return result.item;
                    });
                }
            } else if (!_.isEmpty(action.payload.sortField)) {
                //sorting items
                _items = _.orderBy(state.usablelist, [action.payload.sortField], [action.payload.sortDirection]);
            } else {
                _items = state.untouchedlist;
            }
            // console.log(_items);
            return { ...state, usablelist: _items };
        default:
            return state;
    }
};
export default appsReducer;
