import { useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { faCaretDown, faCaretRight, faLevelUp, faTextSize } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Collapse, Divider, Hidden, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
const StyledLink = styled(Link)({
    color: "var(--text-color)",
    textDecoration: "none",
});

const SideNavItem = ({ navitem, open, darkMode }) => {
    const [navOpen, setNavOpen] = useState(true);
    const handleClick = () => {
        setNavOpen(!navOpen);
    };
    return (
        <>
            {navitem && navitem.children ? (
                <>
                    <ListItem button onClick={handleClick} title={navitem.title}>
                        <ListItemIcon>
                            <Box m={1}>
                                {navitem.icon} <Hidden xsDown> {open ? "" : navOpen ? <FontAwesomeIcon icon={faCaretDown} fixedWidth /> : <FontAwesomeIcon icon={faCaretRight} fixedWidth />}</Hidden>
                            </Box>
                        </ListItemIcon>
                        <ListItemText primary={navitem.label} />
                        {navOpen ? <FontAwesomeIcon icon={faCaretDown} fixedWidth /> : <FontAwesomeIcon icon={faCaretRight} fixedWidth />}
                    </ListItem>
                    <Collapse in={navOpen} timeout='auto' unmountOnExit>
                        <Box bgcolor={darkMode ? "primary.dark" : "primary.light"}>
                            <List component='div' disablePadding style={{ marginLeft: open ? ".75rem" : "0px" }}>
                                {_.map(navitem.children, (item, i) => {
                                    return <SideNavItem key={i} navitem={item} open={open} />;
                                })}
                                <Divider />
                            </List>
                        </Box>
                    </Collapse>
                </>
            ) : navitem && !navitem.children ? (
                <>
                    <StyledLink to={navitem.to}>
                        <ListItem button title={navitem.title}>
                            <ListItemIcon>
                                <Box m={1}>{navitem.icon}</Box>
                            </ListItemIcon>
                            <ListItemText primary={navitem.label} />
                        </ListItem>
                    </StyledLink>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default SideNavItem;
