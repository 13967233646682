import { Box, Grid, Paper, Typography } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useHistory, useParams } from "react-router-dom";

const HelpPage = () => {
    let { slug } = useParams();
    let helpitems = useSelector((state) => state.help.usablelist);
    const [pageContent, setPageContent] = useState("");
    useEffect(() => {
        if (slug && !_.isEmpty(helpitems)) {
            let _pagecontent = _.find(helpitems, { slug, active: 1 });
            console.log(_pagecontent);
            setPageContent(_pagecontent);
        }
    }, [slug, helpitems]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={1}>
                            <Typography component='h1' variant='h4'>
                                {pageContent.name}
                            </Typography>
                        </Box>
                        <Box p={1} mx={2}>
                            {parse(pageContent.content)}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default HelpPage;
