import _ from "lodash";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppCardDisplay from "../Common/AppCardDisplay";
import { Box, Button, Divider, Grid, Snackbar } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import MuiAlert from "@material-ui/lab/Alert";
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const UserAppsPanel = (data) => {
    const { user, admin, showAdminBtns, getuserbyid } = data;
    const { oktaAuth, authState } = useOktaAuth();
    const [userApps, setUserApps] = useState([]);
    const [display, setdisplay] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    // SHOW ADD/REMOVE SOURCE ACCESS
    const [displaySourceBtn, setDisplaySourceBtn] = useState(false);
    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    const getUserApps = (userguid) => {
        const token = oktaAuth.getAccessToken();
        axios
            .get(
                `${process.env.REACT_APP_APIHOST}/api/getmyapps`,

                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        userguid,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setUserApps(res.data.apps);
                } else {
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const addToSource = () => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/addusertogroup`,
                { groupid: "00g2avdqf9IKJmd9p2p7", userid: user.id, name: user.profile.displayName, groupname: "The Source" },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );

                    setTimeout(() => {
                        getuserbyid(user.id);
                    }, 2500);
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const removeFromSource = () => {
        const token = oktaAuth.getAccessToken();
        axios
            .post(
                `${process.env.REACT_APP_APIHOST}/api/removeuserfromgroup`,
                { groupid: "00g2avdqf9IKJmd9p2p7", userid: user.id, name: user.profile.displayName, groupname: "The Source" },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );

                    setTimeout(() => {
                        getuserbyid(user.id);
                    }, 2500);
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    useEffect(() => {
        if (user) {
            getUserApps(user.id);
        }
    }, [user]);
    useEffect(() => {
        if (!_.isEmpty(userApps)) {
            let _appspagecontent = _.compact(
                _.map(userApps, (app, i) => {
                    if (!app.hidden) {
                        return (
                            <Grid key={i} item>
                                <AppCardDisplay app={app} />
                            </Grid>
                        );
                    }
                })
            );
            setdisplay(_appspagecontent);
        } else {
            setdisplay(
                <Grid item xs={12}>
                    No Apps Assigned
                </Grid>
            );
        }
        const _existingSourceAccess = _.find(userApps, { appInstanceId: "0oa1gnd722LdHGuuI2p7" });
        if (_existingSourceAccess) {
            //show remove from source btn
            setDisplaySourceBtn("remove");
        } else {
            //show add to source btn
            setDisplaySourceBtn("add");
        }
    }, [userApps]);
    return (
        <>
            <Grid container spacing={2}>
                <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                    <>{toastContent}</>
                </Snackbar>
                <Grid item xs={12}>
                    {displaySourceBtn === "add" ? (
                        <BlueButton
                            variant='contained'
                            size='small'
                            onClick={() => {
                                addToSource();
                            }}>
                            Add Source Access
                        </BlueButton>
                    ) : displaySourceBtn === "remove" ? (
                        <RedButton
                            variant='contained'
                            size='small'
                            onClick={() => {
                                removeFromSource();
                            }}>
                            Remove Source Access
                        </RedButton>
                    ) : (
                        ""
                    )}
                    <Box p={1}>
                        <Divider />
                    </Box>
                </Grid>

                {display}
            </Grid>
        </>
    );
};

export default UserAppsPanel;
