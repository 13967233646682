import React, { useCallback, useEffect, forwardRef, useRef, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTable, usePagination, useRowSelect, useSortBy, useFilters } from "react-table";
import _ from "lodash";
import { matchSorter } from "match-sorter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown, faSort, faQuestionSquare, faList, faThList, faStepBackward, faBackward, faForward, faStepForward } from "@fortawesome/pro-duotone-svg-icons";
import { Box, Button, Grid, Tooltip, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Input, Chip, TablePagination } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import { MultiSelect } from "primereact/multiselect";
import BrandIcons from "./BrandIcons";
import ExportToExcel from "./ExportToExcel";
import { blue } from "@material-ui/core/colors";
import { Select } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { format } from "date-fns";
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const IndeterminateCheckbox = forwardRef(({ indeterminate, idfield, pageslug, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;
    const { row } = { ...rest };

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    if (row) {
        return (
            <div className='custom-control custom-checkbox'>
                <input type='checkbox' id={`${row.original[idfield]}-checkbox`} className='custom-control-input' ref={resolvedRef} {...rest} disabled={row.original.ruleselected} />
                <label className='custom-control-label' htmlFor={`${row.original[idfield]}-checkbox`}>
                    {row.original.ruleselected ? (
                        <>
                            <span style={{ cursor: "pointer" }} id='brandHelpIcon'>
                                &nbsp;
                                <FontAwesomeIcon icon={faQuestionSquare} />
                            </span>
                        </>
                    ) : (
                        ""
                    )}
                </label>
            </div>
        );
    } else {
        return (
            <div className='custom-control custom-checkbox'>
                <input type='checkbox' id={`${pageslug}-header-checkbox`} className='custom-control-input' ref={resolvedRef} {...rest} />
                <label className='custom-control-label' htmlFor={`${pageslug}-header-checkbox`}></label>
            </div>
        );
    }
});

const DataTable = (props) => {
    const {
        pagetitle,
        localstoragekey,
        columns,
        listitems,
        createnew,
        clickable,
        canExport,
        excelFileName,
        selectAllEnabled,
        showSelectCheckbox,
        selectedItems,
        setSelectedItems,
        idfield,
        pageslug,
        filterData,
        skipPageResetRef,
    } = props;
    let history = useHistory();
    const [tableDensity, setTableDensity] = useState("small");
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [tableExportData, setTableExportData] = useState([]);
    // const [filteredRows, setFilteredRows] = useState([]);
    const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
        const count = preFilteredRows.length;

        return (
            <TextField
                value={filterValue || ""}
                fullWidth
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} items`}
            />
        );
    };
    const fuzzyTextFilterFn = (rows, id, filterValue) => {
        return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
    };
    fuzzyTextFilterFn.autoRemove = (val) => !val;

    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
                });
            },
            multiple: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined ? filterValue.includes(rowValue) : true;
                });
            },
        }),
        []
    );
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    const getRowId = useCallback((row) => {
        return row[idfield];
    }, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        allColumns,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, filters, sortBy, selectedRowIds },
    } = useTable(
        {
            columns: selectedColumns,
            data: listitems,
            initialState: {
                pageIndex: 0,
                pageSize: 25,
                filters: filterData,
                selectedRowIds: selectedItems ? selectedItems : false,
            },
            defaultColumn,
            filterTypes,
            getRowId,
            setSelectedItems,
            autoResetSelectedRows: skipPageResetRef ? !skipPageResetRef.current : true,
        },
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (showSelectCheckbox) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        disableSortBy: true,
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => {
                            if (selectAllEnabled) {
                                return (
                                    <>
                                        <IndeterminateCheckbox pageslug={pageslug} {...getToggleAllRowsSelectedProps()} />
                                    </>
                                );
                            } else {
                                return null;
                            }
                        },
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => {
                            const rowProps = row.getToggleRowSelectedProps();
                            // if (row.original.manualselected) {
                            //     delete rowProps.checked;
                            //     rowProps.defaultChecked = true;
                            // } else if (row.original.autoselected && !row.original.manualselected) {
                            //     rowProps.checked = true;
                            // }
                            return (
                                <>
                                    <IndeterminateCheckbox idfield={idfield} row={row} {...rowProps} />
                                </>
                            );
                        },
                    },
                    ...columns,
                ]);
            }
        }
    );
    const onColumnToggle = (e) => {
        let _selectedColumns = e.target.value;
        let orderedSelectedColumns = columns.filter((col) => _selectedColumns.some((sCol) => sCol.Header === col.Header));
        if (orderedSelectedColumns.length >= 1) {
            setSelectedColumns(orderedSelectedColumns);
        } else {
            console.log("make sure yes");
            // setSnackbarOpen(true);
            // setSnackbarSeverity("warning");
            // setSnackbarMsg("Please make sure at least one field/column is selected");
        }
    };
    const saveView = (selColumns) => {
        console.info(`saving view for ${pagetitle} list`);
        localStorage.removeItem(localstoragekey);
        localStorage.setItem(localstoragekey, JSON.stringify(selColumns));
        setSelectedColumns(selColumns);
    };
    const checkLocalStorageView = () => {
        // customize per list
        let _matrixlistview = localStorage.getItem(localstoragekey);
        _matrixlistview = JSON.parse(_matrixlistview);
        if (_.isEmpty(_matrixlistview)) {
            // localStorage key doesn't exist
            console.info(`${pagetitle} list view key doesn't exist`);
            let _visibleColumns = _.compact(
                _.map(columns, (col, i) => {
                    if (!col.hidden) {
                        return col;
                    }
                })
            );
            setSelectedColumns(_visibleColumns);
        } else if (_matrixlistview) {
            // localStorage key exists
            console.info(`${pagetitle} list view key exists`);
            setSelectedColumns(_matrixlistview);
        }
    };
    useEffect(() => {
        // console.log(selectedRowIds);
        if (setSelectedItems) {
            let hashedSelected = _.map(selectedRowIds, (selected, id) => {
                return id;
            });
            hashedSelected = _.compact(hashedSelected);
            setSelectedItems(hashedSelected);
        }
    }, [selectedRowIds, setSelectedItems]);
    useEffect(() => {
        if (!_.isEmpty(listitems)) {
            checkLocalStorageView();
            // setDefaultView(defaultView);
            // setloading(false);
        }
    }, [listitems]);
    useEffect(() => {
        if (canExport) {
            let _exportdata = _.map(rows, (item, i) => {
                let _expobj = {};
                _.each(columns, (col, n) => {
                    let _key = col.Header;
                    let _val = _.get(item.values, col.id);
                    if (_.isArray(_val)) {
                        _val = _.join(_val, ",");
                    }
                    _.set(_expobj, _key, _val);
                });
                return _expobj;
            });
            setTableExportData(_exportdata);
        }
    }, [rows]);
    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        gotoPage(0);
    };
    const TablePaginationActions = (props) => {
        return (
            <>
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} aria-label='first page'>
                    <FontAwesomeIcon icon={faStepBackward} fixedWidth />
                </Button>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage} aria-label='previous page'>
                    <FontAwesomeIcon icon={faBackward} fixedWidth />
                </Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage} aria-label='next page'>
                    <FontAwesomeIcon icon={faForward} fixedWidth />
                </Button>
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} aria-label='last page'>
                    <FontAwesomeIcon icon={faStepForward} fixedWidth />
                </Button>
            </>
        );
    };
    return (
        <>
            <Paper>
                <Box p={2}>
                    {/* <pre>
                        <code>
                            {JSON.stringify(
                                {
                                    pageIndex,
                                    pageSize,
                                    pageCount,
                                    canNextPage,
                                    canPreviousPage,

                                    // sortBy,
                                    // filters,
                                    // selectedRowIds: selectedRowIds, "selectedFlatRows[].original": selectedFlatRows.map((d) => d.original[idfield]),
                                },
                                null,
                                2
                            )}
                        </code>
                    </pre> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='h5' component='h1'>
                                    {pagetitle} {showSelectCheckbox && selectedItems ? `${selectedItems.length} Selected` : ""}
                                </Typography>
                            </Box>
                            <Box display='flex' style={{ alignItems: "center" }}>
                                <Box px={1}>
                                    {createnew ? (
                                        <HtmlTooltip
                                            title={
                                                <>
                                                    <Typography color='inherit'>Create:</Typography>
                                                    <div>Opens the {createnew.label} Form</div>
                                                </>
                                            }>
                                            <span>
                                                <BlueButton
                                                    size='small'
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => {
                                                        history.push(`${createnew.path}`);
                                                    }}>
                                                    {createnew.label}
                                                </BlueButton>
                                            </span>
                                        </HtmlTooltip>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                                <Box flexGrow={1}></Box>
                                <Box px={1}>
                                    <InputLabel id='datatable-viewable-columns'>Viewable Columns</InputLabel>
                                </Box>
                                <Box>
                                    <Select
                                        labelId='datatable-viewable-columns'
                                        id='datatable-mutiple-chip'
                                        multiple
                                        value={selectedColumns}
                                        onChange={onColumnToggle}
                                        input={<Input id='select-multiple-chip' />}
                                        renderValue={(selected) => (
                                            <div>
                                                {_.map(selected, (value, i) => {
                                                    return <Chip key={i} label={value.Header} color='primary' size='small' style={{ marginRight: "2px" }} />;
                                                })}
                                            </div>
                                        )}>
                                        {_.map(columns, (column, i) => {
                                            return (
                                                <MenuItem key={i} value={column}>
                                                    {column.Header}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Box>
                                <Box px={1}>
                                    <HtmlTooltip
                                        title={
                                            <>
                                                <Typography color='inherit'>Condense Rows:</Typography>
                                                <div>Condense the table rows.</div>
                                            </>
                                        }>
                                        <span>
                                            <Button
                                                size='small'
                                                variant={tableDensity === "small" ? "outlined" : "contained"}
                                                onClick={() => setTableDensity("small")}
                                                disabled={tableDensity === "small" ? true : false}>
                                                <FontAwesomeIcon icon={faThList} title='Condense' size='2x' />
                                            </Button>
                                        </span>
                                    </HtmlTooltip>
                                </Box>
                                <Box px={1}>
                                    <HtmlTooltip
                                        title={
                                            <>
                                                <Typography color='inherit'>Expand Rows:</Typography>
                                                <div>Expand the rows on the table.</div>
                                            </>
                                        }>
                                        <span>
                                            <Button
                                                size='small'
                                                variant={tableDensity === "medium" ? "outlined" : "contained"}
                                                onClick={() => setTableDensity("medium")}
                                                disabled={tableDensity === "medium" ? true : false}>
                                                <FontAwesomeIcon icon={faList} title='Expand' size='2x' />
                                            </Button>
                                        </span>
                                    </HtmlTooltip>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table {...getTableProps()} id='datatable' size={tableDensity}>
                                    <TableHead>
                                        {headerGroups.map((headerGroup) => (
                                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => {
                                                    return (
                                                        // Add the sorting props to control sorting. For this example
                                                        // we can add them into the header props
                                                        <TableCell {...column.getHeaderProps()}>
                                                            {/* Add a sort direction indicator */}
                                                            <div {...column.getSortByToggleProps()} style={{ whiteSpace: "nowrap" }}>
                                                                <span>{column.render("Header")}</span>
                                                                {column.canSort ? (
                                                                    column.isSorted ? (
                                                                        column.isSortedDesc ? (
                                                                            <FontAwesomeIcon icon={faSortDown} fixedWidth />
                                                                        ) : (
                                                                            <FontAwesomeIcon icon={faSortUp} fixedWidth />
                                                                        )
                                                                    ) : (
                                                                        <FontAwesomeIcon icon={faSort} fixedWidth />
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div>{column.canFilter ? column.render("Filter") : null}</div>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableHead>
                                    <TableBody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row);
                                            if (clickable) {
                                                return (
                                                    <TableRow
                                                        {...row.getRowProps()}
                                                        onClick={() => {
                                                            history.push(`/${pageslug}/${row.original[idfield]}`);
                                                        }}
                                                        style={{ cursor: "pointer" }}>
                                                        {row.cells.map((cell) => {
                                                            return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                                                        })}
                                                    </TableRow>
                                                );
                                            } else {
                                                return (
                                                    <TableRow {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                            return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                                                        })}
                                                    </TableRow>
                                                );
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display='flex'>
                                <Box px={1}>
                                    <TablePagination
                                        component='div'
                                        count={rows.length}
                                        rowsPerPageOptions={[10, 25, 50, 100, { value: -1, label: "All" }]}
                                        page={pageIndex}
                                        onChangePage={handleChangePage}
                                        rowsPerPage={pageSize}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Box>
                                <Box flexGrow={1}></Box>
                                <Box px={1}>
                                    <HtmlTooltip
                                        title={
                                            <>
                                                <Typography color='inherit'>Save View:</Typography>
                                                <div>Saves the current selection of columns as a view.</div>
                                            </>
                                        }>
                                        <BlueButton color='inherit' size='small' onClick={() => saveView(selectedColumns)}>
                                            Save View
                                        </BlueButton>
                                    </HtmlTooltip>
                                </Box>
                                <Box px={1}>
                                    <div className='text-right'>
                                        {canExport ? (
                                            <HtmlTooltip
                                                title={
                                                    <>
                                                        <Typography color='inherit'>Export to Excel:</Typography>
                                                        <div>Saves the current view (columns, and filters) to an Excel file.</div>
                                                    </>
                                                }>
                                                <span>
                                                    <ExportToExcel
                                                        filename={excelFileName ? `${excelFileName}_${format(new Date(), "yyyy-MM-dd-HHmmss")}` : `${format(new Date(), "yyyy-MM-dd-HHmmss")}`}
                                                        tabledata={tableExportData}
                                                        elm={"datatable"}
                                                    />
                                                </span>
                                            </HtmlTooltip>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
};

export default DataTable;
