import React from "react";
import _ from "lodash";
import * as XLSX from "xlsx";
import { Button } from "@material-ui/core";

const ExportToExcel = (data) => {
    const { filename, tabledata, elm } = data;
    // const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = "xlsx";

    const exportToXLSX = (type, fn, dl) => {
        const elt = document.getElementById(elm);
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: filename,
        };
        wb.SheetNames.push("MatrixData");
        // console.log(tabledata);
        let ws;
        if (tabledata && tabledata.length >= 1) {
            let rowsOriginalData = _.map(tabledata, (item, i) => {
                return item;
            });
            ws = XLSX.utils.json_to_sheet(rowsOriginalData);
        } else {
            ws = XLSX.utils.table_to_sheet(elt);
        }
        wb.Sheets["MatrixData"] = ws;
        return XLSX.writeFile(wb, fn || `${filename}.` + (type || "xlsx"));
    };

    return (
        <Button size='small' variant='contained' color='primary' onClick={(e) => exportToXLSX(fileExtension)} title='Exported File can take a little while to open.'>
            Export to Excel
        </Button>
    );
};

export default ExportToExcel;
