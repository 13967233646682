import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import _ from "lodash";
import EmailLookupField from "../Fields/EmailLookupField";
import SimpleTextField from "../Fields/SimpleTextField";

import random from "../Common/randomfacts.json";
import countrycodes from "../Common/countrycodes";
import RadioField from "../Fields/RadioField";
import RoleIcons from "./RoleIcons";
import CheckboxField from "../Fields/CheckboxField";
import BrandIcons from "./BrandIcons";
import { cyan } from "@material-ui/core/colors";
import ChoiceField from "../Fields/ChoiceField";
const CyanBox = withStyles((theme) => ({
    root: {
        border: "thin solid",
        borderColor: cyan[500],
        borderRadius: "5px",
    },
}))(Box);
const UserEditorForm = ({ props, showFormHelp, checkContactEmail, lookupEmailValidMsg, setLookupEmailValidMsg }) => {
    const brands = useSelector((state) => state.brands.untouchedlist);
    const roles = useSelector((state) => state.roles.untouchedlist);
    const [showOptionalFields, setShowOptionalFields] = useState(false);
    return (
        <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
                <SimpleTextField
                    props={props}
                    fieldname={"firstName"}
                    fieldtype={"text"}
                    fieldlabel={"First Name"}
                    fieldrequired={true}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>There is only so much I can do to help you out. You're on your own here.</>}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <SimpleTextField
                    props={props}
                    fieldname={"lastName"}
                    fieldtype={"text"}
                    fieldlabel={"Last Name"}
                    fieldrequired={true}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>{_.sample(random.facts)}</>}
                />
            </Grid>
            <Grid item xs={12}>
                <EmailLookupField
                    props={props}
                    checkContactEmail={checkContactEmail}
                    lookupEmailValidMsg={lookupEmailValidMsg}
                    setLookupEmailValidMsg={setLookupEmailValidMsg}
                    email={props.values.email}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>This is the main contact email address for this user account.</>}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <RadioField
                    props={props}
                    fieldname={"userRole"}
                    options={_.orderBy(
                        _.compact(
                            _.map(roles, (role, i) => {
                                if (role.active) {
                                    let _roleObj = {
                                        slug: role.slug,
                                        label: <RoleIcons slug={role.slug} label={true} />,
                                        level: role.level,
                                    };
                                    return _roleObj;
                                }
                            })
                        ),
                        "level",
                        "asc"
                    )}
                    fieldlabel='Role / Audience'
                    fieldrequired={true}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>This determines what kind of content this account has access to. Please choose wisely.</>}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <RadioField
                    props={props}
                    fieldname={"location"}
                    options={[
                        { slug: "domestic", label: "Domestic" },
                        { slug: "international", label: "International" },
                        { slug: "all", label: "Both" },
                    ]}
                    fieldlabel='Location'
                    fieldrequired={true}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>This is based on the location the user should be associated with. An account can be associated with both domestic and international locations.</>}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <CheckboxField
                    props={props}
                    fieldname={"assocBrands"}
                    options={_.orderBy(
                        _.compact(
                            _.map(brands, (brand, i) => {
                                if (props.values.location === "all") {
                                    if (brand.active) {
                                        let _brandObj = {
                                            slug: brand.slug,
                                            label: <BrandIcons slug={brand.frm_name} location={false} label={true} />,
                                        };
                                        return _brandObj;
                                    }
                                } else {
                                    if (brand.active && props.values.location === brand.location) {
                                        let _brandObj = {
                                            slug: brand.slug,
                                            label: <BrandIcons slug={brand.slug} location={props.values.location} label={true} />,
                                        };
                                        return _brandObj;
                                    }
                                }
                            })
                        ),
                        "slug",
                        "asc"
                    )}
                    fieldlabel='Associated Brand(s)'
                    fieldrequired={true}
                    showFormHelp={showFormHelp}
                    fieldHelpText={<>Please choose the brands that the user should be associated with.</>}
                />
                {!props.values.location ? <div>You must first select the location before the list of brands will display.</div> : ""}
            </Grid>
            <Grid item xs={12}>
                <CyanBox p={1}>
                    <Box display='flex'>
                        <Box p={1} flexGrow={1}>
                            <Typography variant='h5' component='h2'>
                                Optional Fields
                            </Typography>
                        </Box>

                        <Box p={1}>
                            {showOptionalFields ? (
                                <Button variant='outlined' onClick={() => setShowOptionalFields(!showOptionalFields)}>
                                    Hide Fields
                                </Button>
                            ) : (
                                <Button variant='outlined' onClick={() => setShowOptionalFields(!showOptionalFields)}>
                                    Show Fields
                                </Button>
                            )}
                        </Box>
                    </Box>
                    {showOptionalFields ? (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <SimpleTextField
                                    props={props}
                                    fieldname={"secondEmail"}
                                    fieldtype={"text"}
                                    fieldlabel={"Secondary Email"}
                                    showFormHelp={showFormHelp}
                                    fieldHelpText={<>{_.sample(random.facts)}</>}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <SimpleTextField
                                    props={props}
                                    fieldname={"primaryPhone"}
                                    fieldtype={"text"}
                                    fieldlabel={"Phone"}
                                    showFormHelp={showFormHelp}
                                    fieldHelpText={<>This should be the main contact phone number for this user account.</>}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <SimpleTextField
                                    props={props}
                                    fieldname={"mobilePhone"}
                                    fieldtype={"text"}
                                    fieldlabel={"Mobile"}
                                    showFormHelp={showFormHelp}
                                    fieldHelpText={<>{_.sample(random.facts)}</>}
                                />
                            </Grid>
                            {props.values.location ? (
                                <>
                                    <Grid item xs={12}>
                                        <SimpleTextField
                                            props={props}
                                            fieldname={"streetAddress"}
                                            fieldtype={"text"}
                                            fieldlabel={"Street"}
                                            showFormHelp={showFormHelp}
                                            fieldHelpText={<>{_.sample(random.facts)}</>}
                                        />
                                    </Grid>
                                    {props.values.location === "international" ? (
                                        <Grid item md={5} xs={12}>
                                            <SimpleTextField
                                                props={props}
                                                fieldname={"city"}
                                                fieldtype={"text"}
                                                fieldlabel={"City"}
                                                showFormHelp={showFormHelp}
                                                fieldHelpText={<>{_.sample(random.facts)}</>}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item md={4} xs={12}>
                                            <SimpleTextField
                                                props={props}
                                                fieldname={"city"}
                                                fieldtype={"text"}
                                                fieldlabel={"City"}
                                                showFormHelp={showFormHelp}
                                                fieldHelpText={<>{_.sample(random.facts)}</>}
                                            />
                                        </Grid>
                                    )}
                                    {props.values.location === "international" ? (
                                        ""
                                    ) : (
                                        <Grid item md={3} xs={12}>
                                            <SimpleTextField
                                                props={props}
                                                fieldname={"state"}
                                                fieldtype={"text"}
                                                fieldlabel={"State"}
                                                showFormHelp={showFormHelp}
                                                fieldHelpText={<>{_.sample(random.facts)}</>}
                                            />
                                        </Grid>
                                    )}
                                    {props.values.location === "international" ? (
                                        <Grid item md={5} xs={12}>
                                            <SimpleTextField
                                                props={props}
                                                fieldname={"zipCode"}
                                                fieldtype={"text"}
                                                fieldlabel={"Zip"}
                                                showFormHelp={showFormHelp}
                                                fieldHelpText={<>{_.sample(random.facts)}</>}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item md={3} xs={12}>
                                            <SimpleTextField
                                                props={props}
                                                fieldname={"zipCode"}
                                                fieldtype={"text"}
                                                fieldlabel={"Mail Code / Zip"}
                                                showFormHelp={showFormHelp}
                                                fieldHelpText={<>{_.sample(random.facts)}</>}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item md={2} xs={12}>
                                        <ChoiceField
                                            props={props}
                                            fieldname={"countryCode"}
                                            options={_.map(countrycodes, (item, i) => {
                                                return { slug: item.code, label: item.label };
                                            })}
                                            fieldvalue={props.values.countryCode}
                                            fieldlabel='Country'
                                            showFormHelp={showFormHelp}
                                            fieldHelpText={<>{_.sample(random.facts)}</>}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                    ) : (
                        ""
                    )}
                </CyanBox>
            </Grid>
        </Grid>
    );
};

export default UserEditorForm;
