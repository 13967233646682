import { useEffect, useState } from "react";
import { Box, Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getStores, sortFilterStores } from "../../actions";
import { differenceInMinutes } from "date-fns";

import List from "./List";
import { blue } from "@material-ui/core/colors";
import StoresTableData from "../Stores/StoresTableData";

const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Box);
const StoresListSelection = ({ props, showFormHelp, fieldHelpText, selectedItems, setSelectedItems, currentAdmin }) => {
    let history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const brands = useSelector((state) => state.brands.untouchedlist);
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const pagetitle = "Stores";
    const localstoragekey = "matrixstorelistview";
    const listitems = useSelector((state) => state.stores.usablelist);
    // name from columnTemplates
    const defaultView = [
        { field: "StoreNumber", name: "StoreNumber", friendlyname: "Store #" },
        { field: "Active", name: "StoreStatus", friendlyname: "Status" },
        { field: "Brand", name: "frmBrands", friendlyname: "Brand" },
        { field: "Location", name: "StoreLocation", friendlyname: "Location" },
        { field: "FranchiseBusinessName", name: "FranchiseName", friendlyname: "Franchise Name" },
    ];
    const defaultColumns = [
        // all available columns
        { field: "FranchiseID", name: "FranchiseID", friendlyname: "FranchiseID" },
        { field: "StoreNumber", name: "StoreNumber", friendlyname: "Store #" },
        { field: "Active", name: "StoreStatus", friendlyname: "Status" },
        { field: "Brand", name: "frmBrands", friendlyname: "Brand" },
        { field: "Location", name: "StoreLocation", friendlyname: "Location" },
        { field: "FranchiseBusinessName", name: "FranchiseName", friendlyname: "Franchise Name" },
        { field: "StoreAddress", name: "StoreAddress", friendlyname: "Address" },
        { field: "StoreCity", name: "StoreCity", friendlyname: "City" },
        { field: "StoreState", name: "StoreState", friendlyname: "State" },
        { field: "PrimaryContact", name: "FranchisePrimaryContact", friendlyname: "Contact" },
    ];
    const [headerRow, setHeaderRow] = useState(
        <div className='table-header' style={{ fontSize: "1.2rem" }}>
            {pagetitle}
        </div>
    );
    const [filter, setfilter] = useState({});
    const resetList = () => {
        if (!_.isEmpty(filter)) {
            dispatch(sortFilterStores({ filters: filter }));
        } else {
            dispatch(getStores(token));
        }
    };
    const filterSortList = (filterObj) => {
        dispatch(sortFilterStores(filterObj));
    };
    const parselistitems = (items) => {
        return items;
    };
    // useEffect(() => {
    //     // customize per list
    //     if (selectedRow) {
    //         setSelectedItems(selectedRow);
    //     } else {
    //         setSelectedItems([]);
    //     }
    // }, [selectedRow]);
    useEffect(() => {
        if (selectedColumns && selectedColumns.length >= 1) {
            // <Box p={1} textAlign='center'>
            //     <Chip label={`Last Updated ${_offsetsynctime} minute(s) ago`} color='primary' />
            // </Box>;
            setHeaderRow(
                <Box flexGrow={1}>
                    <span style={{ fontSize: "1.2rem" }}>{pagetitle}</span>
                </Box>
            );
        }
    }, [selectedColumns]);
    useEffect(() => {
        if (!_.isEmpty(currentAdmin)) {
            let _location = props.values.location;
            let _brands = props.values.assocBrands;
            let _brandfilterarry;
            if (_location === "all") {
                _brandfilterarry = _.map(_brands, (brand, i) => {
                    let _temparry = _.filter(brands, { slug: brand, active: 1 });
                    _temparry = _.map(_temparry, (item, i) => {
                        return item.frm_name;
                    });
                    return _temparry;
                });
                _brandfilterarry = _.flatMap(_brandfilterarry);
            } else {
                _brandfilterarry = _.map(_brands, (brand, i) => {
                    return _.find(brands, { location: _location, slug: brand, active: 1 }).frm_name;
                });
            }
            setfilter({
                Active: {
                    value: "1",
                    matchMode: "equals",
                },
                Brand: {
                    value: _brandfilterarry,
                    matchMode: "in",
                },
            });
        }
    }, [currentAdmin, props.values.location, props.values.assocBrands]);
    return (
        <>
            {/* <List
                pagetitle={pagetitle}
                localstoragekey={localstoragekey}
                listitems={listitems}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                defaultColumns={defaultColumns}
                defaultView={defaultView}
                listfilter={filter}
                headerRow={headerRow}
                resetList={resetList}
                filterSortList={filterSortList}
                parselistitems={parselistitems}
                selectionRow={true}
            /> */}
            <StoresTableData
                pagetitle={pagetitle}
                localstoragekey={localstoragekey}
                listitems={listitems}
                showSelectCheckbox={true}
                clickable={false}
                selectAllEnabled={true}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
            />
            {showFormHelp ? (
                <BlueBox p={1} style={{ borderRadius: "5px" }}>
                    <small>{fieldHelpText}</small>
                </BlueBox>
            ) : (
                ""
            )}
        </>
    );
};

export default StoresListSelection;
