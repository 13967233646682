import { Box, FormControl, FormControlLabel, FormLabel, Radio } from "@material-ui/core";
import { Field, useField } from "formik";
import { RadioGroup } from "formik-material-ui";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { blue, red } from "@material-ui/core/colors";

const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Box);
const RadioField = ({ props, fieldname, options, fieldlabel, fieldrequired, fieldHelpText, showFormHelp }) => {
    const fieldProps = useField(fieldname);
    const [field, meta, helpers] = fieldProps;
    const handleChange = (event) => {
        helpers.setValue(event.target.value);
        helpers.setTouched(true, true);
    };
    return (
        <>
            <FormControl component='fieldset' required={fieldrequired} variant='outlined' margin='dense'>
                <FormLabel component='label'>{fieldlabel}</FormLabel>
                <Field component={RadioGroup} name={fieldname} style={{ marginLeft: "1rem" }}>
                    {_.map(options, (item, i) => {
                        return (
                            <FormControlLabel
                                key={i}
                                className='dense-choice-field'
                                value={item.slug}
                                checked={props.values[fieldname] === item.slug || _.indexOf(props.values[fieldname], item.slug) !== -1 ? true : false}
                                control={<Radio className='dense-choice-field' />}
                                label={item.label}
                            />
                        );
                    })}
                </Field>
            </FormControl>
            {showFormHelp ? (
                <BlueBox p={1} style={{ borderRadius: "5px" }}>
                    <small>{fieldHelpText}</small>
                </BlueBox>
            ) : (
                ""
            )}
        </>
    );
};

export default RadioField;
