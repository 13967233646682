import { useCallback } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import DataTable from "../Common/DataTable";
import BrandIcons from "../Common/BrandIcons";
import { Dropdown } from "primereact/dropdown";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import LocationIcons from "../Common/LocationIcons";
import RoleIcons from "../Common/RoleIcons";
import { format } from "date-fns";
import UserStatusIcons from "../Common/UserStatusIcons";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import DataLoading from "../Common/DataLoading";

const UsersTableData = (data) => {
    const { pagetitle, localstoragekey, listitems, showSelectCheckbox, clickable, setSelectedItems, selectedItems, canExport } = data;
    const brands = useSelector((state) => state.brands.untouchedlist);
    // Cell Data
    const roleCellData = (originalRow, rowIndex) => {
        if (!originalRow.profile.userRole) {
            return "not set";
        } else {
            return originalRow.profile.userRole;
        }
    };
    const locationCellData = (originalRow, rowIndex) => {
        if (!originalRow.profile.location) {
            return "not set";
        } else if (originalRow.profile.location === "all") {
            return "both";
        } else {
            return originalRow.profile.location;
        }
    };
    const storesCellData = (originalRow, rowIndex) => {
        const stores = originalRow.profile.storeGUIDs;
        let num = 0;
        if (stores) {
            num = stores.length;
        }
        return num;
    };
    const guestFocusCellData = (originalRow, rowIndex) => {
        if (!originalRow.profile.guestRelationsRole) {
            return "noaccess";
        } else {
            return true;
        }
    };
    const guestFocusStoresCellData = (originalRow, rowIndex) => {
        let stores = originalRow.profile.guestRelationsAccessList;
        let num = 0;
        if (stores) {
            stores = stores.split(",");
            num = stores.length;
        }
        return num;
    };
    // Cell Templates

    const assocBrandsCell = ({ row, value }) => {
        let _brdarry;
        if (value) {
            if (Array.isArray(value)) {
                // console.log("an array");
                _brdarry = value;
            } else {
                _brdarry = value.split(";");
            }
            return (
                <div style={{ textAlign: "center" }} px={1}>
                    {_.map(_brdarry, (brand, i) => {
                        return <BrandIcons key={i} slug={brand} location={row.original.profile.location} />;
                    })}
                </div>
            );
        } else {
            return "missing";
        }
    };
    const locationTemplate = ({ row, value }) => {
        return (
            <div style={{ textAlign: "center" }}>
                <LocationIcons slug={value} />
            </div>
        );
    };
    const roleTemplate = ({ row, value }) => {
        return (
            <div style={{ textAlign: "center" }}>
                <RoleIcons slug={value} />
            </div>
        );
    };
    const storeCellTemplate = ({ value }) => {
        return <div style={{ textAlign: "center" }}>{value}</div>;
    };
    const statusCellTemplate = ({ value }) => {
        return (
            <div style={{ textAlign: "center" }}>
                <UserStatusIcons status={value} />
            </div>
        );
    };
    const guestFocusCellTemplate = ({ row, value }) => {
        if (value !== "noaccess") {
            const text = row.original.profile.guestRelationsRole;
            const result = text.replace(/([A-Z])/g, " $1");
            const _role = result.charAt(0).toUpperCase() + result.slice(1);
            return (
                <div style={{ textAlign: "center" }}>
                    <FormControlLabel control={<Checkbox checked={value} color='primary' />} label={<small>{_role}</small>} />
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: "center" }}>
                    <small>No Access</small>
                </div>
            );
        }
    };
    const dateTemplate = ({ row, value }) => {
        return <div style={{ textAlign: "center" }}>{format(new Date(value), "MMMM do, yyyy")}</div>;
    };
    // Filters
    const BoolColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        return (
            <>
                <FormControl fullWidth>
                    <Select
                        id='bool-filter-select'
                        style={{ textTransform: "capitalize" }}
                        value={filterValue || ""}
                        fullWidth
                        defaultValue={""}
                        onChange={(e) => {
                            setFilter(e.target.value || undefined);
                        }}>
                        <MenuItem value={""}>All</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={"noaccess"}>No</MenuItem>
                    </Select>
                </FormControl>
            </>
        );
    };
    const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = useMemo(() => {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                options.add(row.values[id]);
            });
            return [...options.values()];
        }, [id, preFilteredRows]);

        // Render a multi-select box
        return (
            <>
                <FormControl fullWidth>
                    <Select
                        id='filter-select'
                        style={{ textTransform: "capitalize" }}
                        value={filterValue || ""}
                        fullWidth
                        defaultValue={""}
                        onChange={(e) => {
                            setFilter(e.target.value || undefined);
                        }}>
                        <MenuItem value={""}>All</MenuItem>
                        {_.map(options, (option, i) => {
                            return (
                                <MenuItem key={i} value={option} style={{ textTransform: "capitalize" }}>
                                    {option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </>
        );
    };
    const SelectBrandFilter = useCallback(
        ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
            // Calculate the options for filtering
            // using the preFilteredRows
            let options = _.compact(
                _.map(brands, (brand, i) => {
                    if (!brand.active && brand.slug !== "all") {
                        return brand;
                    }
                })
            );
            return (
                <>
                    <FormControl fullWidth>
                        {/* <InputLabel id='brand-filter-label' style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            Select Brand
                        </InputLabel> */}
                        <Select
                            labelId='brand-filter-label'
                            id='brand-filter-select'
                            value={filterValue || ""}
                            fullWidth
                            defaultValue={""}
                            onChange={(e) => {
                                let _brand = _.find(options, ["slug", e.target.value]);
                                if (_brand) {
                                    setFilter(_brand.slug || "");
                                } else {
                                    setFilter(undefined);
                                }
                                // console.log(e.target);
                                // setFilter(["aa"]);
                                // const allValues = Array.from(e.target.value)
                                //     .map((o) => o.value)
                                //     .filter(Boolean);
                                // setFilter(allValues && allValues.length ? allValues : undefined);
                            }}>
                            <MenuItem value={""}>All</MenuItem>
                            {_.map(options, (brand, i) => (
                                <MenuItem key={i} value={brand.slug}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            );
        },
        [brands]
    );
    const columns = useMemo(
        () => [
            {
                Header: "Username",
                accessor: "profile.login",
                id: "profile.login",
                // Cell: usernameTemplate,
                filter: "fuzzyText",
            },
            {
                Header: "Name",
                accessor: "profile.displayName",
                id: "profile.displayName",
                // Cell: nameCell,
                filter: "fuzzyText",
            },
            {
                Header: "Email",
                accessor: "profile.email",
                id: "profile.email",
                filter: "fuzzyText",
            },
            {
                Header: "Role",
                accessor: roleCellData,
                id: "profile.userRole",
                Cell: roleTemplate,
                Filter: SelectColumnFilter,
                disableSortBy: true,
            },
            {
                Header: "Brands",
                accessor: "profile.assocBrands",
                id: "profile.assocBrands",
                Cell: assocBrandsCell,
                Filter: SelectBrandFilter,
            },
            {
                Header: "Location",
                accessor: locationCellData,
                id: "profile.location",
                Cell: locationTemplate,
                Filter: SelectColumnFilter,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: statusCellTemplate,
                Filter: SelectColumnFilter,
            },
            {
                Header: "Stores",
                accessor: storesCellData,
                id: "stores",
                Cell: storeCellTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "GuestFocus",
                accessor: guestFocusCellData,
                id: "profile.guestRelationsRole",
                Cell: guestFocusCellTemplate,
                Filter: BoolColumnFilter,
                hidden: true,
            },
            {
                Header: "GuestFocus Stores",
                accessor: guestFocusStoresCellData,
                id: "profile.guestRelationsAccessList",
                Cell: storeCellTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "Activated",
                accessor: "activated",
                id: "activated",
                Cell: dateTemplate,
                disableFilters: true,
                hidden: true,
            },
            {
                Header: "Created",
                accessor: "created",
                id: "created",
                Cell: dateTemplate,
                disableFilters: true,
                hidden: true,
            },
        ],
        [SelectBrandFilter]
    );

    return listitems ? (
        <DataTable
            pagetitle={pagetitle}
            localstoragekey={localstoragekey}
            listitems={listitems}
            columns={columns}
            createnew={{ label: "Create New User", path: "/createuser" }}
            clickable={clickable}
            selectAllEnabled={false}
            showSelectCheckbox={showSelectCheckbox}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            excelFileName={"matrixusers"}
            canExport={canExport}
            idfield={"id"}
            pageslug={"users"}
            filterData={[]}
        />
    ) : (
        <DataLoading />
    );
};

export default UsersTableData;
