import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";

const ControlledSubmitBtn = ({ label, roles, disabled, currentAdmin }) => {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (currentAdmin) {
            const roletest = _.includes(roles, currentAdmin.profile.matrixAdminRole);
            if (roletest) {
                setDisplay(
                    <Button variant='contained' color='primary' type='submit' disabled={disabled}>
                        {label}
                    </Button>
                );
            }
        }
    }, [currentAdmin, disabled, label, roles]);
    return display;
};

export default ControlledSubmitBtn;
