import { useEffect, useState } from "react";
import { Box, Button, Chip, Paper, Snackbar, withStyles } from "@material-ui/core";
import _ from "lodash";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getStores, getUsers, sortFilterStores } from "../../actions";
import { differenceInMinutes } from "date-fns";
import MuiAlert from "@material-ui/lab/Alert";

import List from "../../components/Common/List";
import * as Columns from "../Common/DataTableColData";
import StoresTableData from "../Stores/StoresTableData";
import { blue, grey, red } from "@material-ui/core/colors";

const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);

const UserStoresPanel = (data) => {
    const { user, admin, showAdminBtns, getuserbyid } = data;
    let history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const currentAdmin = useSelector((state) => state.user.usable);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [userStores, setUserStores] = useState([]);
    const [modifyStores, setModifyStores] = useState(false);
    const pagetitle = "Assigned Stores";
    const localstoragekey = "matrixstorelistview";
    const listitems = useSelector((state) => state.stores.usablelist);
    const [displayedStores, setDisplayedStores] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastContent, setToastContent] = useState("");
    let source = axios.CancelToken.source();
    // name from columnTemplates
    const modifyUserStores = async (selectedstores) => {
        console.log(`Performing: ${modifyStores}`);
        let _updatedStores;
        if (modifyStores === "add") {
            _updatedStores = _.concat(user.profile.storeGUIDs, selectedstores);
        } else if (modifyStores === "remove") {
            _updatedStores = _.compact(
                _.map(user.profile.storeGUIDs, (guid, i) => {
                    if (!_.includes(selectedstores, guid)) {
                        return guid;
                    }
                })
            );
        }
        let submitdata = { profile: { storeGUIDs: _.compact(_updatedStores) }, id: user.id };
        console.log("data", submitdata);
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserstores`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
                cancelToken: source.token,
            })
            .then((res) => {
                if (res.data.success) {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='success'>
                            {res.data.message}
                        </Alert>
                    );
                    setTimeout(() => {
                        dispatch(getUsers(token));
                        dispatch(getStores(token));
                        getuserbyid(user.id);
                    }, 1000);
                } else {
                    setToastContent(
                        <Alert onClose={handleToastClose} severity='error'>
                            {res.data.message}
                        </Alert>
                    );
                }
                setToastOpen(true);
            })
            .catch(function (error) {
                console.log(error);
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {error}
                    </Alert>
                );
                setToastOpen(true);
            });
    };
    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            // dispatch(getRequests(token));
            return;
        }
        setToastOpen(false);
        setModifyStores(false);
        // dispatch(getRequests(token));
    };
    const getStoresToRemove = () => {
        setDisplayedStores([]);
        let _userstores = _.map(user.profile.storeGUIDs, (guid, i) => {
            return _.find(listitems, { FranchiseID: guid });
        });
        setDisplayedStores(_userstores);
    };
    const getStoresToAdd = () => {
        setDisplayedStores([]);
        let _nonassignedstores = _.compact(
            _.map(listitems, (store, i) => {
                if (_.includes(user.profile.storeGUIDs, store.FranchiseID)) {
                } else {
                    return store;
                }
            })
        );
        setDisplayedStores(_nonassignedstores);
    };
    const getStoresToDisplay = () => {
        setDisplayedStores([]);
        let _userstores = _.compact(
            _.map(user.profile.storeGUIDs, (guid, i) => {
                return _.find(listitems, { FranchiseID: guid });
            })
        );
        setDisplayedStores(_userstores);
    };
    useEffect(() => {
        if (listitems && user) {
            getStoresToDisplay();
        }
    }, [listitems, user]);

    useEffect(() => {
        console.log(selectedRow);
    }, [selectedRow]);
    return (
        <>
            {/* <List
            pagetitle={pagetitle}
            localstoragekey={localstoragekey}
            listitems={userStores}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            defaultColumns={Columns.storesAvailableColumns}
            defaultView={Columns.storesDefaultColumns}
            listfilter={filter}
            headerRow={headerRow}
            resetList={resetList}
            filterSortList={filterSortList}
            parselistitems={parselistitems}
        /> */}

            <Snackbar open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
                <>{toastContent}</>
            </Snackbar>
            {modifyStores === "add" || modifyStores === "remove" ? (
                <>
                    <Box display='flex' component={Paper} py={2}>
                        <Box px={1}>
                            <GreyButton
                                variant='contained'
                                onClick={() => {
                                    setSelectedRow(null);
                                    setModifyStores(false);
                                    getStoresToDisplay();
                                }}>
                                Cancel
                            </GreyButton>
                        </Box>
                        <Box px={1}>
                            {modifyStores === "add" ? (
                                <BlueButton variant='contained' onClick={() => modifyUserStores(selectedRow)} disabled={_.isEmpty(selectedRow)}>
                                    Add Store(s)
                                </BlueButton>
                            ) : (
                                <RedButton variant='contained' onClick={() => modifyUserStores(selectedRow)} disabled={_.isEmpty(selectedRow)}>
                                    Remove Store(s)
                                </RedButton>
                            )}
                        </Box>
                    </Box>
                    {displayedStores ? (
                        <StoresTableData
                            pagetitle={pagetitle}
                            localstoragekey={localstoragekey}
                            listitems={displayedStores}
                            showSelectCheckbox={true}
                            clickable={false}
                            setSelectedItems={setSelectedRow}
                            selectedItems={selectedRow}
                            filterData={[]}
                        />
                    ) : (
                        "Loading..."
                    )}
                </>
            ) : (
                <>
                    <Box display='flex' component={Paper} py={2}>
                        <Box px={1}>
                            <BlueButton
                                variant='contained'
                                onClick={() => {
                                    getStoresToAdd();
                                    setModifyStores("add");
                                    setSelectedRow(null);
                                }}>
                                Add Store(s)
                            </BlueButton>
                        </Box>
                        <Box px={1}>
                            <RedButton
                                variant='contained'
                                onClick={() => {
                                    getStoresToRemove();
                                    setModifyStores("remove");
                                    setSelectedRow(null);
                                }}>
                                Remove Store(s)
                            </RedButton>
                        </Box>
                    </Box>
                    {displayedStores ? (
                        <StoresTableData pagetitle={pagetitle} localstoragekey={localstoragekey} listitems={displayedStores} showSelectCheckbox={false} clickable={true} />
                    ) : (
                        "Loading..."
                    )}
                </>
            )}
        </>
    );
};

export default UserStoresPanel;
