import { Box, Button } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ControlledSubmitBtn from "../Common/ControlledSubmitBtn";

const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
}))(Button);
const PendingRequestFormMenu = ({ props, currentAdmin, openRejectModal, validUsername }) => {
    const history = useHistory();
    return (
        <div>
            <Box display='flex' px={1}>
                <Box p={1} flexGrow={1}>
                    <RedButton variant='contained' onClick={openRejectModal}>
                        Reject
                    </RedButton>
                </Box>
                <Box p={1}>
                    <GreyButton
                        variant='contained'
                        onClick={() => {
                            history.push(`/accessrequests`);
                        }}>
                        Cancel
                    </GreyButton>
                </Box>
                <Box p={1}>
                    <ControlledSubmitBtn
                        label='Approve'
                        roles={["super", "admin", "appadmin"]}
                        currentAdmin={currentAdmin}
                        disabled={!validUsername || validUsername === "invalid" || !props.dirty || props.isSubmitting || !props.isValid}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default PendingRequestFormMenu;
