import { useState, useEffect, useMemo, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import _ from "lodash";
import { Grid, Select, MenuItem, FormGroup, FormControlLabel, FormControl, InputLabel, makeStyles, Box, Button } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Button);
const GreyButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
        "&:hover": {
            backgroundColor: grey[700],
        },
    },
}))(Button);
const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
};
const UpdateProfileFromJSON = (data) => {
    const { setToastContent, setToastOpen } = data;
    const history = useHistory();
    const classes = useStyles();
    const { oktaAuth, authState } = useOktaAuth();
    const [jsonData, setjsonData] = useState(false);
    const [fieldNames, setFieldNames] = useState([]);
    const [idFieldNameChoices, setidFieldNameChoices] = useState([]);
    const [idField, setidField] = useState(false);
    const [oktaFieldNamesValid, setoktaFieldNamesValid] = useState(false);
    const [ignoreFields, setignoreFields] = useState();
    const [preparedProcessingGroups, setpreparedProcessingGroups] = useState([]);
    const handleToastClose = (event, reason) => {
        const token = oktaAuth.getAccessToken();
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    const processFile = (e) => {
        const file = e.target.files[0];
        let reader = new FileReader();
        if (file) {
            reader.readAsText(file);
            reader.onload = function () {
                let resultjson = JSON.parse(reader.result);
                setjsonData(resultjson);
            };

            reader.onerror = function () {
                setToastContent(
                    <Alert onClose={handleToastClose} severity='error'>
                        {reader.error}
                    </Alert>
                );
                setToastOpen(true);
            };
        }
    };
    const userIdSelected = (e) => {
        setidField(e.target.value);
    };
    const fieldsOktaValid = (e) => {
        setoktaFieldNamesValid(!oktaFieldNamesValid);
    };
    const updateIgnoreFields = (field) => {
        setignoreFields({ ...ignoreFields, [field]: !ignoreFields[field] });
    };
    const fieldTypeSelected = (e, field) => {
        let _fieldarry = _.map(fieldNames, (item, i) => {
            if (item.name === field.name) {
                item.type = e.target.value;
            }
            return item;
        });
        setFieldNames(_fieldarry);
    };
    const handleSubmit = async (values, ignoredfields) => {
        let userid = values.id;
        values = _.omit(values, _.concat(["login", "id"], ignoredfields));

        let submitdata = { profile: values, id: userid };
        console.log("submitdata", submitdata);
        const token = oktaAuth.getAccessToken();
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/updateuserprofile`, submitdata, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const processUserData = (group) => {
        let _currgroup = _.map(group, (user, i) => {
            let _arraytypefields = _.filter(fieldNames, { type: "array" });
            _.each(_arraytypefields, (field, i) => {
                user[field.name] = user[field.name].split(";");
            });
            let _booltypefields = _.filter(fieldNames, { type: "boolean" });
            _.each(_booltypefields, (field, i) => {
                if (user[field.name] && (user[field.name] !== undefined || user[field.name] !== null || user[field.name].toLowerCase() === "true" || user[field.name].toLowerCase() === "yes")) {
                    user[field.name] = true;
                } else {
                    user[field.name] = false;
                }
            });
            return user;
        });
        let _ignoredKeys = _.compact(
            _.map(ignoreFields, (value, i) => {
                if (value) {
                    return i;
                }
            })
        );
        _.each(_currgroup, (user, i) => {
            handleSubmit(user, _ignoredKeys);
        });
    };
    useEffect(() => {
        if (jsonData) {
            let _datatemplaterow = jsonData[0];
            let _fieldnames = _.keys(_datatemplaterow);
            setidFieldNameChoices(_fieldnames);
            _fieldnames = _.map(_fieldnames, (field, i) => {
                return { name: field, type: "string" };
            });
            setFieldNames(_fieldnames);
            let _ignorefields = _.mapValues(_datatemplaterow, (val, i) => {
                return false;
            });
            setignoreFields(_ignorefields);
        }
    }, [jsonData]);
    useEffect(() => {
        if (idField && oktaFieldNamesValid) {
            let _hashedUserData = _.map(jsonData, (user, i) => {
                let _oktaUsrObj = {
                    id: user[idField],
                    ..._.omit(user, [idField]),
                };
                return _oktaUsrObj;
            });
            let _chunkedJSONData = _.chunk(_hashedUserData, 400);
            setpreparedProcessingGroups(_chunkedJSONData);
        }
    }, [idField, oktaFieldNamesValid]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {" "}
                    <FormGroup row>
                        <FormControlLabel control={<input type='file' name='file' id='exampleFile' accept='application/JSON' onChange={processFile} />} label='Upload JSON file' />
                    </FormGroup>
                    <div>Upload a JSON file that makes sure to include any fields you want to update along with the Okta user id/guid.</div>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id='idFieldSelect-label'>Select User ID/GUID Field:</InputLabel>
                        <Select labelId='idFieldSelect-label' id='idFieldSelect' value={idField} required disabled={!jsonData} onChange={userIdSelected}>
                            <MenuItem value={false}>-select-</MenuItem>
                            {_.map(idFieldNameChoices, (name, i) => {
                                return (
                                    <MenuItem key={i} value={name}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box>Do the field names in your file match the appropriate Okta field names?</Box>
                    <FormControlLabel
                        control={<Checkbox checked={oktaFieldNamesValid || false} onChange={fieldsOktaValid} name={"oktafieldvalid"} disabled={!idField} value={oktaFieldNamesValid} />}
                        label={"Yes"}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Box hidden={!idField || !oktaFieldNamesValid}>
                    <Grid item xs={12}>
                        <Typography component='div'>Current Field Names:</Typography>
                        <div>
                            <small>
                                if the field names do not exactly match the okta field, update your JSON file. If they do not match they will be skipped, and you haven't selected to ignore them, it
                                will cause an error.
                            </small>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {_.map(fieldNames, (field, i) => {
                            return (
                                <Grid key={i} container spacing={2}>
                                    <Grid item xs={2}>
                                        {field.name}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {oktaFieldNamesValid ? (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={`ignorefield-${field.name}`}
                                                        className='d-inline'
                                                        label={"Ignore Field?"}
                                                        checked={ignoreFields[field.name] || false}
                                                        value={ignoreFields[field.name]}
                                                        onChange={() => updateIgnoreFields(field.name)}
                                                    />
                                                }
                                                label={"Yes"}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {oktaFieldNamesValid ? (
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id='idFieldSelect-label'>Select User ID/GUID Field:</InputLabel>
                                                <Select labelId='idFieldSelect-label' id='idFieldSelect' value={field.type} required disabled={!jsonData} onChange={(e) => fieldTypeSelected(e, field)}>
                                                    <MenuItem value={"string"}>String</MenuItem>
                                                    <MenuItem value={"boolean"}>Boolean</MenuItem>
                                                    <MenuItem value={"array"}>Array</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                    <Grid item xs={6}></Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display='flex' px={1}>
                            <Box p={1} flexGrow={1}>
                                <GreyButton variant='contained' size='small' onClick={() => history.push("/super/tools")}>
                                    Cancel
                                </GreyButton>
                            </Box>
                            <Box p={1}>
                                {_.map(preparedProcessingGroups, (processGrp, i) => {
                                    return (
                                        <BlueButton
                                            key={i}
                                            variant='contained'
                                            size='small'
                                            onClick={() => {
                                                processUserData(processGrp);
                                            }}>
                                            Process Group {i + 1}
                                        </BlueButton>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
};

export default UpdateProfileFromJSON;
