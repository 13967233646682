import { useEffect, useState } from "react";
import { Box, Chip, Grid, Input, Paper, Select, Snackbar, Typography } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import MuiAlert from "@material-ui/lab/Alert";

import MatrixItemsList from "./MatrixItemsList";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export const List = ({
    pagetitle,
    localstoragekey,
    listitems,
    selectedRow,
    setSelectedRow,
    selectedColumns,
    setSelectedColumns,
    defaultColumns,
    defaultView,
    listfilter,
    headerRow,
    footerRow,
    resetList,
    filterSortList,
    selectionRow,
    canExport,
    excelFileName,
}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [loading, setloading] = useState(true);
    const [columns, setcolumns] = useState(defaultColumns);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const onColumnToggle = (e) => {
        let selectedColumns = e.target.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        if (orderedSelectedColumns.length >= 1) {
            setSelectedColumns(orderedSelectedColumns);
        } else {
            setSnackbarOpen(true);
            setSnackbarSeverity("warning");
            setSnackbarMsg("Please make sure at least one field/column is selected");
        }
    };
    const saveView = (selColumns) => {
        console.info(`saving view for ${pagetitle} list`);
        localStorage.removeItem(localstoragekey);
        localStorage.setItem(localstoragekey, JSON.stringify(selColumns));
        setSelectedColumns(selColumns);
    };

    const resetDefaultView = () => {
        console.info(`setting default view for ${pagetitle} list`);
        localStorage.removeItem(localstoragekey);
        localStorage.setItem(localstoragekey, JSON.stringify(defaultView));
        setSelectedColumns(defaultView);
    };
    const checkLocalStorageView = () => {
        // customize per list
        let _matrixlistview = localStorage.getItem(localstoragekey);
        _matrixlistview = JSON.parse(_matrixlistview);
        if (_.isEmpty(_matrixlistview)) {
            // localStorage key doesn't exist
            console.info(`${pagetitle} list view key doesn't exist`);
            setSelectedColumns(defaultView);
        } else if (_matrixlistview) {
            // localStorage key exists
            console.info(`${pagetitle} list view key exists`);
            setSelectedColumns(_matrixlistview);
        }
    };
    useEffect(() => {
        if (!_.isEmpty(listitems)) {
            checkLocalStorageView();
            // setDefaultView(defaultView);
            setloading(false);
        }
    }, [listitems]);
    return (
        <Grid container spacing={2}>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000} open={snackbarOpen} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Box p={1}>
                    {loading ? (
                        ""
                    ) : (
                        <MatrixItemsList
                            list={listitems}
                            columns={selectedColumns}
                            pagetitle={pagetitle}
                            columnselect={
                                <>
                                    <InputLabel id='datatable-viewable-columns'>Viewable Columns</InputLabel>
                                    <Select
                                        labelId='datatable-viewable-columns'
                                        id='datatable-mutiple-chip'
                                        multiple
                                        value={selectedColumns}
                                        onChange={onColumnToggle}
                                        input={<Input id='select-multiple-chip' />}
                                        renderValue={(selected) => (
                                            <div>
                                                {_.map(selected, (value, i) => {
                                                    return <Chip key={i} label={value.header} color='primary' />;
                                                })}
                                            </div>
                                        )}>
                                        {_.map(columns, (col, i) => {
                                            return (
                                                <MenuItem key={i} value={col}>
                                                    {col.header}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                            }
                            footer={
                                footerRow ? (
                                    <Box className='table-header' display='flex'>
                                        <Box flexGrow={1}></Box>
                                        <Box p={1}>{footerRow}</Box>
                                    </Box>
                                ) : (
                                    ""
                                )
                            }
                            dtfilter={listfilter}
                            contextMenu={false}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            selectionRow={selectionRow}
                            filterSortFunct={filterSortList}
                            resetList={resetList}
                            resetDefaultView={resetDefaultView}
                            saveView={saveView}
                            canExport={canExport}
                            excelFileName={excelFileName}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
export default List;
