import {
    faChevronLeft,
    faEclipseAlt,
    faSun,
    faBrowser,
    faUserCheck,
    faUsersClass,
    faMapMarkerQuestion,
    faStoreAlt,
    faUsersCrown,
    faUserTag,
    faUserSecret,
    faCopyright,
    faUserHeadset,
    faTools,
    faTag,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Collapse, Drawer, Grid, Hidden, ListItem, ListItemIcon, ListItemText, ListSubheader, SwipeableDrawer, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import clsx from "clsx";
import _ from "lodash";
import { useState, useEffect } from "react";
import { withRouter, NavLink as RRNavLink } from "react-router-dom";
// import { OverlayScrollbarsReactComponent } from "../OverlayScrollbars";

import navitems from "../Common/navitems";
import SideNavItem from "./SideNavItem";

const SideNav = ({ myapps, classes, open, handleDrawerClose, setOpen, switchTheme, darkMode, currentAdmin }) => {
    const [navItems, setNavItems] = useState(false);
    const toggleDrawer = (isOpen) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpen(isOpen);
    };

    useEffect(() => {
        let _displayAdminNavItems;
        if (currentAdmin && currentAdmin.profile.matrixAdmin) {
            _displayAdminNavItems = _.compact(
                _.map(navitems, (item, i) => {
                    if (_.includes(item.access, "all") || _.includes(item.access, currentAdmin.profile.matrixAdminRole)) {
                        return item;
                    }
                })
            );
        }
        setNavItems(_.concat(_displayAdminNavItems));
    }, [currentAdmin]);
    return (
        <>
            <Hidden xsDown>
                <Drawer
                    variant='permanent'
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}>
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {_.map(navItems, (item, i) => {
                            return <SideNavItem key={i} navitem={item} classes={classes} open={open} darkMode={darkMode} />;
                        })}
                    </List>
                </Drawer>
            </Hidden>
            <Hidden smUp>
                <SwipeableDrawer
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    anchor={"left"}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}>
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                        </IconButton>
                    </div>
                    <Divider />
                    <Box mx={2}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item xs={9}>
                                <Typography component='span'>{currentAdmin ? `${currentAdmin.profile.displayName}` : ""}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton color='inherit' onClick={() => switchTheme()}>
                                    {darkMode ? <FontAwesomeIcon icon={faEclipseAlt} fixedWidth /> : <FontAwesomeIcon icon={faSun} fixedWidth />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <List>
                        {_.map(navItems, (item, i) => {
                            return <SideNavItem key={i} navitem={item} classes={classes} open={open} darkMode={darkMode} />;
                        })}
                    </List>
                </SwipeableDrawer>
            </Hidden>
        </>
    );
};

export default SideNav;
