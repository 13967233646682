import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import SimpleTextField from "./SimpleTextField";

const EmailLookupField = ({ props, email, checkContactEmail, setLookupEmailValidMsg, lookupEmailValidMsg, showFormHelp, fieldHelpText }) => {
    useEffect(() => {
        if (!props.errors.email && props.values.email) {
            //recheck on valid email
            checkContactEmail(props.values.email);
        } else if (!props.errors.email && !props.values.email) {
            // clear out on blank email
            setLookupEmailValidMsg("");
        }
    }, [email]);
    return (
        <Grid container spacing={1} alignItems='flex-end'>
            <Grid item xs={12}>
                <SimpleTextField
                    props={props}
                    fieldname={"email"}
                    fieldtype={"text"}
                    fieldlabel={"Email"}
                    fieldrequired={true}
                    fieldValidationText={lookupEmailValidMsg}
                    showFormHelp={showFormHelp}
                    fieldHelpText={fieldHelpText}
                />
            </Grid>
        </Grid>
    );
};

export default EmailLookupField;
