import { faBars, faBell, faChartNetwork, faEclipseAlt, faSun, faToolbox } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import { Box, Hidden } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import AdminRoleIcons from "../Common/AdminRoleIcons";
const StyledLink = styled(Link)({
    color: "white",
    textDecoration: "none",
});

const TopNav = ({ classes, open, handleDrawerOpen, currentAdmin, switchTheme, darkMode }) => {
    return (
        <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton edge='start' color='inherit' aria-label='open drawer' onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
                    <FontAwesomeIcon icon={faBars} fixedWidth />
                </IconButton>
                <Typography color='inherit' noWrap className={classes.title}>
                    <StyledLink to='/'>
                        <FontAwesomeIcon icon={faChartNetwork} fixedWidth /> Matrix Admin Portal
                    </StyledLink>
                </Typography>
                <Hidden smDown>
                    <Box ml={2}>
                        <Typography component='span'>
                            Welcome
                            {currentAdmin ? (
                                <>
                                    , {currentAdmin.profile.displayName} <AdminRoleIcons slug={currentAdmin.profile.matrixAdminRole} />
                                </>
                            ) : (
                                ""
                            )}
                        </Typography>
                    </Box>
                    <IconButton color='inherit' onClick={() => switchTheme()}>
                        {darkMode ? <FontAwesomeIcon icon={faEclipseAlt} fixedWidth /> : <FontAwesomeIcon icon={faSun} fixedWidth />}
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

export default TopNav;
