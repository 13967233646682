import { useEffect, useState } from "react";
import { Box, Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getApps, sortFilterApps } from "../../actions";
import { differenceInMinutes } from "date-fns";

import List from "./List";
import { blue } from "@material-ui/core/colors";

const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Box);
const AppsListSelection = ({ props, showFormHelp, fieldHelpText, setAssignedApps, currentAdmin }) => {
    let history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const listitems = useSelector((state) => state.apps.usablelist);
    const [filteredApps, setFilteredApps] = useState([]);
    const [selectedApps, setSelectedApps] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const pagetitle = "Apps";
    const localstoragekey = "matrixappslistview";
    // name from columnTemplates
    const defaultView = [
        { field: "name", name: "Name", friendlyname: "Name" },
        { field: "adminname", name: "Admin", friendlyname: "Admin" },
        { field: "active", name: "Status", friendlyname: "Status" },
        { field: "assocBrands", name: "Brands", friendlyname: "Brands" },
    ];
    const defaultColumns = [
        // all available columns
        { field: "oktagroupid", name: "Assignment", friendlyname: "Assignment" },
        { field: "name", name: "Name", friendlyname: "Name" },
        { field: "adminname", name: "Admin", friendlyname: "Admin" },
        { field: "active", name: "Status", friendlyname: "Status" },
        { field: "assocBrands", name: "Brands", friendlyname: "Brands" },
        { field: "location", name: "Location", friendlyname: "Location" },
    ];
    const [headerRow, setHeaderRow] = useState(
        <div className='table-header' style={{ fontSize: "1.2rem" }}>
            {pagetitle}
        </div>
    );
    const [filter, setfilter] = useState({});
    const resetList = () => {
        if (!_.isEmpty(filter)) {
            dispatch(sortFilterApps({ filters: filter }));
        } else {
            dispatch(getApps(token));
        }
    };
    const filterSortList = (filterObj) => {
        dispatch(sortFilterApps(filterObj));
    };
    const parselistitems = (items) => {
        return items;
    };
    useEffect(() => {
        // customize per list
        if (selectedRow) {
            console.log(selectedRow);
            setAssignedApps(selectedRow);
        } else {
            setAssignedApps([]);
        }
    }, [selectedRow]);
    useEffect(() => {
        // let _localoktasynctime = localStorage.getItem("oktausersynctime");
        // _localoktasynctime = JSON.parse(_localoktasynctime);
        // let _offsetsynctime = differenceInMinutes(new Date(), new Date(_localoktasynctime.lastran));
        if (selectedColumns && selectedColumns.length >= 1) {
            // <Box p={1} textAlign='center'>
            //     <Chip label={`Last Updated ${_offsetsynctime} minute(s) ago`} color='primary' />
            // </Box>;
            setHeaderRow(
                <Box flexGrow={1}>
                    <span style={{ fontSize: "1.2rem" }}>{pagetitle}</span>
                </Box>
            );
        }
    }, [selectedColumns]);
    useEffect(() => {
        if (!_.isEmpty(currentAdmin)) {
            setfilter({
                active: {
                    value: "1",
                    matchMode: "equals",
                },
            });
        }
    }, [currentAdmin]);
    useEffect(() => {
        if (listitems) {
            let taggedAppList = _.map(listitems, (app, i) => {
                if (app.active) {
                    const assocBrandsArry = app.assocBrands.split(";");
                    let checkBrandAssoc = false;
                    _.each(assocBrandsArry, (brand, i) => {
                        if (_.indexOf(props.values.assocBrands, brand) !== -1) {
                            checkBrandAssoc = true;
                        }
                    });
                    const associatedUserRoleArry = app.roles.split(";");
                    let checkUserRoleAssoc = false;
                    _.each(associatedUserRoleArry, (role, i) => {
                        if (props.values.userRole === role) {
                            checkUserRoleAssoc = true;
                        }
                    });
                    if (props.values.location === app.location || app.location === "all" || props.values.location === "all") {
                        if (checkBrandAssoc || app.assocBrands === "all") {
                            if (checkUserRoleAssoc || app.roles === "all") {
                                if (app.oktagroupid === "okta_rule") {
                                    app.selected = true;
                                    app.ruleselected = true;
                                    app.hideSelectionElement = true;
                                    return app;
                                } else {
                                    return app;
                                }
                            } else {
                                return null;
                            }
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }
            });
            taggedAppList = _.compact(taggedAppList);
            setFilteredApps(taggedAppList);
        }
    }, [listitems, props.values.assocBrands, props.values.location, props.values.userRole]);
    useEffect(() => {
        let _defaultselectedapps = _.compact(
            _.map(filteredApps, (app, i) => {
                if (app.selected) {
                    return app;
                }
            })
        );
        setSelectedRow(_defaultselectedapps);
    }, [filteredApps]);

    return (
        <>
            <List
                pagetitle={pagetitle}
                localstoragekey={localstoragekey}
                listitems={filteredApps}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                defaultColumns={defaultColumns}
                defaultView={defaultView}
                listfilter={filter}
                headerRow={headerRow}
                resetList={resetList}
                filterSortList={filterSortList}
                parselistitems={parselistitems}
                selectionRow={true}
            />
            {showFormHelp ? (
                <BlueBox p={1} style={{ borderRadius: "5px" }}>
                    <small>{fieldHelpText}</small>
                </BlueBox>
            ) : (
                ""
            )}
        </>
    );
};

export default AppsListSelection;
