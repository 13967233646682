import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useEffect, useState } from "react";
import { Box, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "5px",
    },
    appbar: {
        borderRadius: "5px",
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {},
}));
const UserDetailMenuBar = (data) => {
    const { page, menu } = data;
    const classes = useStyles();
    const [tabMenu, setTabMenu] = useState("");
    useEffect(() => {
        let _menu;
        switch (page) {
            case "details":
                _menu = menu;
                break;
            case "editdetails":
                _menu = menu;
                break;
            default:
                _menu = "";
                break;
        }
        setTabMenu(_menu);
    }, [page, menu]);
    return (
        <>
            <Box p={1}>
                <Divider />
            </Box>
            {tabMenu}
            <Box p={1}>
                <Divider />
            </Box>
        </>
    );
};

export default UserDetailMenuBar;
