import { useEffect, useState } from "react";
import { Box, Chip } from "@material-ui/core";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getApps, sortFilterApps } from "../actions";
import { differenceInMinutes } from "date-fns";

import List from "../components/Common/List";

const Apps = () => {
    let history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const listitems = useSelector((state) => state.apps.usablelist);
    const currentAdmin = useSelector((state) => state.user.usable);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const pagetitle = "Apps";
    const localstoragekey = "matrixappslistview";
    // name from columnTemplates
    const defaultView = [
        { field: "name", name: "Name", friendlyname: "Name" },
        { field: "adminname", name: "Admin", friendlyname: "Admin" },
        { field: "active", name: "Status", friendlyname: "Status" },
        { field: "assocBrands", name: "Brands", friendlyname: "Brands" },
    ];
    const defaultColumns = [
        // all available columns
        { field: "oktagroupid", name: "Assignment", friendlyname: "Assignment" },
        { field: "name", name: "Name", friendlyname: "Name" },
        { field: "adminname", name: "Admin", friendlyname: "Admin" },
        { field: "active", name: "Status", friendlyname: "Status" },
        { field: "assocBrands", name: "Brands", friendlyname: "Brands" },
        { field: "location", name: "Location", friendlyname: "Location" },
    ];
    const [headerRow, setHeaderRow] = useState(
        <div className='table-header' style={{ fontSize: "1.2rem" }}>
            {pagetitle}
        </div>
    );
    const [filter, setfilter] = useState({});
    const resetList = () => {
        if (!_.isEmpty(filter)) {
            dispatch(sortFilterApps({ filters: filter }));
        } else {
            dispatch(getApps(token));
        }
    };
    const filterSortList = (filterObj) => {
        dispatch(sortFilterApps(filterObj));
    };
    const parselistitems = (items) => {
        return items;
    };
    useEffect(() => {
        console.log(selectedRow);
        // customize per list
        if (selectedRow) {
            console.log("itll work for you");
            // history.push(`/stores/${selectedRow.StoreShortID}`);
        }
    }, [selectedRow]);
    useEffect(() => {
        // let _localoktasynctime = localStorage.getItem("oktausersynctime");
        // _localoktasynctime = JSON.parse(_localoktasynctime);
        // let _offsetsynctime = differenceInMinutes(new Date(), new Date(_localoktasynctime.lastran));
        if (selectedColumns && selectedColumns.length >= 1) {
            // <Box p={1} textAlign='center'>
            //     <Chip label={`Last Updated ${_offsetsynctime} minute(s) ago`} color='primary' />
            // </Box>;
            setHeaderRow(
                <Box flexGrow={1}>
                    <span style={{ fontSize: "1.2rem" }}>{pagetitle}</span>
                </Box>
            );
        }
    }, [selectedColumns]);
    useEffect(() => {
        if (!_.isEmpty(currentAdmin)) {
            setfilter({
                active: {
                    value: "1",
                    matchMode: "equals",
                },
            });
        }
    }, [currentAdmin]);
    return (
        <>Apps</>
        // <List
        //     pagetitle={pagetitle}
        //     localstoragekey={localstoragekey}
        //     listitems={listitems}
        //     selectedRow={selectedRow}
        //     setSelectedRow={setSelectedRow}
        //     selectedColumns={selectedColumns}
        //     setSelectedColumns={setSelectedColumns}
        //     defaultColumns={defaultColumns}
        //     defaultView={defaultView}
        //     listfilter={filter}
        //     headerRow={headerRow}
        //     resetList={resetList}
        //     filterSortList={filterSortList}
        //     parselistitems={parselistitems}
        // />
    );
};

export default Apps;
