import { Box, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Field, useField } from "formik";
import { RadioGroup } from "formik-material-ui";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { blue, red } from "@material-ui/core/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";

const BlueBox = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700],
        },
    },
}))(Box);
const ChoiceField = ({ props, fieldname, options, fieldvalue, fieldlabel, fieldrequired, fieldHelpText, showFormHelp }) => {
    const fieldProps = useField(fieldname);
    const [field, meta, helpers] = fieldProps;
    const handleChange = (event, value) => {
        setTimeout(() => {
            helpers.setValue(value);
            helpers.setTouched(true, true);
        });
    };
    return (
        <>
            <Field
                name={fieldname}
                value={props.values[fieldname]}
                component={Autocomplete}
                options={_.map(options, (tick, i) => {
                    return tick.slug;
                })}
                getOptionLabel={(option) => {
                    let _item = _.find(options, (opt, i) => {
                        if (opt.slug === option) {
                            return opt;
                        }
                    });
                    return _item ? _item.label : "";
                }}
                renderOption={(option) => {
                    let _item = _.find(options, (opt, i) => {
                        if (opt.slug === option) {
                            return opt;
                        }
                    });

                    return _item.label;
                }}
                onChange={handleChange}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            required={fieldrequired}
                            margin='dense'
                            label={fieldlabel}
                            variant='outlined'
                            error={props.errors[fieldname] && props.touched[fieldname] ? true : false}
                        />
                    );
                }}
            />
            {props.errors[fieldname] && props.touched[fieldname] ? (
                <Box color={red[600]}>
                    <small>{props.errors[fieldname]}</small>
                </Box>
            ) : (
                ""
            )}
            {showFormHelp ? (
                <BlueBox p={1} style={{ borderRadius: "5px" }}>
                    <small>{fieldHelpText}</small>
                </BlueBox>
            ) : (
                ""
            )}
        </>
    );
};

export default ChoiceField;
