import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { isAfter, isBefore } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faTasks, faUserPlus } from "@fortawesome/pro-duotone-svg-icons";
import { Box, Grid, LinearProgress, List, ListItem } from "@material-ui/core";
import { Chart } from "primereact/chart";

const AccessRequestsPanel = ({ startDate, endDate, matrixusers, locationView, colorMode }) => {
    const brands = useSelector((state) => state.brands.untouchedlist);
    const [perBrandActivations, setPerBrandActivations] = useState([]);
    const [palette, setPalette] = useState([]);
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (matrixusers && brands) {
            let _matrixusers = _.compact(
                _.map(matrixusers, (user, i) => {
                    const _activateDate = new Date(user.activated);
                    if (isAfter(_activateDate, startDate) && isBefore(_activateDate, endDate)) {
                        return user;
                    }
                })
            );
            let _perbrandactivations = _.compact(
                _.map(brands, (brand, i) => {
                    if (brand.active) {
                        let _brdactivations = _.compact(
                            _.map(_matrixusers, (user, i) => {
                                let _asscbrndarry = _.compact(user.profile.assocBrands);
                                if (_.includes(_asscbrndarry, brand.slug) && (user.profile.location === brand.location || user.profile.location === "all")) {
                                    return user;
                                }
                            })
                        );
                        let brdObj = {
                            brand: brand.name,
                            slug: brand.slug,
                            location: brand.location,
                            color: brand.hexcolor,
                            val: _brdactivations.length,
                        };
                        if (_brdactivations.length >= 1) {
                            return brdObj;
                        }
                    }
                })
            );
            let _paletteColors = _.map(_perbrandactivations, (brand, i) => {
                return brand.color;
            });
            setPalette(_paletteColors);
            setPerBrandActivations(_perbrandactivations);
        }
    }, [startDate, endDate, matrixusers, brands]);
    useEffect(() => {
        if (locationView) {
            let _chartData = {
                labels: _.compact(
                    _.map(perBrandActivations, (item, i) => {
                        if (item.location === locationView) {
                            return item.brand;
                        }
                    })
                ),
                datasets: [
                    {
                        data: _.compact(
                            _.map(perBrandActivations, (item, i) => {
                                if (item.location === locationView) {
                                    return item.val;
                                }
                            })
                        ),
                        fill: true,
                        backgroundColor: palette,
                    },
                ],
            };
            setChartData(_chartData);
        }
    }, [locationView, palette, perBrandActivations]);

    const chartOptions = {
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: colorMode === "light" ? "#495057" : "#ffffff",
                    },
                    gridLines: {
                        color: colorMode === "light" ? "#495057" : "#9e9e9e",
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: colorMode === "light" ? "#495057" : "#ffffff",
                    },
                    gridLines: {
                        color: colorMode === "light" ? "#495057" : "#9e9e9e",
                    },
                },
            ],
        },
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box p={2}>
                        <FontAwesomeIcon icon={faUserPlus} fixedWidth size='2x' />
                        &nbsp;
                        <span style={{ fontSize: "1.4rem", textTransform: "uppercase" }}>Activated Users</span>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={2}>{!_.isEmpty(perBrandActivations) ? <Chart type='bar' data={chartData} options={chartOptions} /> : "No Records to view"}</Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AccessRequestsPanel;
