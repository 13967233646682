import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ApprovedRequest from "../components/Requests/ApprovedRequest";
import CancelledRequest from "../components/Requests/CancelledRequest";
import PendingRequest from "../components/Requests/CreateUserForm";
import PendingRequestReadOnly from "../components/Requests/PendingRequestReadOnly";

const RequestID = () => {
    let params = useParams();
    const items = useSelector((state) => state.requests.untouchedlist);
    const currentAdmin = useSelector((state) => state.user.usable);
    const idfield = "id";
    const [display, setDisplay] = useState("");
    const [currentItem, setcurrentItem] = useState({});
    useEffect(() => {
        if (!_.isEmpty(params.id)) {
            let _currentitem = _.find(items, (item) => {
                if (`${item[idfield]}` === params.id) {
                    return item;
                }
            });
            setcurrentItem(_currentitem);
        }
    }, [params, items]);
    useEffect(() => {
        if (!_.isEmpty(currentItem)) {
            switch (currentItem.status) {
                case "Approved":
                    setDisplay(<ApprovedRequest item={currentItem} />);
                    break;
                case "Cancelled":
                    setDisplay(<CancelledRequest item={currentItem} />);
                    break;
                case "Pending":
                    if (currentAdmin.profile.matrixAdmin && _.includes(["super", "admin", "appsadmin"], currentAdmin.profile.matrixAdminRole)) {
                        setDisplay(<PendingRequest item={currentItem} currentAdmin={currentAdmin} />);
                    } else {
                        setDisplay(<PendingRequestReadOnly item={currentItem} />);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [currentItem]);
    return display;
};

export default RequestID;
