import { useEffect, useState } from "react";
import { Box, Chip } from "@material-ui/core";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getUsers, sortFilterMatrixUsers } from "../actions";
import * as CellTemplates from "../components/Common/cellTemplates";

import List from "../components/Common/List";
import * as Columns from "../components/Common/DataTableColData";
import UsersTableData from "../components/User/UsersTableData";

const SharedAccounts = () => {
    let history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const currentAdmin = useSelector((state) => state.user.usable);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listData, setListData] = useState(false);
    const pagetitle = "Shared Accounts";
    const localstoragekey = "matrixsharedacctlistview";
    const listitems = useSelector((state) => state.matrixusers.usablelist);
    const defaultViewColumns = Columns.usersDefaultColumns;
    const availableColumns = Columns.usersAvailableColumns;
    const [headerRow, setHeaderRow] = useState(
        <div className='table-header' style={{ fontSize: "1.2rem" }}>
            {pagetitle}
        </div>
    );
    const [footerRow, setFooterRow] = useState("");
    const [filter, setfilter] = useState({});
    const resetList = () => {
        if (filter) {
            dispatch(sortFilterMatrixUsers({ filters: filter }));
        } else {
            dispatch(getUsers(token));
        }
    };
    const filterSortList = (filterObj) => {
        dispatch(sortFilterMatrixUsers(filterObj));
    };
    const parselistitems = (items) => {
        let _filtereditems;
        if (items && currentAdmin && _.includes(["super"], currentAdmin.profile.matrixAdminRole)) {
            _filtereditems = _.compact(
                _.map(items, (user, i) => {
                    if (!user.profile.matrixTestAccount) {
                        return user;
                    }
                })
            );
        } else {
            _filtereditems = items;
        }
        return _filtereditems;
    };
    useEffect(() => {
        // customize per list
        if (selectedRow) {
            console.log(selectedRow);

            history.push(`/users/${selectedRow.id}`);
        }
    }, [selectedRow]);
    useEffect(() => {
        if (selectedColumns && selectedColumns.length >= 1) {
            setHeaderRow(
                <Box flexGrow={1}>
                    <span style={{ fontSize: "1.2rem" }}>{pagetitle}</span>
                </Box>
            );
        }
    }, [selectedColumns]);
    useEffect(() => {
        if (!_.isEmpty(currentAdmin)) {
            setfilter({});
        }
    }, [currentAdmin]);
    useEffect(() => {
        if (!_.isEmpty(listitems)) {
            let _data = _.compact(
                _.map(listitems, (user, i) => {
                    // only shared accounts
                    if (user.profile.sharedAccount) {
                        return user;
                    }
                })
            );
            setListData(_data);
        }
    }, [listitems]);
    return (
        // <List
        //     pagetitle={pagetitle}
        //     localstoragekey={localstoragekey}
        //     listitems={listitems}
        //     selectedRow={selectedRow}
        //     setSelectedRow={setSelectedRow}
        //     selectedColumns={selectedColumns}
        //     setSelectedColumns={setSelectedColumns}
        //     defaultColumns={availableColumns}
        //     defaultView={defaultViewColumns}
        //     listfilter={filter}
        //     headerRow={headerRow}
        //     footerRow={footerRow}
        //     resetList={resetList}
        //     filterSortList={filterSortList}
        //     parselistitems={parselistitems}
        //     canExport={true}
        //     excelFileName={"matrixusers"}
        // />
        <UsersTableData pagetitle={pagetitle} localstoragekey={localstoragekey} listitems={listData} showSelectCheckbox={false} canExport={true} clickable={true} />
    );
};

export default SharedAccounts;
