import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faHouseUser, faUserCheck, faUsersClass } from "@fortawesome/pro-duotone-svg-icons";
const StyledLink = styled(Link)({
    color: "var(--text-color)",
    textDecoration: "none",
});
const UsersBreadcrumb = ({ item }) => {
    return (
        <Breadcrumbs aria-label='breadcrumb'>
            <StyledLink to={"/"}>
                <FontAwesomeIcon icon={faHouse} />
                &nbsp;Home
            </StyledLink>
            {item.profile.sharedAccount ? (
                <StyledLink to={"/sharedaccounts"}>
                    <FontAwesomeIcon icon={faHouseUser} />
                    &nbsp;Shared Accounts
                </StyledLink>
            ) : item.profile.matrixAdmin ? (
                <StyledLink to={"/super/admins"}>
                    <FontAwesomeIcon icon={faUsersClass} />
                    &nbsp;Admins
                </StyledLink>
            ) : (
                <StyledLink to={"/users"}>
                    <FontAwesomeIcon icon={faUsersClass} />
                    &nbsp;Users
                </StyledLink>
            )}

            <Typography color='textPrimary'>{item.profile.login}</Typography>
        </Breadcrumbs>
    );
};

export default UsersBreadcrumb;
