// import { CALL_API } from "redux-api-middleware";
import axios from "axios";
import _ from "lodash";
import { subDays, formatISO9075 } from "date-fns";
let _matrixUsers = [];
let _focusbrandsUsers = [];
let _matrixSignins = [];
let _focusbrandsSignins = [];

export const getMe = (user) => {
    return {
        type: "GET_USER",
        payload: user,
    };
};
export const getOktaMe = (token, userguid) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/me", {
                headers: {
                    authorization: `Bearer ${token}`,
                    // oktaapitoken: process.env.REACT_APP_OKTA_API_TOKEN,
                    userguid,
                },
            })
            .then((res) => {
                dispatch(getMe(res.data.me));
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getRqusts = (requests) => {
    return {
        type: "GET_REQUESTS",
        payload: requests,
    };
};
export const getRequests = (token) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getrequests", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    let _requestsdata = _.orderBy(res.data.requests, "id", "desc");
                    dispatch(getRqusts(_requestsdata));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getHlp = (help) => {
    return {
        type: "GET_HELPCONTENT",
        payload: help,
    };
};
export const getHelp = (token) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/gethelp", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log("getting help", res.data);
                if (res.data.success) {
                    dispatch(getHlp(res.data.help));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getBrnds = (brands) => {
    return {
        type: "GET_BRANDS",
        payload: brands,
    };
};
export const getBrands = (token) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getbrands", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getBrnds(res.data.brands));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getRls = (roles) => {
    return {
        type: "GET_ROLES",
        payload: roles,
    };
};
export const getRoles = (token, user) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getroles", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getRls(res.data.roles));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getAdms = (admins) => {
    return {
        type: "GET_ADMINS",
        payload: admins,
    };
};
export const getAdmins = (token) => {
    // let searchstring = setAdminAdminsFilter(user, brands);
    let searchstring = `profile.matrixAdmin eq true`;
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getadminusers", {
                headers: {
                    authorization: `Bearer ${token}`,
                    searchstring,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getAdms(res.data.admins));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getAdmRls = (roles) => {
    return {
        type: "GET_ADMROLES",
        payload: roles,
    };
};
export const getAdminRoles = (token, user) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getadminroles", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getAdmRls(res.data.roles));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getAps = (apps) => {
    return {
        type: "GET_APPS",
        payload: apps,
    };
};
export const getApps = (token) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getapps", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getAps(res.data.apps));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getStrs = (stores) => {
    return {
        type: "GET_STORES",
        payload: stores,
    };
};
export const getStores = (token) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getstores", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getStrs(res.data.stores));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getMatrixUsrs = (users) => {
    return {
        type: "GET_MATRIXUSERS",
        payload: users,
    };
};
export const getFBUsrs = (users) => {
    return {
        type: "GET_FBUSERS",
        payload: users,
    };
};
export const getUsers = (token) => {
    _matrixUsers = [];
    _focusbrandsUsers = [];
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getallusers", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // console.log("getting all users");
                // console.log(res.data);
                if (res.data.success) {
                    _focusbrandsUsers = _.concat(
                        _focusbrandsUsers,
                        _.compact(
                            _.map(res.data.users, (user, i) => {
                                if (_.includes(user.profile.login, "focusbrands.com")) {
                                    return user;
                                }
                            })
                        )
                    );
                    _matrixUsers = _.concat(
                        _matrixUsers,
                        _.compact(
                            _.map(res.data.users, (user, i) => {
                                if (_.includes(user.profile.login, "focusbrandsmatrix.com")) {
                                    return user;
                                }
                            })
                        )
                    );
                    dispatch(getMatrixUsrs(_matrixUsers));
                    dispatch(getFBUsrs(_focusbrandsUsers));
                } else {
                    console.log(res.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getMatrixSgnIns = (signins) => {
    return {
        type: "GET_MATRIXSIGNINS",
        payload: signins,
    };
};
export const getFBSgnIns = (signins) => {
    return {
        type: "GET_FBSIGNINS",
        payload: signins,
    };
};
export const getSignins = (token) => {
    _matrixSignins = [];
    _focusbrandsSignins = [];
    let _startDate = subDays(new Date(), 14);
    _startDate = formatISO9075(new Date(_startDate));
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getallsignins", {
                headers: {
                    authorization: `Bearer ${token}`,
                    startdate: _startDate,
                },
            })
            .then((res) => {
                // console.log("getting all signins");
                // console.log(res.data);
                if (res.data.success) {
                    _focusbrandsSignins = _.concat(
                        _focusbrandsSignins,
                        _.compact(
                            _.map(res.data.signins, (signin, i) => {
                                if (_.includes(signin.actoraltid, "focusbrands.com")) {
                                    return signin;
                                }
                            })
                        )
                    );
                    _matrixSignins = _.concat(
                        _matrixSignins,
                        _.compact(
                            _.map(res.data.signins, (signin, i) => {
                                if (!_.includes(signin.actoraltid, "focusbrands.com")) {
                                    return signin;
                                }
                            })
                        )
                    );
                    dispatch(getMatrixSgnIns(_matrixSignins));
                    dispatch(getFBSgnIns(_focusbrandsSignins));
                } else {
                    console.log(res.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const sortFilterMatrixUsers = (payload) => ({
    type: "SORT_FILTER_MATRIXUSERS",
    payload,
});
export const sortFilterMatrixAdmins = (payload) => ({
    type: "SORT_FILTER_ADMINS",
    payload,
});
export const sortFilterFBUsers = (payload) => ({
    type: "SORT_FILTER_FBUSERS",
    payload,
});
export const sortFilterStores = (payload) => ({
    type: "SORT_FILTER_STORES",
    payload,
});
export const sortFilterRequests = (payload) => ({
    type: "SORT_FILTER_REQUESTS",
    payload,
});
export const sortFilterApps = (payload) => ({
    type: "SORT_FILTER_APPS",
    payload,
});
export const sortFilterHelpContent = (payload) => ({
    type: "SORT_FILTER_HELPCONTENT",
    payload,
});
export const sortFilterMatrixSignins = (payload) => ({
    type: "SORT_FILTER_MATRIXSIGNINS",
    payload,
});
export const sortFilterFBSignins = (payload) => ({
    type: "SORT_FILTER_FBSIGNINS",
    payload,
});
