import { useEffect, useState, useMemo } from "react";
import { Box, Chip } from "@material-ui/core";
import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRequests, sortFilterRequests } from "../actions";
import { differenceInMinutes } from "date-fns";

// import List from "../components/Common/List";
// import * as Columns from "../components/Common/DataTableColData";
// import DataTable from "../components/Common/DataTable";
import RequestsTableData from "../components/Requests/RequestsTableData";

const Requests = () => {
    let history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const dispatch = useDispatch();
    const currentAdmin = useSelector((state) => state.user.usable);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const pagetitle = "Requests";
    const localstoragekey = "matrixrequestslistview";
    const listitems = useSelector((state) => state.requests.usablelist);
    // name from columnTemplates
    // const defaultViewColumns = Columns.requestsDefaultColumns;
    // const availableColumns = Columns.requestsAvailableColumns;
    // const [headerRow, setHeaderRow] = useState(
    //     <div className='table-header' style={{ fontSize: "1.2rem" }}>
    //         {pagetitle}
    //     </div>
    // );
    // const [filter, setfilter] = useState({});
    // const resetList = () => {
    //     if (!_.isEmpty(filter)) {
    //         dispatch(sortFilterRequests({ filters: filter }));
    //     } else {
    //         dispatch(getRequests(token));
    //     }
    // };
    // const filterSortList = (filterObj) => {
    //     dispatch(sortFilterRequests(filterObj));
    // };
    // const parselistitems = (items) => {
    //     return items;
    // };
    // useEffect(() => {
    //     // customize per list
    //     if (selectedRow) {
    //         console.log(selectedRow);
    //         history.push(`/accessrequests/${selectedRow.id}`);
    //     }
    // }, [selectedRow]);
    // useEffect(() => {
    //     // let _localoktasynctime = localStorage.getItem("oktausersynctime");
    //     // if (!_.isEmpty(_localoktasynctime)) {
    //     //     _localoktasynctime = JSON.parse(_localoktasynctime);
    //     //     let _offsetsynctime = differenceInMinutes(new Date(), new Date(_localoktasynctime.lastran));
    //     // }
    //     if (selectedColumns && selectedColumns.length >= 1) {
    //         // <Box p={1} textAlign='center'>
    //         //     <Chip label={`Last Updated ${_offsetsynctime} minute(s) ago`} color='primary' />
    //         // </Box>;
    //         setHeaderRow(
    //             <Box flexGrow={1}>
    //                 <span style={{ fontSize: "1.2rem" }}>{pagetitle}</span>
    //             </Box>
    //         );
    //     }
    // }, [selectedColumns]);
    // useEffect(() => {
    //     if (!_.isEmpty(currentAdmin)) {
    //         setfilter({
    //             status: {
    //                 value: "Pending",
    //                 matchMode: "equals",
    //             },
    //         });
    //     }
    // }, [currentAdmin]);
    /// react-table setup
    return (
        <>
            {/* // <List
        //     pagetitle={pagetitle}
        //     localstoragekey={localstoragekey}
        //     listitems={listitems}
        //     selectedRow={selectedRow}
        //     setSelectedRow={setSelectedRow}
        //     selectedColumns={selectedColumns}
        //     setSelectedColumns={setSelectedColumns}
        //     defaultColumns={availableColumns}
        //     defaultView={defaultViewColumns}
        //     listfilter={filter}
        //     resetList={resetList}
        //     filterSortList={filterSortList}
        //     parselistitems={parselistitems}
        // /> */}
            <RequestsTableData pagetitle={pagetitle} localstoragekey={localstoragekey} listitems={listitems} />
        </>
    );
};

export default Requests;
