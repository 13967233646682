import { Box, Chip, Divider, Grid, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { OrganizationChart } from "primereact/organizationchart";
import { styled, withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { useSelector } from "react-redux";
import _ from "lodash";
import RoleIcons from "../Common/RoleIcons";
import UserStatusIcons from "../Common/UserStatusIcons";
import { useEffect, useState } from "react";
const StyledLink = styled(Link)({
    color: "var(--text-color)",
});
const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        minWidth: 150,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        padding: "10px",
    },
}))(Tooltip);
const StoreHierarchyPanel = (data) => {
    const { store } = data;
    const matrixusers = useSelector((state) => state.matrixusers.untouchedlist);
    const focusbrandsusers = useSelector((state) => state.focusbrandsusers.untouchedlist);
    const [assignedMatrixUsers, setAssignedMatrixUsers] = useState([]);
    const [guestFocusMatrixChartData, setGuestFocusMatrixChartData] = useState(false);
    const [assignedFBUsers, setAssignedFBUsers] = useState([]);
    const [guestFocusFBChartData, setGuestFocusFBChartData] = useState(false);
    const _chartdata = [
        {
            label: "Operating Partner",
            data: { name: store.operatingPartner_name, email: store.operatingPartner_email, oktaguid: store.operatingPartner_oktaguid },
            expanded: true,
            children: [
                {
                    label: "District Operator",
                    data: {
                        name: store.districtOperator_name ? store.districtOperator_name : store.operatingPartner_name,
                        email: store.districtOperator_email ? store.districtOperator_email : store.operatingPartner_email,
                        oktaguid: store.districtOperator_oktaguid ? store.districtOperator_oktaguid : store.operatingPartner_oktaguid,
                    },
                    expanded: true,
                    children: [
                        {
                            label: "District Manager",
                            data: {
                                name: store.districtManager_name ? store.districtManager_name : store.districtOperator_name ? store.districtOperator_name : store.operatingPartner_name,
                                email: store.districtManager_email ? store.districtManager_email : store.districtOperator_email ? store.districtOperator_email : store.operatingPartner_email,
                                oktaguid: store.districtManager_oktaguid
                                    ? store.districtManager_oktaguid
                                    : store.districtOperator_oktaguid
                                    ? store.districtOperator_oktaguid
                                    : store.operatingPartner_oktaguid,
                            },
                            expanded: true,
                            children: [
                                {
                                    label: "General Manager",
                                    data: {
                                        name: store.generalManager_name
                                            ? store.generalManager_name
                                            : store.districtManager_name
                                            ? store.districtManager_name
                                            : store.districtOperator_name
                                            ? store.districtOperator_name
                                            : store.operatingPartner_name,
                                        email: store.generalManager_email
                                            ? store.generalManager_email
                                            : store.districtManager_email
                                            ? store.districtManager_email
                                            : store.districtOperator_email
                                            ? store.districtOperator_email
                                            : store.operatingPartner_email,
                                        oktaguid: store.generalManager_oktaguid
                                            ? store.generalManager_oktaguid
                                            : store.districtManager_oktaguid
                                            ? store.districtManager_oktaguid
                                            : store.districtOperator_oktaguid
                                            ? store.districtOperator_oktaguid
                                            : store.operatingPartner_oktaguid,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
    const nodeMatrixUserTemplate = (node) => {
        let _user = _.find(matrixusers, { id: node.data.oktaguid });
        if (_user) {
            return (
                <div>
                    <div className='node-header'>{node.label}</div>
                    <div className='node-content'>
                        <Box>
                            <UserStatusIcons status={_user.status} hidelabel={true} />
                            <CustomTooltip
                                arrow
                                placement='left'
                                title={
                                    _user ? (
                                        <>
                                            <div>
                                                <strong>{_user.profile.displayName}</strong>
                                            </div>
                                            <div>
                                                <strong>GuestFocus Role: </strong>
                                                {_user.profile.guestRelationsRole}
                                            </div>
                                            <div>
                                                <strong>GuestFocus Access Level: </strong>
                                                {_user.profile.guestRelationsAccessLevel}
                                            </div>
                                            <div>
                                                <strong>Status: </strong>
                                                {_user.status}
                                            </div>
                                            <div>
                                                <strong>Guest Focus Access: </strong>
                                                {_user.profile.guestRelationsRole ? "Yes" : "No"}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <strong>User Does Not Have an Assigned Matrix Account.</strong>
                                        </>
                                    )
                                }>
                                <StyledLink to={`/users/${node.data.oktaguid}`}>{node.data.name}</StyledLink>
                            </CustomTooltip>
                        </Box>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className='node-header'>{node.label}</div>
                    <div className='node-content'>No Matrix Account</div>
                </div>
            );
        }
    };
    useEffect(() => {
        if (matrixusers) {
            let _assignedUsers = _.compact(
                _.map(matrixusers, (user, i) => {
                    let _gfstorearry = user.profile.guestRelationsAccessList ? user.profile.guestRelationsAccessList.split(",") : [];
                    if (_.includes(_gfstorearry, store.StoreShortID)) {
                        return user;
                    }
                })
            );
            setAssignedMatrixUsers(_assignedUsers);
        }
    }, [matrixusers]);
    useEffect(() => {
        if (!_.isEmpty(assignedMatrixUsers)) {
            let _gfchartdata = _.map(assignedMatrixUsers, (user, i) => {
                let _userobj = {
                    label: user.profile.displayName,
                    data: {
                        id: user.id,
                        status: user.status,
                        displayName: user.profile.displayName,
                        email: user.profile.email,
                        guestRelationsRole: user.profile.guestRelationsRole,
                        sharedAccount: user.profile.sharedAccount,
                        guestRelationsAccessLevel: user.profile.guestRelationsAccessLevel,
                    },
                };
                return _userobj;
            });
            setGuestFocusMatrixChartData(_gfchartdata);
        }
    }, [assignedMatrixUsers]);
    useEffect(() => {
        if (focusbrandsusers) {
            let _assignedUsers = _.compact(
                _.map(focusbrandsusers, (user, i) => {
                    let _gfstorearry = user.profile.guestRelationsAccessList ? user.profile.guestRelationsAccessList.split(",") : [];
                    if (_.includes(_gfstorearry, store.StoreShortID)) {
                        return user;
                    }
                })
            );
            setAssignedFBUsers(_assignedUsers);
        }
    }, [focusbrandsusers]);
    useEffect(() => {
        if (!_.isEmpty(assignedFBUsers)) {
            let _gfchartdata = _.map(assignedFBUsers, (user, i) => {
                let _userobj = {
                    label: user.profile.displayName,
                    data: {
                        id: user.id,
                        status: user.status,
                        displayName: user.profile.displayName,
                        email: user.profile.email,
                        guestRelationsRole: user.profile.guestRelationsRole,
                        sharedAccount: user.profile.sharedAccount,
                        guestRelationsAccessLevel: user.profile.guestRelationsAccessLevel,
                    },
                };
                return _userobj;
            });
            setGuestFocusFBChartData(_gfchartdata);
        }
    }, [assignedFBUsers]);
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    Above Store
                </Grid>
                <Grid item xs={3}>
                    GuestFocus FB Access
                </Grid>
                <Grid item xs={3}>
                    Store Level
                </Grid>
                <Grid item xs={3}>
                    GuestFocus Matrix Access
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    -Coming Soon-
                </Grid>
                <Grid item xs={3}>
                    {guestFocusFBChartData ? (
                        <>
                            {_.map(guestFocusFBChartData, (node, i) => {
                                return (
                                    <Box my={1} key={i}>
                                        <Chip
                                            variant={node.data.guestRelationsRole ? "default" : "outlined"}
                                            label={
                                                <Box>
                                                    <UserStatusIcons status={node.data.status} hidelabel={true} />
                                                    <CustomTooltip
                                                        arrow
                                                        placement='left'
                                                        title={
                                                            <>
                                                                <div>
                                                                    <strong>{node.data.displayName}</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>GuestFocus Role: </strong>
                                                                    {node.data.guestRelationsRole}
                                                                </div>
                                                                <div>
                                                                    <strong>GuestFocus Access Level: </strong>
                                                                    {node.data.guestRelationsAccessLevel}
                                                                </div>
                                                                <div>
                                                                    <strong>Status: </strong>
                                                                    {node.data.status}
                                                                </div>
                                                                <div>
                                                                    <strong>Guest Focus Access: </strong>
                                                                    {node.data.guestRelationsRole ? "Yes" : "No"}
                                                                </div>
                                                            </>
                                                        }>
                                                        <span>{node.label}</span>
                                                    </CustomTooltip>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                );
                            })}
                        </>
                    ) : (
                        "No FRM Data to Display"
                    )}
                </Grid>
                <Grid item xs={3}>
                    <OrganizationChart value={_chartdata} nodeTemplate={nodeMatrixUserTemplate}></OrganizationChart>
                </Grid>
                <Grid item xs={3}>
                    {guestFocusMatrixChartData ? (
                        <>
                            {_.map(guestFocusMatrixChartData, (node, i) => {
                                return (
                                    <Box my={1} key={i}>
                                        <Chip
                                            variant={node.data.guestRelationsRole ? "default" : "outlined"}
                                            label={
                                                <Box>
                                                    <UserStatusIcons status={node.data.status} hidelabel={true} />
                                                    <CustomTooltip
                                                        arrow
                                                        placement='left'
                                                        title={
                                                            <>
                                                                <div>
                                                                    <strong>{node.data.displayName}</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>GuestFocus Role: </strong>
                                                                    {node.data.guestRelationsRole}
                                                                </div>
                                                                <div>
                                                                    <strong>GuestFocus Access Level: </strong>
                                                                    {node.data.guestRelationsAccessLevel}
                                                                </div>
                                                                <div>
                                                                    <strong>Status: </strong>
                                                                    {node.data.status}
                                                                </div>
                                                                <div>
                                                                    <strong>Guest Focus Access: </strong>
                                                                    {node.data.guestRelationsRole ? "Yes" : "No"}
                                                                </div>
                                                            </>
                                                        }>
                                                        <StyledLink to={`/users/${node.data.id}`}>{node.label}</StyledLink>
                                                    </CustomTooltip>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                );
                            })}
                        </>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default StoreHierarchyPanel;
