import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { isAfter, isBefore } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faTasks } from "@fortawesome/pro-duotone-svg-icons";
import { Box, Grid, LinearProgress, List, ListItem } from "@material-ui/core";
import { Chart } from "primereact/chart";

const AccessRequestsPanel = ({ startDate, endDate, requests, locationView, colorMode }) => {
    const brands = useSelector((state) => state.brands.untouchedlist);
    const [perBrandRequests, setPerBrandRequests] = useState([]);
    const [palette, setPalette] = useState([]);
    const [chartData, setChartData] = useState({});
    useEffect(() => {
        if (requests && brands) {
            let _requests = _.compact(
                _.map(requests, (request, i) => {
                    const _reqDate = new Date(request.request_date);
                    if (isAfter(_reqDate, startDate) && isBefore(_reqDate, endDate)) {
                        return request;
                    }
                })
            );
            let _perbrandrequests = _.compact(
                _.map(brands, (brand, i) => {
                    if (brand.active) {
                        let _brdrequests = _.compact(
                            _.map(_requests, (request, i) => {
                                let _asscbrndarry;
                                if (Array.isArray(request.assocBrands)) {
                                    // console.log("an array");
                                    _asscbrndarry = request.assocBrands;
                                } else {
                                    _asscbrndarry = _.compact(request.assocBrands.split(";"));
                                }
                                if (_.includes(_asscbrndarry, brand.slug) && request.location === brand.location) {
                                    return request;
                                }
                            })
                        );
                        let brdObj = {
                            brand: brand.name,
                            slug: brand.slug,
                            location: brand.location,
                            color: brand.hexcolor,
                            val: _brdrequests.length,
                        };
                        if (_brdrequests.length >= 1) {
                            return brdObj;
                        }
                    }
                })
            );
            let _paletteColors = _.compact(
                _.map(_perbrandrequests, (brand, i) => {
                    if (brand.location === locationView) {
                        return brand.color;
                    }
                })
            );
            setPalette(_paletteColors);
            setPerBrandRequests(_perbrandrequests);
        }
    }, [startDate, endDate, requests, brands, locationView]);
    useEffect(() => {
        if (locationView) {
            let _chartData = {
                labels: _.compact(
                    _.map(perBrandRequests, (item, i) => {
                        if (item.location === locationView) {
                            return item.brand;
                        }
                    })
                ),
                datasets: [
                    {
                        data: _.compact(
                            _.map(perBrandRequests, (item, i) => {
                                if (item.location === locationView) {
                                    return item.val;
                                }
                            })
                        ),
                        fill: true,
                        borderColor: colorMode === "light" ? "#495057" : "#9e9e9e",
                        backgroundColor: palette,
                    },
                ],
            };
            setChartData(_chartData);
        }
    }, [locationView, palette, perBrandRequests]);

    const chartOptions = {
        legend: {
            display: false,
        },
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box p={2}>
                        <FontAwesomeIcon icon={faTasks} fixedWidth size='2x' />
                        &nbsp;
                        <span style={{ fontSize: "1.4rem", textTransform: "uppercase" }}>Access Requests</span>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={2}>{!_.isEmpty(perBrandRequests) ? <Chart type='pie' data={chartData} options={chartOptions} /> : "No Records to view"}</Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AccessRequestsPanel;
